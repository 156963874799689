import { Divider, Group, Paper, SimpleGrid, Text, Title } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconFiretruck, IconRobotFace, IconSparkles, IconTool, IconWall } from "@tabler/icons-react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { ReactNode, useState } from "react";
import { Content } from "shared/components/global/Content";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { useIsSupport } from "shared/hooks/useIsSupport";
import { match } from "ts-pattern";

const DevtoolIcon = () => {
    const [n] = useState(() => Math.floor(Math.random() * 5) + 1);

    return match(n)
        .with(1, () => <IconTool />)
        .with(2, () => <IconRobotFace />)
        .with(3, () => <IconFiretruck />)
        .with(4, () => <IconSparkles />)
        .with(5, () => <IconWall />)
        .otherwise(() => null);
};

const DevtoolCard = ({ children, disabled }: { children: ReactNode; disabled?: boolean }) => {
    const { ref, hovered } = useHover();
    return (
        <Paper
            style={{ transition: "box-shadow 250ms", cursor: disabled ? "not-allowed" : undefined }}
            shadow={!disabled && hovered ? "xl" : undefined}
            bg={disabled ? "gray.4" : undefined}
            radius="md"
            ref={ref}
        >
            <Group w="full" justify="space-between" p="xl">
                <Title order={5} c={disabled ? "dimmed" : undefined}>
                    {children}
                </Title>
                <Text c={disabled ? "dimmed" : undefined}>
                    <DevtoolIcon />
                </Text>
            </Group>
        </Paper>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/_dev/devtools/")({
    component: () => {
        const isMobile = useIsMobile();
        const { isSupport } = useIsSupport();

        return (
            <Content h="100vh">
                <Title order={3}>Sandbox Devtools 😼</Title>
                <Divider />

                <SimpleGrid cols={isMobile ? 1 : 2} h="full">
                    <Link to="/devtools/message-o-matic">
                        <DevtoolCard>Message-o-Matic</DevtoolCard>
                    </Link>

                    <Link to="/devtools/user-tron" disabled={!isSupport}>
                        <DevtoolCard disabled={!isSupport}>User-Tron</DevtoolCard>
                    </Link>
                </SimpleGrid>
            </Content>
        );
    },
});
