import { AuthContextProps, useAuth } from "react-oidc-context";

export function useIsSupport() {
    const auth = useAuth();
    return { isSupport: isSupport(auth) };
}

export function isSupport(auth: AuthContextProps) {
    return (auth.user?.profile["group_membership"] as string[] | undefined)?.includes("emns-support-tech") ?? false;
}
