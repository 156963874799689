import { createRouter, redirect } from "@tanstack/react-router";
import { routeTree } from "routeTree.gen";
import { permissionCheck } from "shared/stores/oidc";

export const router = createRouter({
    routeTree,
    defaultPreload: "intent",
    defaultStaleTime: 15 * 60 * 60 * 1000, // 15 minutes
    context: {
        auth: undefined!,
        assertPermission(userPerms, permission, id) {
            if (!permissionCheck(userPerms, permission, id)) throw redirect({ to: "/" });
        },
        assertPermissions(opts) {
            opts.forEach((args) => {
                if (!permissionCheck(...args)) throw redirect({ to: "/" });
            });
        },
    },
});

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}
