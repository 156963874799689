import { Button, Card, Center, Divider, Group, Image, rem, Stack, Text, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconFileTypePdf } from "@tabler/icons-react";
import { queryOptions, useSuspenseQueries } from "@tanstack/react-query";
import { ReactNode } from "react";
import { fileApi } from "shared/api/fileApi";
import { qk } from "shared/utils/qk";
import { createMarkerComponent, createSlots } from "shared/utils/slots";
import { match, P } from "ts-pattern";

type FileItemProps = { fileId: string; children?: ReactNode };

/** @private */
const Action = createMarkerComponent();

export const FileItem = Object.assign(
    ({ fileId, children = <></> }: FileItemProps) => {
        const [
            {
                data: { fileName, fileType },
            },
            {
                data: { presignedUrl },
            },
        ] = useSuspenseQueries({ queries: [queries.about(fileId), queries.presignedUrl(fileId)] });

        const slots = createSlots(children, {
            Action,
        });

        function handleExpand() {
            match(fileType)
                .with("application/pdf", () => window.open(presignedUrl, "_blank")?.focus())
                .otherwise(() =>
                    modals.open({
                        title: <Title order={5}>{fileName}</Title>,
                        children: (
                            <Stack>
                                <Image src={presignedUrl} />
                                <Divider />
                                <Group justify="flex-end">
                                    <Button variant="default" onClick={() => modals.closeAll()}>
                                        Close
                                    </Button>
                                </Group>
                            </Stack>
                        ),
                    }),
                );
        }

        return (
            <Card shadow="sm" p="sm" pb="xs">
                <Card.Section component="button" onClick={handleExpand} style={{ border: "unset", cursor: "pointer" }}>
                    <Center>
                        <Preview fileType={fileType} presignedUrl={presignedUrl} />
                    </Center>
                </Card.Section>
                <Group mt="xs">
                    <Text size="xs">{fileName}</Text>
                    {slots.Action}
                </Group>
                {slots.rest}
            </Card>
        );
    },
    { Action },
);

/** @private */
const Preview = (props: { fileType: string; presignedUrl: string }) =>
    match(props)
        .with({ fileType: P.union("application/pdf") }, () => (
            <Center w={rem(100)} h={rem(100)}>
                <IconFileTypePdf size={42} />
            </Center>
        ))
        .otherwise(({ presignedUrl }) => <Image h={rem(100)} src={presignedUrl} />);

const queries = {
    about: (id: string) =>
        queryOptions({
            queryKey: qk("document", id),
            queryFn: () => fileApi.get(id),
        }),
    presignedUrl: (id: string) =>
        queryOptions({
            queryKey: qk("document", "presignedUrl", id),
            queryFn: () => fileApi.sign(id),
        }),
};
