import { ActionIcon, Box, Checkbox, Group, Stack, Text, Tooltip } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createContext, useContext, useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";

type TopicEditorNode = {
    id: string;
    name: string;
    isCategory: boolean;
    isRequired: boolean;
    children?: TopicEditorNode[];
};

type TopicTreeSelectContext = {
    disableRequired: boolean;
    values: { id: string; name: string }[];
    expandedIds: string[];
    expandNode: (id: string) => void;
    collapseNode: (id: string) => void;
    onCheckboxChange: (target: { id: string; name: string }, checked: boolean) => void;
};
const ctx = createContext<TopicTreeSelectContext | null>(null);

type TopicTreeSelectProps = {
    /** When set, topics with `isRequired` set to true will be checked and disabled */
    disableRequired?: boolean;
    values: { id: string; name: string }[];
    onChange?: (topics: { id: string; name: string }[]) => unknown;
    onTopicChange?: (topic: { id: string; name: string }, checked: boolean) => unknown;
};

export const TopicTreeSelect = (props: TopicTreeSelectProps) => {
    const {
        data: { rootTopic },
    } = useSuspenseQuery(query);
    const [expandedIds, setExpandedIds] = useState([] as string[]);

    function expandNode(id: string) {
        setExpandedIds((prev) => [...new Set(prev.concat(id))]);
    }

    function collapseNode(id: string) {
        setExpandedIds((prev) => prev.filter((it) => it != id));
    }

    function onCheckboxChange(target: { id: string; name: string }, checked: boolean) {
        props.onTopicChange?.(target, checked);
        if (checked) props.onChange?.(props.values.concat([target]));
        else props.onChange?.(props.values.filter((it) => it.id != target.id));
    }

    return (
        <ctx.Provider
            value={{
                values: props.values,
                expandNode,
                collapseNode,
                expandedIds,
                onCheckboxChange,
                disableRequired: !!props.disableRequired,
            }}
        >
            <Stack gap={0}>
                {rootTopic.children.map((topic) => (
                    <TopicNode key={topic.id} node={topic} />
                ))}
            </Stack>
        </ctx.Provider>
    );
};

const TopicNode = (props: { node: TopicEditorNode }) => {
    const { collapseNode, expandNode, onCheckboxChange, expandedIds, values, disableRequired } = useContext(ctx)!;

    const isExpanded = expandedIds.includes(props.node.id);

    return (
        <Stack gap={0} display="inline-flex" w="min-content">
            <Group gap="0" w="min-content" p="sm" wrap="nowrap" style={{ whiteSpace: "nowrap" }}>
                <ActionIcon
                    display={props.node.isCategory && (props.node.children?.length ?? 0) > 0 ? undefined : "none"}
                    variant="transparent"
                    color="var(--mantine-color-text)"
                    onClick={() => {
                        if (isExpanded) collapseNode(props.node.id);
                        else expandNode(props.node.id);
                    }}
                >
                    <IconChevronRight style={{ transition: "transform 0.5s", transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)" }} />
                </ActionIcon>
                {!props.node.isCategory && (
                    <Tooltip
                        disabled={!props.node.isRequired || !disableRequired}
                        label="This topic is required and cannot be unsubscribed from"
                    >
                        <Checkbox
                            disabled={props.node.isRequired && disableRequired}
                            checked={(props.node.isRequired && disableRequired) || values.some((it) => it.id == props.node.id)}
                            onChange={(e) => onCheckboxChange(props.node, e.target.checked)}
                            pr="sm"
                        />
                    </Tooltip>
                )}
                <Text>{props.node.name}</Text>
            </Group>
            <Stack gap={0} style={{ display: isExpanded ? undefined : "none" }}>
                <Box
                    w="fit-content"
                    style={{
                        borderLeft: "3rem solid transparent",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {props.node.children?.map((c) => <TopicNode key={c.id} node={c} />)}
                </Box>
            </Stack>
        </Stack>
    );
};

const query = queryOptions({
    queryKey: qk("topicTree", "list"),
    queryFn: () =>
        httpPostGraphql(
            graphql(`
                query TopicTreeTopics {
                    rootTopic {
                        id
                        name
                        isCategory
                        isRequired
                        children {
                            id
                            name
                            isCategory
                            isRequired
                            children {
                                id
                                name
                                isCategory
                                isRequired
                                children {
                                    id
                                    name
                                    isCategory
                                    isRequired
                                    children {
                                        id
                                        name
                                        isCategory
                                        isRequired
                                    }
                                }
                            }
                        }
                    }
                }
            `),
            {},
        ),
});
