import { httpGet, httpPostFormData } from "shared/api/httpClient";
import { z } from "zod";

function intoFormData(file: File): FormData {
    const fd = new FormData();
    fd.append("_data", file);
    return fd;
}

/* Represents an uploaded document object, as it exists on the API  */
const fileObjectSchema = z.object({
    id: z.string(),
    fileDescription: z.string(),
    fileName: z.string(),
    fileSize: z.number(),
    fileType: z.string(),
});
export type FileObject = z.infer<typeof fileObjectSchema>;

const signedGrantSchema = z.object({
    presignedUrl: z.string().transform((token) => `${import.meta.env["VITE_API_BASE_URL"]}/presignedUrl/${token}`),
    fileObjectId: z.string(),
});
type SignedGrantSchema = z.infer<typeof signedGrantSchema>;

export const fileApi = {
    async upload(file: File): Promise<FileObject> {
        return await httpPostFormData("/document", intoFormData(file));
    },

    async get(id: string): Promise<FileObject> {
        return await httpGet(`/document/${id}`).then(fileObjectSchema.parse);
    },

    async sign(id: string): Promise<SignedGrantSchema> {
        return await httpGet(`/document/${id}/data`).then(signedGrantSchema.parse);
    },

    async delete(_id: string): Promise<void> {
        return await Promise.resolve();
    },
};
