import {
    Accordion,
    AccordionControlProps,
    ActionIcon,
    Box,
    Button,
    Center,
    Checkbox,
    Combobox,
    Divider,
    Group,
    Input,
    InputLabel,
    Modal,
    NavLink,
    Pill,
    PillsInput,
    Skeleton,
    Stack,
    Switch,
    Text,
    TextInput,
    Title,
    Tooltip,
    useCombobox,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronRight, IconDownload, IconPlus, IconX } from "@tabler/icons-react";
import { queryOptions, useQuery, useSuspenseInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useBlocker } from "@tanstack/react-router";
import { ReactNode, useEffect, useState } from "react";
import { rootGroupQuery } from "routes/_auth/organization/_groups/groups";
import { userQueryOptions } from "routes/_auth/route";
import { httpPostGraphql } from "shared/api/httpClient";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { permissionCheck } from "shared/stores/oidc";
import { useUserId } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { graphql } from "src/gql";
import { Cm, GroupDetailsInfoQuery, GroupNode, Permission, UserNode } from "src/gql/graphql";

export type StagedMember = {
    firstName?: string;
    lastName?: string;
    id: string;
    euds: {
        type: Cm;
        value: string;
    }[];
};

export type GroupInfo = Pick<GroupNode, "id" | "name" | "internalJoinable"> & {
    members?: Pick<UserNode, "id" | "firstName" | "lastName">[];
    children?: GroupInfo[];
    parentIds?: GroupInfo[];
    synchronized?: boolean;
};

export type GroupParent = Pick<GroupInfo, "id" | "name">;

export const GroupTreeNode = ({
    group,
    expandedNodes,
    checkboxOnClick,
    chevronOnClick,
    isDisabledPredicate,
    shouldRedirect,
    selectedCheckboxes,
    skipNodePredicate,
    renderLabel,
    shouldRenderCheckbox,
}: {
    group: GroupInfo;
    expandedNodes: string[];
    shouldRedirect?: boolean;
    chevronOnClick?: (node: GroupInfo) => void;
    checkboxOnClick?: (node: GroupInfo) => void;
    selectedCheckboxes?: string[];
    isDisabledPredicate?: (group: GroupInfo) => boolean;
    skipNodePredicate?: (group: GroupInfo) => boolean;
    renderLabel: (group: GroupInfo) => ReactNode;
    shouldRenderCheckbox?: (group: GroupInfo) => boolean;
}) => {
    const [fetchChildren, setFetchChildren] = useState(false);
    const { data, isLoading } = useQuery({
        queryKey: qk("organization", "group", group.id),
        queryFn: () => httpPostGraphql(groupQuery, { groupId: group.id }),
        enabled: fetchChildren,
    });

    const children = (!!data ? data.group.children : group.children)?.filter((c) => !skipNodePredicate?.(c));
    const opened = expandedNodes.includes(group.id ?? "");

    const willRenderCheckbox = !!checkboxOnClick && !!selectedCheckboxes && (!!shouldRenderCheckbox ? shouldRenderCheckbox(group) : true);

    const expandButton: ReactNode = (
        <ActionIcon variant="subtle" color="text">
            <IconChevronRight
                opacity={isDisabledPredicate?.(group) ? 0.4 : undefined}
                className={expandedNodes.includes(group.id ?? "") ? "expandChevron" : "collapseChevron"}
                onClick={() => {
                    if (children?.some((c) => c.children === undefined)) setFetchChildren(true);
                    chevronOnClick?.(group);
                }}
            />
        </ActionIcon>
    );

    return (
        <Stack gap={0} w="min-content" style={{ display: "inline-flex" }}>
            <Group w="min-content" gap={0} p="sm" wrap="nowrap" style={{ whiteSpace: "nowrap" }}>
                {willRenderCheckbox && (
                    <Checkbox
                        checked={selectedCheckboxes!.includes(group.id)}
                        onChange={() => checkboxOnClick!(group)}
                        pr="sm"
                        disabled={isDisabledPredicate?.(group)}
                    />
                )}
                {(children?.length ?? 0) !== 0 && expandButton}
                {renderLabel(group)}
            </Group>
            <Stack gap={0} style={{ display: opened ? undefined : "none" }}>
                {isLoading ? (
                    <Skeleton>
                        <NavLink>Placeholder name</NavLink>
                    </Skeleton>
                ) : (
                    <Box
                        w="fit-content"
                        style={{
                            borderLeft: "var(--mantine-spacing-md) solid transparent",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {children?.map((c) => (
                            <GroupTreeNode
                                key={c.id + c.children?.map((c) => c.id).join()}
                                group={c}
                                expandedNodes={expandedNodes}
                                chevronOnClick={chevronOnClick}
                                shouldRedirect={shouldRedirect}
                                skipNodePredicate={skipNodePredicate}
                                checkboxOnClick={checkboxOnClick}
                                isDisabledPredicate={isDisabledPredicate}
                                selectedCheckboxes={selectedCheckboxes}
                                renderLabel={renderLabel}
                                shouldRenderCheckbox={shouldRenderCheckbox}
                            />
                        ))}
                    </Box>
                )}
            </Stack>
        </Stack>
    );
};

const SectionedAccordionControl = ({ rightSection, ...props }: AccordionControlProps & { rightSection: ReactNode }) => {
    return (
        <Center>
            <Accordion.Control {...props} />
            {rightSection}
        </Center>
    );
};

export const GroupDetailsForm = ({
    form,
    group,
    handleSubmit,
}: {
    form: UseFormReturnType<GroupDetailsInfoQuery["group"]>;
    group: GroupDetailsInfoQuery["group"];
    handleSubmit: (data: GroupDetailsInfoQuery["group"]) => void;
}) => {
    const {
        data: { rootGroup },
    } = useSuspenseQuery(rootGroupQueryOptions);
    const { reset } = form;

    useEffect(() => {
        form.setInitialValues({ ...group });
        reset();
    }, [group]);

    useBlocker({
        blockerFn: () => {
            if (window.confirm("This action will lose currently unsaved changes. Continue anyways?")) {
                reset();
                return true;
            }
            return false;
        },
        condition: form.isDirty(),
    });

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Accordion.Item value="group" style={{ borderBottom: "none" }}>
                <SectionedAccordionControl
                    rightSection={
                        <Group wrap="nowrap" pl="md">
                            <Button color="text" type="submit" disabled={!form.isDirty()} leftSection={<IconDownload />}>
                                Save
                            </Button>

                            <Button
                                variant="default"
                                onClick={(e) => {
                                    e.preventDefault();
                                    reset();
                                }}
                                type="button"
                            >
                                Reset
                            </Button>
                        </Group>
                    }
                >
                    <Title order={3}>Details</Title>
                </SectionedAccordionControl>
                <Accordion.Panel>
                    <TextInput key={form.key("name")} label="Group Name" placeholder="Enter name" {...form.getInputProps("name")} />
                    <Stack gap={0} pt="sm">
                        <InputLabel>Parent Group(s)</InputLabel>
                        <GroupTreeModal
                            rootGroup={rootGroup}
                            values={form.getValues().parents ?? []}
                            onChange={(parents) => form.setValues({ parents })}
                            disabled={group.synchronized}
                            currentGroup={group}
                            errorText={(form.errors["parents"] as string) ?? undefined}
                        />
                    </Stack>
                    <Switch
                        key={form.key("internalJoinable")}
                        label="Internally Joinable?"
                        py="sm"
                        size="lg"
                        disabled={group.synchronized}
                        {...form.getInputProps("internalJoinable", { type: "checkbox" })}
                    />
                    <Title order={3}>Sender Profile</Title>
                    <Group p="sm">Placeholder</Group>
                    <Title order={3}>Templates</Title>
                    <Group p="sm">Placeholder</Group>
                </Accordion.Panel>
            </Accordion.Item>
        </form>
    );
};

export const GroupTreeModal = ({
    rootGroup,
    currentGroup,
    disabled,
    values,
    onChange,
    errorText,
    target,
    shouldRenderCheckbox,
}: {
    rootGroup: GroupInfo;
    currentGroup?: GroupInfo;
    values: GroupParent[];
    onChange: ((parents: GroupParent[]) => void) | ((parents: GroupParent[]) => Promise<void>);
    disabled?: boolean;
    errorText?: string;
    target?: (onClick: () => void) => ReactNode;
    shouldRenderCheckbox?: (group: GroupInfo) => boolean;
}) => {
    const combobox = useCombobox();
    const [opened, { open, close }] = useDisclosure();
    const [expandedNodes, setExpandedNodes] = useState<string[]>([rootGroup.id]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<GroupParent[]>([]);
    const { userId } = useUserId();

    const { data: permissions } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.clientPermissions });

    function isDisabledPredicate(g: GroupInfo) {
        return !permissionCheck(permissions, Permission.GroupSubgroupManage, g.id) || !!(currentGroup?.synchronized && g.synchronized);
    }

    useEffect(() => {
        setSelectedCheckboxes(values);
    }, [values]);

    return (
        <>
            <Modal opened={opened} onClose={close} title="Select Groups">
                <Stack>
                    <Box mah="75%">
                        <GroupTreeNode
                            group={rootGroup}
                            expandedNodes={expandedNodes}
                            chevronOnClick={(group) => {
                                if (expandedNodes.includes(group.id)) setExpandedNodes(expandedNodes.filter((g) => g != group.id));
                                else setExpandedNodes(expandedNodes.concat(group.id));
                            }}
                            checkboxOnClick={(node: GroupParent) =>
                                setSelectedCheckboxes(
                                    selectedCheckboxes.some((n) => n.id == node.id)
                                        ? selectedCheckboxes.filter((n) => n.id != node.id)
                                        : selectedCheckboxes.concat(node),
                                )
                            }
                            selectedCheckboxes={selectedCheckboxes.map((n) => n.id)}
                            isDisabledPredicate={isDisabledPredicate}
                            skipNodePredicate={(g) => g.id == currentGroup?.id}
                            renderLabel={(g) => <Text opacity={isDisabledPredicate(g) ? 0.4 : undefined}>{g.name}</Text>}
                            shouldRenderCheckbox={shouldRenderCheckbox}
                        />
                    </Box>
                    <Group>
                        <Button
                            variant="default"
                            style={{ flexGrow: 1 }}
                            onClick={() => {
                                close();
                                setSelectedCheckboxes(values);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ flexGrow: 1 }}
                            onClick={() => {
                                onChange(selectedCheckboxes)?.catch(logger.error);
                                close();
                            }}
                        >
                            Continue
                        </Button>
                    </Group>
                </Stack>
            </Modal>
            <Combobox store={combobox}>
                <Combobox.DropdownTarget>
                    {!!target ? (
                        target(open)
                    ) : (
                        <PillsInput
                            pointer
                            onClick={!disabled && currentGroup?.id != rootGroup.id ? open : undefined}
                            disabled={disabled || currentGroup?.id == rootGroup.id}
                            error={errorText}
                        >
                            <Pill.Group>
                                {values.length > 0 ? (
                                    values.map((g: GroupParent) => (
                                        <Pill
                                            key={g.id}
                                            withRemoveButton
                                            disabled={disabled}
                                            onRemove={() => {
                                                onChange(values.filter((x: GroupParent) => x.id != g.id))?.catch(logger.error);
                                            }}
                                        >
                                            {g.name}
                                        </Pill>
                                    ))
                                ) : currentGroup?.id == rootGroup.id ? (
                                    <Text fs="italic">This group cannot have any parents</Text>
                                ) : (
                                    <Input.Placeholder>Pick one or more values</Input.Placeholder>
                                )}

                                <Combobox.EventsTarget>
                                    <PillsInput.Field
                                        type="hidden"
                                        onBlur={() => combobox.closeDropdown()}
                                        onKeyDown={(event) => {
                                            if (event.key === "Backspace") {
                                                event.preventDefault();
                                                onChange(values.slice(0, -1))?.catch(logger.error);
                                            }
                                        }}
                                    />
                                </Combobox.EventsTarget>
                            </Pill.Group>
                        </PillsInput>
                    )}
                </Combobox.DropdownTarget>
            </Combobox>
        </>
    );
};

const AddMembersModal = ({
    initialMembers,
    groupId,
    opened,
    onClose,
    setMembersOverride,
    isCreateForm,
}: {
    opened: boolean;
    onClose: () => void;
    initialMembers: string[];
    groupId: string;
    setMembersOverride: (members: StagedMember[]) => void;
    isCreateForm: boolean;
}) => {
    const [newMembers, setNewMembers] = useState<string[]>([]);

    const { mutate: addMembersMutation } = useGraphqlMutation({
        document: mutations.addMembersToGroup,
        onSuccess: async () => {
            await qk.invalidate("organization", "group", groupId);
            notify.show.success({ message: "Added users to group" });
        },
        onError: () => notify.show.error({ message: "Error adding users to group" }),
    });

    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(usersInfiniteQuery);
    const members = pages.flat();

    return (
        <Modal
            opened={opened}
            onClose={() => {
                onClose();
                if (!isCreateForm) setNewMembers([]);
            }}
            title={<Title order={3}>Add members</Title>}
            centered
        >
            {/* TODO: Search */}
            <Stack>
                <Stack mah="50dvh" style={{ overflowY: "auto" }}>
                    {members.map((m) => {
                        const maybeEud = m.euds.find((eud) => eud.type == Cm.Email);
                        const checked = newMembers.includes(m.id) || initialMembers.includes(m.id);
                        return (
                            <Checkbox
                                key={m.id}
                                label={m.firstName || m.lastName ? `${m.firstName ?? ""} ${m.lastName ?? ""}` : "Unknown user"}
                                description={!!maybeEud ? maybeEud.value : ""}
                                size="md"
                                checked={checked}
                                disabled={initialMembers.includes(m.id)}
                                onChange={() => setNewMembers(checked ? newMembers.filter((id) => id != m.id) : newMembers.concat(m.id))}
                            />
                        );
                    })}
                    <LoadMoreTrigger query={usersInfiniteQuery} />
                </Stack>
                <Divider />
                <Button
                    w="100%"
                    onClick={() => {
                        if (isCreateForm) {
                            setMembersOverride(
                                members
                                    .filter((m) => newMembers.includes(m.id))
                                    .map((m) => ({
                                        firstName: m.firstName ?? undefined,
                                        lastName: m.lastName ?? undefined,
                                        id: m.id,
                                        euds: m.euds,
                                    })),
                            );
                        } else addMembersMutation({ groupId, userIds: newMembers });

                        onClose();
                    }}
                >
                    Add
                </Button>
            </Stack>
        </Modal>
    );
};

const Member = ({
    member,
    canChangeMembership,
    onRemove,
}: {
    member: StagedMember;
    canChangeMembership: boolean;
    onRemove: () => void;
}) => {
    const maybeEud = member.euds.find((eud) => eud.type == Cm.Email);

    return (
        <Group key={member.id} justify="space-between">
            <Stack gap={0}>
                <Text>{member.firstName || member.lastName ? `${member.firstName ?? ""} ${member.lastName ?? ""}` : "Unknown user"}</Text>
                <Text fz="sm">{!!maybeEud ? maybeEud.value : ""}</Text>
            </Stack>
            <ActionIcon variant="transparent" color="text" size="sm" disabled={!canChangeMembership} onClick={onRemove}>
                <IconX size={16} />
            </ActionIcon>
        </Group>
    );
};

export const MemberDetails = ({
    members,
    groupId,
    membersOverride,
    setMembersOverride,
    canViewMembership,
    canChangeMembership,
    isCreateForm,
}: {
    members: GroupDetailsInfoQuery["group"]["members"];
    groupId: string;
    membersOverride?: StagedMember[];
    setMembersOverride: (members: StagedMember[]) => void;
    canViewMembership: boolean;
    canChangeMembership: boolean;
    isCreateForm: boolean;
}) => {
    const [opened, { open, close }] = useDisclosure(false);

    const { mutate: removeMemberMutation } = useGraphqlMutation({
        document: mutations.removeMembersFromGroup,
        onSuccess: async () => {
            await qk.invalidate("organization", "group", groupId);
            notify.show.success({ message: "Removed user from group" });
        },
        onError: () => notify.show.error({ message: "Failed to removed user from group" }),
    });

    return (
        <>
            <AddMembersModal
                opened={opened}
                onClose={close}
                initialMembers={members.map((m) => m.id)}
                groupId={groupId}
                setMembersOverride={setMembersOverride}
                isCreateForm={isCreateForm}
            />
            <Accordion.Item value="members" style={{ borderBottom: "none" }}>
                <SectionedAccordionControl
                    rightSection={
                        <Tooltip label="You do not have permission to add members to this group" disabled={canChangeMembership}>
                            <Button
                                variant="default"
                                leftSection={<IconPlus />}
                                flex="1 0 auto"
                                onClick={open}
                                disabled={!canChangeMembership}
                            >
                                Add
                            </Button>
                        </Tooltip>
                    }
                >
                    <Title order={3}>Members</Title>
                </SectionedAccordionControl>
                <Accordion.Panel>
                    <Box style={{ flexGrow: 1 }}>
                        <Stack style={{ overflowY: "auto" }}>
                            {(membersOverride?.length ?? 0) > 0 ? (
                                membersOverride?.map((member) => (
                                    <Member
                                        key={member.id}
                                        member={member}
                                        canChangeMembership
                                        onRemove={() => {
                                            setMembersOverride(membersOverride.filter((m) => m.id != member.id));
                                        }}
                                    />
                                ))
                            ) : canViewMembership && members.length > 0 ? (
                                members.map((member) => (
                                    <Member
                                        key={member.id}
                                        member={{
                                            firstName: member.firstName ?? undefined,
                                            lastName: member.lastName ?? undefined,
                                            id: member.id,
                                            euds: member.euds,
                                        }}
                                        canChangeMembership={canChangeMembership}
                                        onRemove={() => removeMemberMutation({ userId: member.id, groupId: groupId })}
                                    />
                                ))
                            ) : (
                                <Stack align="center">
                                    <Text c="dimmed">No members found</Text>
                                </Stack>
                            )}
                        </Stack>
                    </Box>
                </Accordion.Panel>
            </Accordion.Item>
        </>
    );
};

const mutations = {
    addMembersToGroup: graphql(`
        mutation GroupDetailsAddUsersToGroup($groupId: UUID!, $userIds: [UUID!]!) {
            addUsersToGroup(groupId: $groupId, userIds: $userIds) {
                id
            }
        }
    `),
    removeMembersFromGroup: graphql(`
        mutation GroupDetailsRemoveMember($userId: UUID!, $groupId: UUID!) {
            removeUserFromGroup(userId: $userId, groupId: $groupId) {
                id
            }
        }
    `),
};

export const usersInfiniteQuery = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query GroupDetailsUsers($offset: Int!, $limit: Int!) {
            users(limit: $limit, offset: $offset) {
                id
                firstName
                lastName
                euds {
                    type
                    value
                }
            }
        }
    `),
    select: ({ users }) => users,
    queryKey: ["organization", "users"],
});

export const rootGroupQueryOptions = queryOptions({
    queryKey: qk("organization", "rootGroup"),
    queryFn: () => httpPostGraphql(rootGroupQuery, {}),
});

const groupQuery = graphql(`
    query GroupTreeChildGroup($groupId: UUID!) {
        group(id: $groupId) {
            id
            name
            internalJoinable
            synchronized
            members {
                id
                firstName
                lastName
                euds {
                    type
                    value
                }
            }
            children {
                id
                name
                internalJoinable
                synchronized
                members {
                    id
                    firstName
                    lastName
                    euds {
                        type
                        value
                    }
                }
                children {
                    id
                    name
                    internalJoinable
                    synchronized
                    members {
                        id
                        firstName
                        lastName
                        euds {
                            type
                            value
                        }
                    }
                    children {
                        id
                        name
                        internalJoinable
                        synchronized
                        members {
                            id
                            firstName
                            lastName
                            euds {
                                type
                                value
                            }
                        }
                    }
                }
            }
        }
    }
`);
