import { Group, Paper, Title } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link, LinkProps } from "@tanstack/react-router";
import { userQueryOptions } from "routes/_auth/route";
import { Content } from "shared/components/global/Content";
import { useUserId } from "shared/stores/oidc";

type HeadingLinkProps = Omit<LinkProps, "className"> & {
    title: string;
};

const HeadingLink = ({ title, to, ...rest }: HeadingLinkProps) => {
    return (
        <Link {...rest} to={to}>
            <Paper shadow="sm" p="sm" py="md">
                <Group justify="space-between">
                    <Title order={3} fz={20}>
                        {title}
                    </Title>
                    <IconChevronRight />
                </Group>
            </Paper>
        </Link>
    );
};

const SettingsContainer = () => {
    const { userId } = useUserId();
    const { data: groups } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.groups });

    return (
        <Content>
            <Content.Heading>
                <Title order={3}>Settings</Title>
            </Content.Heading>
            <HeadingLink title="Profile information" to="/settings/profile" />
            <HeadingLink title="Locations" to="/settings/locations" />
            <HeadingLink title="Topic Subscriptions" to="/settings/topics" />
            {groups.length > 0 && <HeadingLink title="Groups" to="/settings/groups" />}
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/settings/")({
    component: SettingsContainer,
});
