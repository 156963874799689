import { ActionIcon, Box, Group, Menu, MenuItemProps, Paper, Stack, Text, Title } from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import { ReactNode } from "@tanstack/react-router";

type DetailCardProps = {
    title: string;
    subtitle: string;
    badge?: ReactNode;
    actions?: ({ icon: ReactNode; label: string; onClick: (e: React.MouseEvent) => unknown } & Omit<MenuItemProps, "onClick">)[];
    children: ReactNode;
};

export const DetailCard = ({ title, subtitle, badge, children, actions, ...rest }: DetailCardProps) => {
    return (
        <Box w="100%" {...rest}>
            <Paper shadow="md" radius="md" px="sm" py="md" w="100%">
                <Group justify="space-between" wrap="nowrap" align="flex-start">
                    <Group>
                        <Title order={3}>{title}</Title>
                        {badge}
                    </Group>
                    <Group wrap="nowrap" onClick={(e) => e.stopPropagation()}>
                        {!!actions?.length && (
                            <Menu>
                                <Menu.Target>
                                    <ActionIcon variant="transparent">
                                        <IconDots size={16} />
                                    </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    {actions.map(({ label, icon, onClick, ...rest }) => (
                                        <Menu.Item
                                            key={label}
                                            leftSection={icon}
                                            rightSection={
                                                <Text size="sm" c="inherit">
                                                    {label}
                                                </Text>
                                            }
                                            onClick={onClick}
                                            {...rest}
                                        />
                                    ))}
                                </Menu.Dropdown>
                            </Menu>
                        )}
                    </Group>
                </Group>

                <Stack gap={"xs"}>
                    <Text size="sm">{subtitle}</Text>
                    <Text lineClamp={4}>{children}</Text>
                </Stack>
            </Paper>
        </Box>
    );
};
