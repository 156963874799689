import { createFormContext } from "@mantine/form";
import { nonemptyString } from "shared/utils/zod";
import { z } from "zod";

export const addressFormStateSchema = z.object({
    name: nonemptyString(),
    street: nonemptyString(),
    street2: z.string().nullish(),
    city: nonemptyString(),
    state: nonemptyString(),
    zip: nonemptyString(),
});

type AddressFormState = z.infer<typeof addressFormStateSchema>;

export const [AddressFormProvider, useAddressFormContext, useAddressForm] = createFormContext<AddressFormState>();
