import { Box, Button, Checkbox, Group, Input, Loader, Stack, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { Suspense, useDeferredValue, useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { BadgeItem } from "shared/components/global/BadgeItem";
import { ComposeFormProvider, useComposeFormContext } from "shared/components/message/compose/context";
import { useFormSubscription } from "shared/hooks/useFormSubscription";
import { to } from "shared/utils/fns";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";

export const AudienceGroupEditor = () => {
    const form = useComposeFormContext();
    const groups = useFormSubscription(form, "audience.groups");

    function handleDeleteGroup(idx: number) {
        form.setFieldValue("audience.groups", (prev) => prev.toSpliced(idx, 1));
    }

    return (
        <Stack>
            <Text fw="bold">Groups</Text>
            <Button
                variant="default"
                leftSection={<IconPlus />}
                onClick={() =>
                    modals.open({
                        title: "Select Groups",
                        children: (
                            <ComposeFormProvider form={form}>
                                <Modal />
                            </ComposeFormProvider>
                        ),
                    })
                }
            >
                Add Groups
            </Button>
            <Group>
                {groups.map((grp, i) => (
                    <BadgeItem size="lg" key={grp.id + i} onDelete={() => handleDeleteGroup(i)}>
                        {grp.name}
                    </BadgeItem>
                ))}
            </Group>
        </Stack>
    );
};

/** @private */
const Modal = () => {
    const [search, setSearch] = useState("");
    const deferredSearch = useDeferredValue(search);

    return (
        <Stack>
            <Input leftSection={<IconSearch />} placeholder="Search groups" value={search} onChange={(e) => setSearch(e.target.value)} />
            <Suspense fallback={<Loader />}>
                <GroupSelectPanel search={deferredSearch} />
            </Suspense>
        </Stack>
    );
};

const GroupSelectPanel = ({ search }: { search: string }) => {
    const { data: allGroups } = useSuspenseQuery(query(search));
    const form = useComposeFormContext();
    const groups = useFormSubscription(form, "audience.groups");

    function isGroupSelected(id: string) {
        return groups.some((grp) => grp.id == id);
    }

    function handleGroupCheckboxChange(grp: { id: string; name: string }, checked: boolean) {
        form.setFieldValue("audience.groups", (prev) => {
            if (checked) return prev.concat([grp]);
            else return prev.filter(({ id }) => id != grp.id);
        });
    }

    return (
        <Stack>
            {allGroups.map((grp) => (
                <Checkbox
                    checked={isGroupSelected(grp.id)}
                    onChange={(e) => handleGroupCheckboxChange(grp, e.target.checked)}
                    key={grp.id}
                    label={
                        <Box mt="-sm">
                            <Text>{grp.name}</Text>
                            <Text c="dimmed" size="xs">
                                {`${grp.parents.at(0)?.name}`}
                            </Text>
                        </Box>
                    }
                />
            ))}
        </Stack>
    );
};

const query = (search: string) =>
    queryOptions({
        queryKey: qk("messages", "composer", "groups", "search", search),
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query AudienceGroupEditorGroups($search: String!) {
                        searchGroups(limit: 10, offset: 0, permissions: [GROUP_MESSAGE_SEND_TO], query: $search) {
                            id
                            name
                            parents {
                                id
                                name
                            }
                        }
                    }
                `),
                { search },
            ),
        select: to("searchGroups"),
    });
