import { ActionIcon, ActionIconProps } from "@mantine/core";
import { IconChevronLeft } from "@tabler/icons-react";
import { useRouter } from "@tanstack/react-router";

export const BackButton = (props: Omit<ActionIconProps, "onClick" | "children">) => {
    const router = useRouter();

    return (
        <ActionIcon variant="transparent" onClick={() => router.history.back()} {...props}>
            <IconChevronLeft />
        </ActionIcon>
    );
};
