import { ActionIcon, Group, rem, Stack, Text, Title } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { modals } from "@mantine/modals";
import { IconFileDescription, IconTrash, IconUpload, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { fileApi, FileObject } from "shared/api/fileApi";
import { FileItem } from "shared/components/global/FileItem";
import { useComposeFormContext } from "shared/components/message/compose/context";
import { useFormSubscription } from "shared/hooks/useFormSubscription";
import { to } from "shared/utils/fns";
import { logger } from "shared/utils/logger";

/**
 * Constructs a file uploader which accepts many {@link File} objects and yields many {@link FileObject} documents
 */
function useUploadQueue(opts: {
    /**
     * Fired in chunks with the new {@link FileObject} documents after the upload queue is cleared
     */
    onUploaded: (documents: FileObject[]) => unknown;
}) {
    const [queue, setQueue] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [buffer, setBuffer] = useState<FileObject[]>([]);

    useEffect(() => {
        if (queue.length == 0) {
            // flush buffer
            opts.onUploaded(buffer);
            setBuffer([]);

            setIsLoading(false);
            return;
        }

        const [next, ...tail] = queue;
        setIsLoading(true);

        fileApi
            .upload(next)
            .then((doc) => setBuffer((buf) => buf.concat([doc])))
            .then(() => setQueue(tail))
            .catch(logger.error);
    }, [queue]);

    return {
        /**
         * Pushes a file to the upload queue
         */
        enqueue(file: File) {
            setQueue((q) => q.concat([file]));
        },
        /**
         * Whether or not there is currently an upload processing
         */
        isLoading,
    };
}

export const AttachmentEditor = () => {
    const form = useComposeFormContext();
    const attachmentIds = useFormSubscription(form, "files").map(to("id"));

    const uploader = useUploadQueue({
        onUploaded(attachments) {
            form.setFieldValue("files", (prev) => prev.concat(attachments));
        },
    });

    function handleDeleteAttachment({ attachmentId }: { attachmentId: string }) {
        modals.openConfirmModal({
            title: <Title order={5}>Delete this Attachment?</Title>,
            children: <Text>{`Are you sure you want to delete this attachmnet?`}</Text>,
            labels: {
                confirm: "Delete",
                cancel: "Cancel",
            },
            confirmProps: {
                color: "red",
                leftSection: <IconTrash />,
            },
            onConfirm() {
                fileApi
                    .delete(attachmentId)
                    .then(() => form.setFieldValue("files", (files) => files.filter(({ id }) => id != attachmentId)))
                    .catch(logger.error);
            },
        });
    }

    return (
        <Stack>
            <Dropzone
                multiple
                loading={uploader.isLoading}
                accept={[MIME_TYPES.pdf, MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.svg, MIME_TYPES.gif]}
                onDrop={(files) => {
                    files.forEach(uploader.enqueue);
                }}
            >
                <Stack align="center" gap={"xs"} py="xl">
                    <Dropzone.Accept>
                        <IconUpload style={{ width: rem(42), height: rem(42) }} stroke={1.5} />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX style={{ width: rem(42), height: rem(42), color: "red" }} stroke={1.5} />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconFileDescription style={{ width: rem(42), height: rem(42), color: "dimmed" }} stroke={1.5} />
                    </Dropzone.Idle>

                    <div style={{ textAlign: "center" }}>
                        <Text size="lg" inline>
                            Attach Files
                        </Text>
                        <Text size="sm" c="dimmed" inline mt={7}>
                            pdf, jpeg, png, svg, or gif
                        </Text>
                    </div>
                </Stack>
            </Dropzone>

            <Group>
                {attachmentIds.map((attachmentId) => (
                    <FileItem key={attachmentId} fileId={attachmentId}>
                        <FileItem.Action>
                            <ActionIcon size="xs" variant="transparent" onClick={() => handleDeleteAttachment({ attachmentId })}>
                                <IconTrash />
                            </ActionIcon>
                        </FileItem.Action>
                    </FileItem>
                ))}
            </Group>
        </Stack>
    );
};
