import { useRouter } from "@tanstack/react-router";
import { useEffect } from "react";

export function useOnNavAway(f: () => unknown) {
    const { subscribe } = useRouter();

    useEffect(() => {
        const cleanup = subscribe("onBeforeNavigate", f);
        return () => cleanup();
    }, []);
}
