import { Badge, Text, Title, UnstyledButton } from "@mantine/core";
import { IconCopy, IconEdit, IconTrash } from "@tabler/icons-react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Content } from "shared/components/global/Content";
import { DetailCard } from "shared/components/global/DetailCard";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { qk } from "shared/utils/qk";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { NoMessagesFigure } from "src/assets/NoMessagesFigure";
import { graphql } from "src/gql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const nav = useNavigate();
    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(query);
    const templates = pages.flat();

    return (
        <Content>
            <Content.Heading>
                <Title order={3}>Templates</Title>
            </Content.Heading>

            {templates.length == 0 && (
                <NoMessagesFigure>
                    <Text ta={"center"} c="dimmed">
                        Looks like you don't have any Templates yet
                    </Text>
                </NoMessagesFigure>
            )}

            {templates.map((template) => (
                <UnstyledButton
                    key={template.id}
                    w="100%"
                    onClick={() => {
                        void nav({ to: "/messages/compose", search: { t: template.id } });
                    }}
                >
                    <DetailCard
                        title={template.subject}
                        subtitle={template.owner.name}
                        badge={
                            <Badge radius={"sm"} variant="light" color={"indigo"}>
                                Template
                            </Badge>
                        }
                        actions={[
                            { icon: <IconEdit size={16} />, label: "Edit", onClick: () => void 0 },
                            { icon: <IconCopy size={16} />, label: "Duplicate", onClick: () => void 0 },
                            { icon: <IconTrash size={16} />, label: "Delete", onClick: () => void 0, color: "red" },
                        ]}
                    >
                        {template.body}
                    </DetailCard>
                </UnstyledButton>
            ))}

            <LoadMoreTrigger query={query} />
        </Content>
    );
};

const query = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query Templates($offset: Int!, $limit: Int!) {
            templates(offset: $offset, limit: $limit) {
                id
                subject
                body
                owner {
                    name
                }
            }
        }
    `),
    queryKey: qk("templates", "list"),
    select: ({ templates }) => templates,
});

/** @public */
export const Route = createFileRoute("/_auth/organization/templates")({
    loader: () => queryClient.prefetchInfiniteQuery(query),
    component: Component,
});
