import { useMutation } from "@tanstack/react-query";
import { UseMutationOptions } from "@tanstack/react-query";
import { AuthContextProps } from "react-oidc-context";
import { getUserId } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";

type AbstractedMutationOptions<TData = unknown, TVariables = unknown> = Omit<
    UseMutationOptions<TData, unknown, TVariables, unknown>,
    "throwOnError" | "mutationFn"
>;

export async function logoutFunction(auth: AuthContextProps) {
    return auth
        .removeUser()
        .then(() => auth.signoutRedirect({ id_token_hint: auth.user?.id_token }))
        .then(() => auth.clearStaleState())
        .then(() => auth.removeUser())
        .then(() => auth.signoutRedirect({ id_token_hint: auth.user?.id_token }))
        .then(() => auth.clearStaleState())
        .then(() => qk.invalidate("user", "id", getUserId(auth)));
}

/** @public - TODO: consider removal? */
export const useLogout = ({ onSuccess, onError, ...rest }: AbstractedMutationOptions<void, { auth: AuthContextProps }>) => {
    const { mutate: logout, ...mut } = useMutation({
        throwOnError: false,
        mutationFn: async ({ auth }) => {
            await logoutFunction(auth);
        },
        onSuccess: (res, ...rest) => {
            onSuccess?.(res, ...rest);
        },
        onError: (err, ...rest) => {
            onError?.(err, ...rest);
            notify.show.error({ message: "Failed to log out" });
            logger.error(err);
        },
        ...rest,
    });

    return { logout, ...mut };
};
