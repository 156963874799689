import { Text, Title, UnstyledButton } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconTemplate, IconTrash } from "@tabler/icons-react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate, useRouter } from "@tanstack/react-router";
import { motion } from "motion/react";
import { Content } from "shared/components/global/Content";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { MessageDetailCard } from "shared/components/message/MessageDetailCard";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useOptimisticUpdater } from "shared/hooks/useOptimisicUpdater";
import { notify } from "shared/utils/notify";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { NoMessagesFigure } from "src/assets/NoMessagesFigure";
import { graphql } from "src/gql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const { preloadRoute } = useRouter();
    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(query);
    const { updater } = useOptimisticUpdater(query);
    const { mutateAsync: discardDraftAsync } = useGraphqlMutation(mutations.discard);
    const { mutateAsync: templatizeDraftAsync } = useGraphqlMutation(mutations.templatize);

    const nav = useNavigate();
    const drafts = pages.flat();

    function handleDeleteDraft(id: string) {
        modals.openConfirmModal({
            title: "Discard Draft",
            labels: {
                confirm: "Discard",
                cancel: "Cancel",
            },
            confirmProps: { color: "red" },
            children: <Text size="sm">Are you sure you want to discard this draft?</Text>,
            onConfirm() {
                void discardDraftAsync({ id }).then(() => {
                    updater.delete(id);
                    notify.show.success({
                        message: `Draft Discarded`,
                    });
                });
            },
        });
    }

    function handleTemplatizeDraft(id: string) {
        modals.openConfirmModal({
            title: "Create Template From Draft",
            labels: {
                confirm: "Create",
                cancel: "Cancel",
            },
            children: <Text size="sm">Are you sure you want to create a new Message Template from this Draft?</Text>,
            onConfirm() {
                void templatizeDraftAsync({ id })
                    .then(() => {
                        notify.show.success({
                            message: "Template Created!",
                        });
                    })
                    .then(() => nav({ to: "/organization/templates" }));
            },
        });
    }

    return (
        <Content>
            <Content.Heading>
                <Title order={3}>Message Drafts</Title>
            </Content.Heading>

            {drafts.length == 0 && (
                <NoMessagesFigure>
                    <Text ta={"center"} c="dimmed">
                        Looks like you don't have any message Drafts yet
                    </Text>
                </NoMessagesFigure>
            )}
            {drafts.map((draft) => (
                <motion.div layout key={draft.id}>
                    <UnstyledButton
                        w="100%"
                        onMouseEnter={() => {
                            void preloadRoute({ to: "/messages/drafts/$id", params: { id: draft.id } });
                        }}
                        onClick={() => {
                            void nav({ to: "/messages/drafts/$id", params: { id: draft.id } });
                        }}
                    >
                        <MessageDetailCard
                            unread
                            title={draft.subject}
                            severity={draft.severity}
                            body={draft.body}
                            actions={[
                                {
                                    icon: <IconTemplate size={16} />,
                                    label: "Create Template",
                                    onClick: () => handleTemplatizeDraft(draft.id),
                                },
                                {
                                    icon: <IconTrash size={16} />,
                                    label: "Discard",
                                    onClick: () => handleDeleteDraft(draft.id),
                                    color: "red",
                                },
                            ]}
                        />
                    </UnstyledButton>
                </motion.div>
            ))}

            <LoadMoreTrigger query={query} />
        </Content>
    );
};

const query = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query DraftMessages($offset: Int!, $limit: Int!) {
            drafts(limit: $limit, offset: $offset) {
                id
                body
                subject
                severity
                status
            }
        }
    `),
    select: ({ drafts }) => drafts,
    queryKey: ["messages", "draft"],
});

const mutations = {
    discard: graphql(`
        mutation DraftsDiscardMesasgeDraft($id: UUID!) {
            discardMessageDraft(id: $id) {
                id
            }
        }
    `),
    templatize: graphql(`
        mutation DraftsTemplatizeMessageDraft($id: UUID!) {
            createTemplateFromMessage(msgId: $id) {
                id
            }
        }
    `),
};

/** @public */
export const Route = createFileRoute("/_auth/messages/drafts/")({
    shouldReload: false,
    component: Component,
    loader: () => queryClient.prefetchInfiniteQuery(query),
});
