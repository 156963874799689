import { ActionIcon, Button, Divider, Group, Stack, Title } from "@mantine/core";
import { IconPlus, IconX } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { userQueryOptions } from "routes/_auth/route";
import { Content } from "shared/components/global/Content";
import { GroupParent, GroupTreeModal, rootGroupQueryOptions } from "shared/components/groups/Groups";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useUserId } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { queryClient } from "src/queryClient";

const GroupsList = ({
    joinedGroups,
    setJoinedGroups,
}: {
    joinedGroups: GroupParent[];
    setJoinedGroups: (groups: GroupParent[]) => void;
}) => {
    const { userId } = useUserId();
    const { data: groups } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.groups });

    const { mutate: removeUser } = useGraphqlMutation({
        document: removeUserFromGroup,
        onSuccess: async (data) => {
            setJoinedGroups(joinedGroups.filter((g) => g.id != data?.removeUserFromGroup.id));
            await qk.invalidate("user", "id", userId);
            notify.show.success({ message: "Successfully left group" });
        },
    });

    return groups.map((g) => (
        <Stack key={g.id}>
            <Group justify="space-between">
                <Title order={3} pl="sm">
                    {g.name}
                </Title>
                {g.internalJoinable && (
                    <ActionIcon variant="transparent" onClick={() => removeUser({ userId, groupId: g.id })}>
                        <IconX size={16} />
                    </ActionIcon>
                )}
            </Group>
            <Divider />
        </Stack>
    ));
};

const JoinGroupsContainer = ({
    joinedGroups,
    setJoinedGroups,
}: {
    joinedGroups: GroupParent[];
    setJoinedGroups: (groups: GroupParent[]) => void;
}) => {
    const { userId } = useUserId();
    const {
        data: { rootGroup },
    } = useSuspenseQuery(rootGroupQueryOptions);

    const { mutateAsync: addUser } = useGraphqlMutation({
        document: addUserToGroup,
        onSuccess: async (data) => {
            if (!!data?.addUserToGroup) setJoinedGroups(joinedGroups.concat(data.addUserToGroup as GroupParent));
            await qk.invalidate("user");
        },
    });

    return (
        <GroupTreeModal
            rootGroup={rootGroup}
            values={joinedGroups}
            onChange={async (groups) => {
                const promises: Promise<unknown>[] = [];
                groups.forEach((g) => {
                    if (!joinedGroups.some((group) => g.id == group.id)) {
                        promises.push(addUser({ userId, groupId: g.id }));
                    }
                });
                await Promise.all(promises).then((data) =>
                    notify.show.success({ message: data.length > 1 ? "Successfully joined groups" : "Successfully joined group" }),
                );
                await qk.invalidate("user", "id", userId);
            }}
            target={(onClick) => (
                <Button variant="default" leftSection={<IconPlus size={16} />} onClick={onClick}>
                    Join Groups
                </Button>
            )}
            shouldRenderCheckbox={(group) => group.internalJoinable && !joinedGroups.some((g) => g.id == group.id)}
        />
    );
};

const GroupsContainer = () => {
    const { userId } = useUserId();
    const { data: groups } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.groups });

    const [joinedGroups, setJoinedGroups] = useState<GroupParent[]>(groups);

    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={3}>Groups</Title>
            </Content.Heading>

            <GroupsList joinedGroups={joinedGroups} setJoinedGroups={setJoinedGroups} />
            <JoinGroupsContainer joinedGroups={joinedGroups} setJoinedGroups={setJoinedGroups} />
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/settings/groups")({
    loader: () => {
        queryClient.ensureQueryData({ ...rootGroupQueryOptions }).catch(logger.error);
    },
    component: GroupsContainer,
});
const addUserToGroup = graphql(`
    mutation SettingsAddUserToGroup($groupId: UUID!, $userId: UUID!) {
        addUserToGroup(groupId: $groupId, userId: $userId) {
            id
            name
        }
    }
`);

const removeUserFromGroup = graphql(`
    mutation SettingsRemoveUserFromGroup($groupId: UUID!, $userId: UUID!) {
        removeUserFromGroup(groupId: $groupId, userId: $userId) {
            id
        }
    }
`);
