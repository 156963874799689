import { Button, Group, Title } from "@mantine/core";
import { zodResolver } from "@mantine/form";
import { modals } from "@mantine/modals";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate, useSearch } from "@tanstack/react-router";
import { useEffect } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { SplitButton } from "shared/components/global/SplitButton";
import {
    ComposeFormProvider,
    composeFormStateSchema,
    composeFormStateToMessageInfoInput,
    useComposeForm,
} from "shared/components/message/compose/context";
import { SendPanel } from "shared/components/message/compose/SendPanel";
import { ComposeForm } from "shared/components/message/ComposeForm";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { createRandomStr, to } from "shared/utils/fns";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { geozod } from "shared/utils/zod";
import { graphql } from "src/gql";
import { Cm, MessageSeverity } from "src/gql/graphql";
import { z } from "zod";

const Component = () => {
    const nav = useNavigate();
    const form = useComposeForm({
        validate: zodResolver(composeFormStateSchema),
        initialValues: {
            audience: {
                groups: [],
                topics: [],
                users: [],
                features: [],
                geofilters: [],
            },
            severity: MessageSeverity.Informational,
            sender: { id: "" },
            body: "",
            richBody: "",
            options: [{ method: Cm.Email }, { method: Cm.Sms }],
            files: [],
            subject: "",
        },
    });

    const { t } = useSearch({ from: "/_auth/messages/compose/" });
    const { data: template } = useQuery(queries.template(t));
    const { mutateAsync: createDraft } = useGraphqlMutation(mutations.createDraft);
    const { mutateAsync: sendMessage } = useGraphqlMutation(mutations.sendMessage);

    useEffect(() => {
        if (!template) return;

        form.setValues({
            ...template,
            audience: {
                ...template.audience,
                groups: template.audience.groups,
                geofilters: template.audience.geofilters.map(to("id")),
                features: template.audience.geometries
                    .map((s) => JSON.parse(s))
                    .map((geometry) => geozod.feature(geozod.polygon()).parse({ type: "Feature", id: createRandomStr(), geometry })),
            },
        });
    }, [template]);

    function handleSaveAsDraft() {
        if (form.validate().hasErrors) return;

        createDraft({ params: composeFormStateToMessageInfoInput.parse(form.getValues()) })
            .then(async ({ createMessage: { subject, id } }) => {
                notify.show.success({ message: `Saved '${subject}'` });
                return nav({ to: "/messages/drafts/$id", params: { id } });
            })
            .catch(logger.error);
    }

    function handleSend() {
        if (form.validate().hasErrors) return;

        modals.open({
            title: "Are you sure?",
            children: (
                <SendPanel
                    onClose={() => modals.closeAll()}
                    onSend={({ expireAt }) => {
                        void createDraft({ params: composeFormStateToMessageInfoInput.parse(form.getValues()) })
                            .then(async ({ createMessage: { id } }) => sendMessage({ id, expireAt }))
                            .then(() => notify.show.success({ message: "Message sent!" }))
                            .then(() => modals.closeAll())
                            .then(() => nav({ to: "/messages/sent" }));
                    }}
                />
            ),
        });
    }

    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={3}>New Message</Title>
            </Content.Heading>

            <ComposeFormProvider form={form}>
                <ComposeForm />
            </ComposeFormProvider>

            <Group justify="flex-end" p="xs">
                <Button variant="transparent" onClick={handleSaveAsDraft}>
                    Save as Draft
                </Button>
                <SplitButton onClick={handleSend} menu={{ Schedule: { fn: () => {} } }}>
                    Review and send
                </SplitButton>
            </Group>
        </Content>
    );
};

const mutations = {
    createDraft: graphql(`
        mutation ComposeCreateDraft($params: MessageInfoInput!) {
            createMessage(params: $params) {
                id
                subject
            }
        }
    `),
    sendMessage: graphql(`
        mutation ComposeSendMesage($id: UUID!, $expireAt: Instant) {
            sendMessage(id: $id, expireAt: $expireAt) {
                id
            }
        }
    `),
};

const queries = {
    template: (id?: string) =>
        queryOptions({
            enabled: !!id,
            queryKey: qk("templates", "detail", id ?? "unset"),
            select: (it) => it?.template,
            queryFn: () =>
                id
                    ? httpPostGraphql(
                          graphql(`
                              query ComposeSendMessageTemplateDetail($id: UUID!) {
                                  template(id: $id) {
                                      id
                                      richBody
                                      subject
                                      severity
                                      files {
                                          id
                                      }
                                      sender {
                                          id
                                          name
                                      }
                                      owner {
                                          id
                                          name
                                      }
                                      audience {
                                          geometries
                                          geofilters {
                                              id
                                          }
                                          groups {
                                              name
                                              id
                                          }
                                          topics {
                                              name
                                              id
                                          }
                                          users {
                                              id
                                          }
                                      }
                                  }
                              }
                          `),
                          { id: id! },
                      )
                    : undefined,
        }),
};

/** @public */
export const Route = createFileRoute("/_auth/messages/compose/")({
    validateSearch: z.object({ t: z.string().optional() }),
    component: Component,
});
