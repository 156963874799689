import { geozod } from "shared/utils/zod";
import { z } from "zod";

const base = "https://api.radar.io/v1";

const autocompleteSchema = z.object({
    addresses: z
        .object({
            latitude: z.number(),
            longitude: z.number(),
            geometry: geozod.point(),
            formattedAddress: z.string(),
        })
        .array(),
});

export type RadarAutocomplete = z.infer<typeof autocompleteSchema>;

export const radarApi = {
    autocomplete: (query: string, near?: GeoJSON.Position) =>
        fetch(
            base +
                `/search/autocomplete?limit=5&layers=address&countryCode=US&query=${encodeURIComponent(query)}${!!near ? "&near=" + near[1] + "," + near[0] : ""}`,
            {
                headers: { Authorization: import.meta.env["VITE_RADAR_KEY"] },
            },
        )
            .then((res) => res.json())
            .then(autocompleteSchema.parse),
};
