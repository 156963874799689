import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { AuthContextProps } from "react-oidc-context";
import { AppError } from "shared/components/global/AppError/AppError";
import { NotFound } from "shared/components/global/NotFound/NotFound";
import { AsideProvider } from "shared/utils/aside";
import { Permission, UserQuery } from "src/gql/graphql";

type AuthRouterContext = {
    auth: Promise<AuthContextProps>;
    assertPermission: (userPerms: UserQuery["user"]["clientPermissions"], neededPerm: Permission, id?: string) => void;
    assertPermissions: (opts: [userPerms: UserQuery["user"]["clientPermissions"], neededPerm: Permission, id?: string][]) => void;
};

const Component = () => {
    return (
        <AsideProvider>
            <Outlet />
            {import.meta.env.VITE_ENVIRONMENT !== "prod" && !import.meta.env.VITE_DISABLE_DEVTOOLS && (
                <>
                    <TanStackRouterDevtools position="bottom-right" initialIsOpen={false} />
                    <ReactQueryDevtools position="right" initialIsOpen={false} />
                </>
            )}
        </AsideProvider>
    );
};

/** @public */
export const Route = createRootRouteWithContext<AuthRouterContext>()({
    errorComponent: AppError,
    notFoundComponent: NotFound,
    component: Component,
});
