import maplibre from "maplibre-gl";
import Map, { NavigationControl } from "react-map-gl/maplibre";

export const MapBase = ({ children, ...props }: Parameters<typeof Map>[0]) => (
    <Map
        attributionControl={false}
        doubleClickZoom={false}
        dragRotate={false}
        mapLib={maplibre}
        mapStyle={`https://api.radar.io/maps/styles/radar-default-v1?publishableKey=${import.meta.env.VITE_RADAR_KEY}`}
        initialViewState={{
            zoom: 12,
        }}
        {...props}
    >
        <NavigationControl position="bottom-left" />
        {children}
    </Map>
);
