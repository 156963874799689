import { Combobox, Loader, TextInput, useCombobox } from "@mantine/core";
import { useDeferredValue, useEffect, useState } from "react";
import { MapRef, useMap } from "react-map-gl/maplibre";
import { radarApi, RadarAutocomplete } from "shared/api/radarApi";
import { createDebounce, voiding } from "shared/utils/fns";
import { logger } from "shared/utils/logger";

type MapSearchProps = {
    mapId: string;
};

export const MapSearch = (props: MapSearchProps) => {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const [isLoading, setLoading] = useState(false);
    const [near, setNear] = useState<GeoJSON.Position>();
    const [data, setData] = useState<RadarAutocomplete["addresses"]>([]);
    const [value, setValue] = useState("");

    const { [props.mapId]: map } = useMap() as Record<string, MapRef | undefined>;
    useEffect(() => {
        const { debouncing } = createDebounce();
        if (!map) return;

        function handleMapMove() {
            debouncing(() => {
                setNear(map?.getCenter().toArray());
            });
        }

        map.on("move", handleMapMove);
        return () => {
            map.off("move", handleMapMove);
        };
    }, [map]);

    function refetchOptions(query: string) {
        if (query == "") return setData([]);
        setLoading(true);

        radarApi
            .autocomplete(query, near)
            .then(({ addresses }) => setData(addresses))
            .catch(logger.error)
            .finally(() => setLoading(false));
    }

    const options = data.map((item) => (
        <Combobox.Option
            value={item.formattedAddress}
            key={item.formattedAddress}
            onClick={() => map?.flyTo({ center: item.geometry.coordinates as [number, number], animate: true, zoom: 17 })}
        >
            {item.formattedAddress}
        </Combobox.Option>
    ));

    const deferredQuery = useDeferredValue(value);
    useEffect(() => {
        refetchOptions(deferredQuery);
    }, [deferredQuery]);

    return (
        <Combobox
            onOptionSubmit={(optionValue) => {
                setValue(optionValue);
                combobox.closeDropdown();
            }}
            withinPortal={false}
            store={combobox}
        >
            <Combobox.Target>
                <TextInput
                    placeholder="Search addresses..."
                    value={value}
                    onChange={(event) => {
                        setValue(event.currentTarget.value);
                        combobox.resetSelectedOption();
                        combobox.openDropdown();
                    }}
                    onClick={voiding(combobox.openDropdown)}
                    onFocus={voiding(combobox.openDropdown)}
                    onBlur={voiding(combobox.closeDropdown)}
                    rightSection={isLoading && <Loader type="oval" size={16} />}
                />
            </Combobox.Target>

            <Combobox.Dropdown hidden={data.length == 0}>
                <Combobox.Options>
                    {options}
                    {options.length == 0 && value.trim() != "" && !isLoading && <Combobox.Empty>No results found</Combobox.Empty>}
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
};
