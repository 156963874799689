export function searchTree<T extends TreeLike>(root: T, predicate: (node: T) => boolean) {
    const matched = new Array<T>();

    function recurse(node: T) {
        if (predicate(node)) matched.push(node);
        node.children?.forEach((it) => recurse(it as T));
    }

    recurse(root);
    return matched;
}

/** @private */
type TreeLike = {
    id: string;
    children?: TreeLike[];
};
