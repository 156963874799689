import { rem } from "@mantine/core";
import { Link, RichTextEditor } from "@mantine/tiptap";
import Placeholder from "@tiptap/extension-placeholder";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useComposeFormContext } from "shared/components/message/compose/context";
import { useFormSubscription } from "shared/hooks/useFormSubscription";
import { catching } from "shared/utils/fns";

export const MessageBodyEditor = () => {
    const form = useComposeFormContext();
    const richbody = useFormSubscription(form, "richBody");

    const editor = useEditor({
        extensions: [StarterKit, Link, Placeholder.configure({ placeholder: "Let people know what's going on..." })],
        content: catching<object>(() => JSON.parse(richbody)) ?? richbody,
        onUpdate({ editor }) {
            form.setFieldValue("richBody", JSON.stringify(editor.getJSON()));
            form.setFieldValue("body", editor.state.doc.content.content.map((each) => each.textContent).join("\n"));
        },
    });

    return (
        <RichTextEditor editor={editor} mih={rem(300)}>
            <RichTextEditor.Toolbar sticky stickyOffset={60}>
                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Underline />
                    <RichTextEditor.Strikethrough />
                    <RichTextEditor.ClearFormatting />
                    <RichTextEditor.Code />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.H1 />
                    <RichTextEditor.H2 />
                    <RichTextEditor.H3 />
                    <RichTextEditor.H4 />
                    <RichTextEditor.H5 />
                    <RichTextEditor.H6 />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Hr />
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link />
                    <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Undo />
                    <RichTextEditor.Redo />
                </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
        </RichTextEditor>
    );
};
