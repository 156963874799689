import { ActionIcon, Badge, BadgeProps } from "@mantine/core";
import { IconX } from "@tabler/icons-react";

type BadgeItemProps = {
    onDelete?: () => unknown;
} & BadgeProps;

export const BadgeItem = ({ onDelete, ...rest }: BadgeItemProps) => {
    return (
        <Badge
            variant="light"
            radius="md"
            rightSection={
                <ActionIcon size="xs" variant="transparent" onClick={onDelete}>
                    <IconX color="var(--mantine-primary-color-light-color)" />
                </ActionIcon>
            }
            {...rest}
        />
    );
};
