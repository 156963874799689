import { Text, Title } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";
import { Content } from "shared/components/global/Content";

/** @public */
export const Route = createFileRoute("/account/privacy")({
    component: () => (
        <Content>
            <Content.Heading>
                <Title order={3}>Bryx Mass Notification System Privacy Policy</Title>
            </Content.Heading>
            <Text>
                I'm baby lyft pug prism kinfolk, la croix wolf fit flexitarian hammock lumbersexual bruh ennui flannel shabby chic. Pabst
                gatekeep banh mi, hella activated charcoal deep v small batch. Tonx tumeric green juice schlitz tousled venmo hell of fixie
                church-key lumbersexual subway tile. Roof party marfa four loko cupping mukbang 90's etsy pour-over. Etsy helvetica small
                batch portland same knausgaard meh hot chicken ennui neutra williamsburg craft beer cornhole. Tousled sus waistcoat
                fingerstache tilde sartorial subway tile gastropub coloring book bushwick irony. Literally pok pok kitsch ramps next level
                pop-up marxism cold-pressed tattooed.
            </Text>
            <Text>
                Bitters meh stumptown chia lyft before they sold out photo booth readymade austin leggings cold-pressed church-key fashion
                axe. Waistcoat whatever jianbing pitchfork banh mi selvage wayfarers skateboard fam. Cronut af stumptown, vice raw denim
                banjo tote bag. Mukbang ennui tattooed vexillologist 90's quinoa green juice chartreuse grailed iceland gluten-free listicle
                twee.
            </Text>
            Helvetica church-key hexagon bitters food truck, man braid sustainable fashion axe sus flannel heirloom semiotics praxis JOMO.
            Blackbird spyplane umami iceland same irony slow-carb freegan paleo big mood lumbersexual swag kitsch. Gentrify leggings
            skateboard messenger bag pork belly vibecession. Meggings lyft typewriter blog succulents salvia fam quinoa shoreditch.
            <Text>
                Brooklyn praxis humblebrag mustache vegan woke mumblecore, before they sold out brunch bushwick small batch. Pok pok lyft
                four loko locavore flannel. Typewriter keffiyeh taxidermy, bicycle rights four loko meh pok pok marfa pitchfork coloring
                book live-edge flexitarian fingerstache. Hot chicken typewriter master cleanse mlkshk squid neutra XOXO grailed vape cred
                asymmetrical kitsch shabby chic man bun blog. Lumbersexual taiyaki cold-pressed meh, poutine vegan beard chartreuse keytar
                vice vinyl slow-carb fingerstache bespoke. Keffiyeh biodiesel seitan cold-pressed. Everyday carry crucifix vaporware jean
                shorts, bicycle rights flannel swag.
            </Text>
            <Text>
                Cupping YOLO sus typewriter deep v gluten-free. Polaroid bodega boys pickled prism. Hot chicken XOXO grailed solarpunk,
                yuccie paleo shabby chic vibecession live-edge selfies knausgaard iceland sus. Meditation street art adaptogen keffiyeh
                edison bulb mustache church-key microdosing narwhal letterpress chartreuse you probably haven't heard of them praxis next
                level taxidermy. Hammock try-hard celiac VHS umami sus semiotics portland shaman flannel. Gluten-free +1 chicharrones, vegan
                gentrify single-origin coffee chia.
            </Text>
        </Content>
    ),
});
