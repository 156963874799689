import { Loader } from "@mantine/core";
import { createFileRoute, redirect, useRouter } from "@tanstack/react-router";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { logoutFunction } from "shared/api/authApi";
import { logger } from "shared/utils/logger";

/**
 * @public
 */
export const Route = createFileRoute("/logout")({
    component: () => {
        const router = useRouter();
        const auth = useAuth();

        useEffect(() => {
            async function logoutWrapper() {
                if (auth.isAuthenticated) await logoutFunction(auth);
                await router.invalidate().then(() => redirect({ to: "/login" }));
            }
            logoutWrapper().catch(logger.error);
        }, []);

        return <Loader />;
    },
});
