import { MantinePrimaryShade, useMantineTheme } from "@mantine/core";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { bbox } from "@turf/bbox";
import { useEffect } from "react";
import { Layer, Source, useMap } from "react-map-gl/maplibre";
import { httpPostGraphql } from "shared/api/httpClient";
import { useColorMode } from "shared/hooks/useColorMode";
import { qk } from "shared/utils/qk";
import { geozod } from "shared/utils/zod";
import { graphql } from "src/gql";

/**
 * Renders the customer's boundary as a polygon on the map, and sets the maps bounds to fit that boundary
 */
export const MapBoundaryOverlay = () => {
    const map = useMap();
    const theme = useMantineTheme();
    const { isDark } = useColorMode();

    const { data: boundary } = useSuspenseQuery(query);

    useEffect(() => {
        const [minLng, minLat, maxLng, maxLat] = bbox(boundary);
        map.current?.fitBounds(
            [
                [minLng, minLat],
                [maxLng, maxLat],
            ],
            { padding: 40, duration: 0 },
        );
    }, []);

    return (
        <Source id="customer-boundary" type="geojson" data={boundary}>
            <Layer
                id="customer-boundary-layer"
                type="line"
                layout={{ "line-join": "round", "line-cap": "round" }}
                paint={{
                    // we have to hand-jam responsive primary shading here because we are rendering directly to a canvas, so we can't use css variables :(
                    "line-color": theme.colors[theme.primaryColor][(theme.primaryShade as MantinePrimaryShade)[isDark ? "dark" : "light"]],
                    "line-width": 2,
                }}
            />
        </Source>
    );
};

const query = queryOptions({
    queryKey: qk("map", "customer", "bounds"),
    queryFn: () =>
        httpPostGraphql(
            graphql(`
                query MapBoundaryControlCustomerBounds {
                    customer {
                        boundary
                    }
                }
            `),
            {},
        ).then(({ customer: { boundary } }) => geozod.polygon().parse(JSON.parse(boundary))),
});
