import { IconChartPie2, IconInbox, IconMapPin, IconSettings } from "@tabler/icons-react";
import { SidebarLinkData } from "shared/components/global/Sidebar/SidebarLink";
import { Permission } from "src/gql/graphql";

export const sidebarLinkData: SidebarLinkData[] = [
    {
        icon: IconInbox,
        label: "Messages",
        to: "/messages",
        index: 0,
        subLinks: {
            header: "Messages",
            data: [
                { label: "Inbox", to: "/messages/inbox" },
                {
                    label: "Drafts",
                    to: "/messages/drafts",
                },
                {
                    label: "Scheduled",
                    to: "/messages/scheduled",
                },
                {
                    label: "Sent",
                    to: "/messages/sent",
                },
            ],
        },
    },
    { icon: IconMapPin, label: "Map", to: "/map", index: 1 },
    {
        icon: IconChartPie2,
        label: "Organization",
        to: "/organization",
        index: 2,
        subLinks: {
            header: "Organization",
            data: [
                { label: "Audit Logs", to: "/organization/auditLogs" },
                {
                    label: "Groups",
                    to: "/organization/groups",
                    permissions: [{ value: Permission.GroupSubgroupManage }],
                },
                { label: "Reports", to: "/organization/reports" },
                { label: "Roles", to: "/organization/roles" },
                { label: "Templates", to: "/organization/templates" },
                { label: "Topics", to: "/organization/topics/list", permissions: [{ value: Permission.TopicCategoryManage }] },
                { label: "Users", to: "/organization/users" },
            ],
        },
    },
    { icon: IconSettings, label: "Settings", to: "/settings", position: "bottom", index: 3 },
];
