import { type TypedDocumentNode } from "@graphql-typed-document-node/core";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { type ExecutionResult } from "graphql";
import { httpPostGraphql, StrictUndef } from "shared/api/httpClient";
import { AbstractedMutationOptions } from "shared/utils/http";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";

type GraphqlMutationArgs<T, V> = {
    document: TypedDocumentNode<T, V>;
} & AbstractedMutationOptions<StrictUndef<ExecutionResult<T>["data"]>, V>;

export function useGraphqlMutation<T, V>(document: GraphqlMutationArgs<T, V>["document"]): UseMutationResult<T, Error, V, unknown>;
export function useGraphqlMutation<T, V>(args: GraphqlMutationArgs<T, V>): UseMutationResult<T, Error, V, unknown>;
export function useGraphqlMutation<T, V>(args: GraphqlMutationArgs<T, V> | GraphqlMutationArgs<T, V>["document"]) {
    if ("document" in args) {
        const { document, onSuccess, onError, ...rest } = args;
        return useMutation({
            throwOnError: false,
            mutationFn: (variables) => httpPostGraphql<T, V>(document, variables),
            onSuccess: (res, ...rest) => {
                onSuccess?.(res, ...rest);
            },
            onError: (err, ...rest) => {
                onError?.(err, ...rest);
                notify.show.error({ message: "Your request could not be processed" });
                logger.error(err);
            },
            ...rest,
        });
    } else {
        return useGraphqlMutation({ document: args });
    }
}
