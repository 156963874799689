import {
    ActionIcon,
    Box,
    Button,
    Checkbox,
    Combobox,
    Group,
    Input,
    Modal,
    NavLink,
    Pill,
    PillsInput,
    Skeleton,
    Stack,
    Text,
    useCombobox,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronRight } from "@tabler/icons-react";
import { queryOptions, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { ReactNode, useEffect, useState } from "react";
import { rootGroupQuery } from "routes/_auth/organization/groups";
import { userQueryOptions } from "routes/_auth/route";
import { httpPostGraphql } from "shared/api/httpClient";
import { permissionCheck } from "shared/stores/oidc";
import { useUserId } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { GroupNode, Permission, UserNode } from "src/gql/graphql";

export type GroupInfo = Pick<GroupNode, "id" | "name" | "internalJoinable"> & {
    members: Pick<UserNode, "id" | "firstName" | "lastName">[];
    children?: GroupInfo[];
    parentIds?: GroupInfo[];
    synchronized?: boolean;
};

export type GroupParent = Pick<GroupInfo, "id" | "name">;

export const GroupTreeNode = ({
    group,
    expandedNodes,
    checkboxOnClick,
    chevronOnClick,
    isDisabledPredicate,
    shouldRedirect,
    selectedCheckboxes,
    skipNodePredicate,
    renderLabel,
    shouldRenderCheckbox,
}: {
    group: GroupInfo;
    expandedNodes: string[];
    shouldRedirect?: boolean;
    chevronOnClick?: (node: GroupInfo) => void;
    checkboxOnClick?: (node: GroupInfo) => void;
    selectedCheckboxes?: string[];
    isDisabledPredicate?: (group: GroupInfo) => boolean;
    skipNodePredicate?: (group: GroupInfo) => boolean;
    renderLabel: (group: GroupInfo) => ReactNode;
    shouldRenderCheckbox?: (group: GroupInfo) => boolean;
}) => {
    const [fetchChildren, setFetchChildren] = useState(false);
    const { data, isLoading } = useQuery({
        queryKey: qk("organization", "group", group.id),
        queryFn: () => httpPostGraphql(groupQuery, { groupId: group.id }),
        enabled: fetchChildren,
    });

    const children = (!!data ? data.group.children : group.children)?.filter((c) => !skipNodePredicate?.(c));
    const opened = expandedNodes.includes(group.id ?? "");

    const willRenderCheckbox = !!checkboxOnClick && !!selectedCheckboxes && (!!shouldRenderCheckbox ? shouldRenderCheckbox(group) : true);

    const expandButton: ReactNode = (
        <ActionIcon variant="subtle" color="var(--mantine-color-text)">
            <IconChevronRight
                opacity={isDisabledPredicate?.(group) ? 0.4 : undefined}
                className={expandedNodes.includes(group.id ?? "") ? "expandChevron" : "collapseChevron"}
                onClick={() => {
                    if (children?.some((c) => c.children === undefined)) setFetchChildren(true);
                    chevronOnClick?.(group);
                }}
            />
        </ActionIcon>
    );

    return (
        <Stack gap={0} w="min-content" style={{ display: "inline-flex" }}>
            <Group w="min-content" gap={0} p="sm" wrap="nowrap" style={{ whiteSpace: "nowrap" }}>
                {willRenderCheckbox && (
                    <Checkbox
                        checked={selectedCheckboxes!.includes(group.id)}
                        onChange={() => checkboxOnClick!(group)}
                        pr="sm"
                        disabled={isDisabledPredicate?.(group)}
                    />
                )}
                {children?.length !== 0 && expandButton}
                {renderLabel(group)}
            </Group>
            <Stack gap={0} style={{ display: opened ? undefined : "none" }}>
                {isLoading ? (
                    <Skeleton>
                        <NavLink>Placeholder name</NavLink>
                    </Skeleton>
                ) : (
                    <Box
                        w="fit-content"
                        style={{
                            borderLeft: "var(--mantine-spacing-md) solid transparent",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {children?.map((c) => (
                            <GroupTreeNode
                                key={c.id + c.children?.map((c) => c.id).join()}
                                group={c}
                                expandedNodes={expandedNodes}
                                chevronOnClick={chevronOnClick}
                                shouldRedirect={shouldRedirect}
                                skipNodePredicate={skipNodePredicate}
                                checkboxOnClick={checkboxOnClick}
                                isDisabledPredicate={isDisabledPredicate}
                                selectedCheckboxes={selectedCheckboxes}
                                renderLabel={renderLabel}
                                shouldRenderCheckbox={shouldRenderCheckbox}
                            />
                        ))}
                    </Box>
                )}
            </Stack>
        </Stack>
    );
};

export const GroupTreeModal = ({
    rootGroup,
    currentGroup,
    disabled,
    values,
    onChange,
    errorText,
    target,
    shouldRenderCheckbox,
}: {
    rootGroup: GroupInfo;
    currentGroup?: GroupInfo;
    values: GroupParent[];
    onChange: ((parents: GroupParent[]) => void) | ((parents: GroupParent[]) => Promise<void>);
    disabled?: boolean;
    errorText?: string;
    target?: (onClick: () => void) => ReactNode;
    shouldRenderCheckbox?: (group: GroupInfo) => boolean;
}) => {
    const combobox = useCombobox();
    const [opened, { open, close }] = useDisclosure();
    const [expandedNodes, setExpandedNodes] = useState<string[]>([rootGroup.id]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<GroupParent[]>([]);
    const { userId } = useUserId();

    const { data: permissions } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.clientPermissions });

    function isDisabledPredicate(g: GroupInfo) {
        return !permissionCheck(permissions, Permission.GroupSubgroupManage, g.id) || !!(currentGroup?.synchronized && g.synchronized);
    }

    useEffect(() => {
        setSelectedCheckboxes(values);
    }, [values]);

    return (
        <>
            <Modal opened={opened} onClose={close} title="Select Groups">
                <Stack>
                    <Box mah="75%">
                        <GroupTreeNode
                            group={rootGroup}
                            expandedNodes={expandedNodes}
                            chevronOnClick={(group) => {
                                if (expandedNodes.includes(group.id)) setExpandedNodes(expandedNodes.filter((g) => g != group.id));
                                else setExpandedNodes(expandedNodes.concat(group.id));
                            }}
                            checkboxOnClick={(node: GroupParent) =>
                                setSelectedCheckboxes(
                                    selectedCheckboxes.some((n) => n.id == node.id)
                                        ? selectedCheckboxes.filter((n) => n.id != node.id)
                                        : selectedCheckboxes.concat(node),
                                )
                            }
                            selectedCheckboxes={selectedCheckboxes.map((n) => n.id)}
                            isDisabledPredicate={isDisabledPredicate}
                            skipNodePredicate={(g) => g.id == currentGroup?.id}
                            renderLabel={(g) => <Text opacity={isDisabledPredicate(g) ? 0.4 : undefined}>{g.name}</Text>}
                            shouldRenderCheckbox={shouldRenderCheckbox}
                        />
                    </Box>
                    <Group>
                        <Button
                            variant="default"
                            style={{ flexGrow: 1 }}
                            onClick={() => {
                                close();
                                setSelectedCheckboxes(values);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ flexGrow: 1 }}
                            onClick={() => {
                                onChange(selectedCheckboxes)?.catch(logger.error);
                                close();
                            }}
                        >
                            Continue
                        </Button>
                    </Group>
                </Stack>
            </Modal>
            <Combobox store={combobox}>
                <Combobox.DropdownTarget>
                    {!!target ? (
                        target(open)
                    ) : (
                        <PillsInput
                            pointer
                            onClick={!disabled && currentGroup?.id != rootGroup.id ? open : undefined}
                            disabled={disabled || currentGroup?.id == rootGroup.id}
                            error={errorText}
                        >
                            <Pill.Group>
                                {values.length > 0 ? (
                                    values.map((g: GroupParent) => (
                                        <Pill
                                            key={g.id}
                                            withRemoveButton
                                            disabled={disabled}
                                            onRemove={() => {
                                                onChange(values.filter((x: GroupParent) => x.id != g.id))?.catch(logger.error);
                                            }}
                                        >
                                            {g.name}
                                        </Pill>
                                    ))
                                ) : currentGroup?.id == rootGroup.id ? (
                                    <Text fs="italic">This group cannot have any parents</Text>
                                ) : (
                                    <Input.Placeholder>Pick one or more values</Input.Placeholder>
                                )}

                                <Combobox.EventsTarget>
                                    <PillsInput.Field
                                        type="hidden"
                                        onBlur={() => combobox.closeDropdown()}
                                        onKeyDown={(event) => {
                                            if (event.key === "Backspace") {
                                                event.preventDefault();
                                                onChange(values.slice(0, -1))?.catch(logger.error);
                                            }
                                        }}
                                    />
                                </Combobox.EventsTarget>
                            </Pill.Group>
                        </PillsInput>
                    )}
                </Combobox.DropdownTarget>
            </Combobox>
        </>
    );
};

export const rootGroupQueryOptions = queryOptions({
    queryKey: qk("organization", "rootGroup"),
    queryFn: () => httpPostGraphql(rootGroupQuery, {}),
});

const groupQuery = graphql(`
    query GroupTreeChildGroup($groupId: UUID!) {
        group(id: $groupId) {
            id
            name
            internalJoinable
            synchronized
            members {
                id
                firstName
                lastName
            }
            children {
                id
                name
                internalJoinable
                synchronized
                members {
                    id
                    firstName
                    lastName
                }
                children {
                    id
                    name
                    internalJoinable
                    synchronized
                    members {
                        id
                        firstName
                        lastName
                    }
                    children {
                        id
                        name
                        internalJoinable
                        synchronized
                        members {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }
        }
    }
`);
