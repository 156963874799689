import { Group, Stack, TextInput } from "@mantine/core";
import { useAddressFormContext } from "shared/components/address/context";

export const AddressForm = () => {
    const form = useAddressFormContext();

    return (
        <Stack>
            <TextInput withAsterisk label="Name" {...form.getInputProps("name")} />
            <TextInput withAsterisk label="Address" {...form.getInputProps("street")} />
            <TextInput label="Address line 2" {...form.getInputProps("street2")} />
            <Group>
                <TextInput flex={1} withAsterisk label="City" {...form.getInputProps("city")} />
                <TextInput flex={1} withAsterisk label="State" {...form.getInputProps("state")} />
                <TextInput flex={1} withAsterisk label="Zip" {...form.getInputProps("zip")} />
            </Group>
        </Stack>
    );
};
