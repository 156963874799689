import { ActionIcon, Box, Group, Menu, MenuItemProps, Paper, Stack, Text, Title } from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import { ReactNode } from "@tanstack/react-router";
import { MessageStatusBadge } from "shared/components/message/MessageStatusBadge";
import { titlecase } from "shared/utils/fns";
import { getRelativeTimeString } from "shared/utils/temporal";
import { MessageSeverity, MessageStatus } from "src/gql/graphql";

type MessageDetailCardProps = {
    title: string;
    unread?: boolean;
    timestamp?: Date | string;
    severity: MessageSeverity;
    body: string;
    status?: MessageStatus;
    actions?: ({ icon: ReactNode; label: string; onClick: (e: React.MouseEvent) => unknown } & Omit<MenuItemProps, "onClick">)[];
};

export const MessageDetailCard = ({ title, unread, timestamp, status, severity, body, actions, ...rest }: MessageDetailCardProps) => {
    return (
        <Box w="100%" {...rest}>
            <Paper shadow="md" radius="md" px="sm" py="md" w="100%">
                <Group justify="space-between" wrap="nowrap" align="flex-start">
                    <Title order={3} c={!unread ? "dimmed" : undefined}>
                        {title}
                    </Title>
                    <Group wrap="nowrap" onClick={(e) => e.stopPropagation()}>
                        {status && <MessageStatusBadge status={status} />}
                        {!!actions?.length && (
                            <Menu>
                                <Menu.Target>
                                    <ActionIcon variant="transparent">
                                        <IconDots size={16} />
                                    </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    {actions.map(({ label, icon, onClick, ...rest }) => (
                                        <Menu.Item
                                            key={label}
                                            leftSection={icon}
                                            rightSection={
                                                <Text size="sm" c="inherit">
                                                    {label}
                                                </Text>
                                            }
                                            onClick={onClick}
                                            {...rest}
                                        />
                                    ))}
                                </Menu.Dropdown>
                            </Menu>
                        )}
                    </Group>
                </Group>

                <Stack gap={"xs"}>
                    <Text size="sm" c={!unread ? "dimmed" : undefined}>
                        {`${titlecase(severity.toLowerCase())} ${!!timestamp ? "| " + getRelativeTimeString(typeof timestamp == "string" ? new Date(+timestamp) : timestamp) : ""}`}
                    </Text>
                    <Text lineClamp={4} c={!unread ? "dimmed" : undefined}>
                        {body}
                    </Text>
                </Stack>
            </Paper>
        </Box>
    );
};
