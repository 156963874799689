import { Button, Group, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate, useParams } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { SplitButton } from "shared/components/global/SplitButton";
import { ComposeFormProvider, composeFormStateToMessageInfoInput, useComposeForm } from "shared/components/message/compose/context";
import { SendPanel } from "shared/components/message/compose/SendPanel";
import { ComposeForm } from "shared/components/message/ComposeForm";
import { MessageStatusBadge } from "shared/components/message/MessageStatusBadge";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { to } from "shared/utils/fns";
import { createRandomStr } from "shared/utils/fns";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { geozod } from "shared/utils/zod";
import { graphql } from "src/gql";
import { MessageStatus } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const nav = useNavigate();
    const { id } = useParams({ from: "/_auth/messages/drafts/$id" });
    const {
        data: { message },
    } = useSuspenseQuery(query(id));

    const { mutateAsync: saveDraft } = useGraphqlMutation(mutations.saveDraft);
    const { mutateAsync: sendMessage } = useGraphqlMutation(mutations.sendMessage);

    function handleSaveDraft() {
        const validation = form.validate();
        if (validation.hasErrors) {
            logger.warn(validation.errors);
            return;
        }

        void saveDraft({
            id,
            params: composeFormStateToMessageInfoInput.parse(form.getValues()),
        }).then(({ updateMessage: { subject } }) => notify.show.success({ message: `Saved draft '${subject}'.` }));
    }

    function handleSend() {
        if (form.validate().hasErrors) return;

        modals.open({
            title: "Are you sure?",
            children: (
                <SendPanel
                    onClose={() => modals.closeAll()}
                    onSend={({ expireAt }) => {
                        void saveDraft({ id, params: composeFormStateToMessageInfoInput.parse(form.getValues()) })
                            .then(async ({ updateMessage: { id } }) => sendMessage({ id, expireAt }))
                            .then(() => notify.show.success({ message: "Message sent!" }))
                            .then(() => modals.closeAll())
                            .then(() => nav({ to: "/messages/sent" }));
                    }}
                />
            ),
        });
    }

    const form = useComposeForm({
        initialValues: {
            ...message,
            audience: {
                ...message.audience,
                groups: message.audience.groups.map((it) => ({ ...it, size: it.members.length })),
                geofilters: message.audience.geofilters.map(to("id")),
                features: message.audience.geometries
                    .map((s) => JSON.parse(s))
                    .map((geometry) => geozod.feature(geozod.polygon()).parse({ type: "Feature", id: createRandomStr(), geometry })),
            },
        },
    });

    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={3}>Edit Message</Title>
                <MessageStatusBadge status={MessageStatus.Draft} />
            </Content.Heading>

            <ComposeFormProvider form={form}>
                <ComposeForm />
            </ComposeFormProvider>

            <Group justify="flex-end" wrap="nowrap">
                <Button variant="transparent" onClick={handleSaveDraft}>
                    Save
                </Button>

                <SplitButton onClick={handleSend} menu={{ Schedule: { fn: () => {} } }}>
                    Review and send
                </SplitButton>
            </Group>
        </Content>
    );
};

const query = (id: string) =>
    queryOptions({
        queryKey: qk("messages", "composer", "message", id),
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query ComposeMessage($id: UUID!) {
                        message(id: $id) {
                            id
                            subject
                            body
                            richBody
                            files {
                                id
                            }
                            severity
                            sender {
                                id
                            }
                            audience {
                                geometries
                                geofilters {
                                    id
                                }
                                users {
                                    id
                                    firstName
                                    lastName
                                }
                                groups {
                                    id
                                    name
                                    members {
                                        id
                                    }
                                }
                                topics {
                                    id
                                    name
                                }
                            }
                            options {
                                method
                            }
                        }
                    }
                `),
                { id },
            ),
    });

const mutations = {
    saveDraft: graphql(`
        mutation DraftsSave($id: UUID!, $params: MessageInfoInput!) {
            updateMessage(id: $id, params: $params) {
                id
                subject
            }
        }
    `),
    sendMessage: graphql(`
        mutation DraftsSendMesage($id: UUID!, $expireAt: Instant) {
            sendMessage(id: $id, expireAt: $expireAt) {
                id
            }
        }
    `),
};

/** @public */
export const Route = createFileRoute("/_auth/messages/drafts/$id")({
    loader: ({ params: { id } }) => queryClient.ensureQueryData(query(id)),
    component: Component,
});
