import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { logger } from "shared/utils/logger";

/** @public */
export const Route = createFileRoute("/account/callback")({
    component: () => {
        const auth = useAuth();
        const navigate = useNavigate();

        useEffect(() => {
            if (auth.isLoading) return;
            navigate({ to: "/" }).catch(logger.error);
        }, [auth.isLoading]);
    },
});
