import { z } from "zod";

export const nonemptyString = () => z.string().trim().min(1, "This field is required");
export const nonemptyUUID = () => z.string().uuid("This field is required");

/**
 * Zod bindings for GeoJSON
 */
export const geozod = {
    position: () => z.number().array().min(2).max(3) as z.ZodType<GeoJSON.Position>,
    point: () => z.object({ type: z.literal("Point"), coordinates: geozod.position() }) satisfies z.ZodType<GeoJSON.Point>,
    polygon: () =>
        z.object({
            type: z.literal("Polygon"),
            coordinates: geozod.position().array().array(),
        }) satisfies z.ZodType<GeoJSON.Polygon>,
    multipolygon: () =>
        z.object({
            type: z.literal("MultiPolygon"),
            coordinates: geozod.position().array().array().array(),
        }) satisfies z.ZodType<GeoJSON.MultiPolygon>,
    feature: <G extends GeoJSON.Geometry>(geometry: z.ZodType<G>) =>
        z.object({
            id: z.coerce.string(), // per the official RFC-7946, this 'id' is optional but for our use cases we want to keep it required
            type: z.literal("Feature"),
            properties: z.object({}).passthrough().default({}),
            geometry,
        }) as z.ZodType<GeoJSON.Feature<G>>,
};
