import { Box, SegmentedControl, Stack } from "@mantine/core";
import { useState } from "react";
import { MapProvider } from "react-map-gl/maplibre";
import { MapBase } from "shared/components/map/MapBase";
import { MapBoundaryOverlay } from "shared/components/map/MapBoundaryOverlay";
import { MapGeoFilterOverlay } from "shared/components/map/MapGeoFilterOverlay";
import { MapPolygonDrawOverlay } from "shared/components/map/MapPolygonDrawOverlay";
import { MapSearch } from "shared/components/map/MapSearch";
import { useComposeFormContext } from "shared/components/message/compose/context";
import { useFormSubscription } from "shared/hooks/useFormSubscription";
import { useIsMobile } from "shared/hooks/useIsMobile";

export const GeoLocationEditor = () => {
    const form = useComposeFormContext();
    const features = useFormSubscription(form, "audience.features");
    const geofilters = useFormSubscription(form, "audience.geofilters");

    const isMobile = useIsMobile();
    const [tab, setTab] = useState("Draw");

    return (
        <MapProvider>
            <Stack>
                <SegmentedControl value={tab} onChange={setTab} w="fit-content" data={["Draw", "Shape"]} />
                <MapSearch mapId="geo-location-editor-map" />
                <Box pos="relative" h={isMobile ? "50dvh" : "65dvh"} w={isMobile ? "80dvw" : "60dvw"} style={{ overflow: "clip" }}>
                    <MapBase id="geo-location-editor-map" interactiveLayerIds={[MapGeoFilterOverlay.layerId]}>
                        <MapBoundaryOverlay />

                        {tab == "Draw" && (
                            <MapPolygonDrawOverlay
                                position="top-left"
                                features={features}
                                onChange={(fs) => form.setFieldValue("audience.features", fs)}
                            />
                        )}
                        {tab == "Shape" && (
                            <MapGeoFilterOverlay
                                geofilterIds={geofilters}
                                onChange={(gfs) => form.setFieldValue("audience.geofilters", gfs)}
                            />
                        )}
                    </MapBase>
                </Box>
            </Stack>
        </MapProvider>
    );
};
