import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useEffect, useRef } from "react";
import { ControlPosition, IControl, useControl } from "react-map-gl";
import { useMap } from "react-map-gl/maplibre";
import { uniqueBy } from "shared/utils/fns";

type MapDrawControlProps = {
    position?: ControlPosition;
    features: GeoJSON.Feature<GeoJSON.Polygon>[];
    onChange?: (features: GeoJSON.Feature<GeoJSON.Polygon>[]) => unknown;
};

export function MapPolygonDrawOverlay(props: MapDrawControlProps) {
    const map = useMap();

    useEffect(() => {
        function handleUpdate(evt: { features: GeoJSON.Feature<GeoJSON.Polygon>[] }) {
            props.onChange?.(evt.features.concat(props.features).filter(uniqueBy("id")));
        }

        function handleDelete(evt: { features: GeoJSON.Feature<GeoJSON.Polygon>[] }) {
            props.onChange?.(evt.features.filter((each) => !evt.features.some((deleted) => deleted.id == each.id)));
        }

        map.current?.on("draw.create", handleUpdate);
        map.current?.on("draw.update", handleUpdate);
        map.current?.on("draw.delete", handleDelete);

        return () => {
            map.current?.off("draw.create", handleUpdate);
            map.current?.off("draw.update", handleUpdate);
            map.current?.off("draw.delete", handleDelete);
        };
    }, [map.current, props.features]);

    const ctrl = useRef(
        new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                trash: true,
                polygon: true,
            },
        }),
    );

    useControl(
        () => ctrl.current as IControl,
        () => {
            props.features.forEach(ctrl.current.add);
        },
        () => {},
        {
            position: props.position,
        },
    );

    return null;
}
