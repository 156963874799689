/**
 * @see https://github.com/erwijet/typescriptly/blob/main/packages/%40tsly/core/src/types/unwind.ts
 */
type DeepUnwind<T> = T extends (infer Inner)[] ? DeepUnwind<Inner> : T extends ReadonlyArray<infer Inner> ? DeepUnwind<Inner> : T;

/** @private */
class TslyArray<T> {
    constructor(private inner: T[]) {}

    /**
     * Creates a clone of some given array, without calling the given array's internal iterator, thereby improving
     * performance, particularly for larger arrays. For more information refer to [this comparison benchmark](https://jsbench.me/fylb5kvldn/1)
     *
     * This operation yields the same result as `const newArr = [...arr];`
     */
    clone(): TslyArray<T> {
        return arr(new Array<T>().concat(this.inner));
    }

    /**
     * Moves an element at the specified index to a new index
     */
    move(opts: { from: number; to: number }): TslyArray<T>;
    /**
     * Moves the first element satisfying the given predicate to a new index
     */
    move(opts: { by: (el: T, i: number) => boolean; to: number }): TslyArray<T>;
    move(opts: ({ from: number } | { by: (el: T, i: number) => boolean }) & { to: number }) {
        if ("by" in opts) {
            const i = this.inner.findIndex(opts.by);
            if (i < 0) return this;

            return this.clone().morph((it) => {
                const [spliced] = it.splice(i, 1);
                return it.toSpliced(opts.to, 0, spliced);
            });
        } else {
            return this.clone().morph((it) => {
                const [spliced] = it.splice(opts.from, 1);
                return it.toSpliced(opts.to, 0, spliced);
            });
        }
    }

    /**
     * Swaps two elements in some array, returning a copy of the new array without modifying the source.
     *
     * @example
     * ```ts
     * arr(['apple', 'banana', 'pear']).(1, 2).take(); // ['apple', 'pear', 'banana']
     * ```
     */
    swap(i1: number, i2: number): TslyArray<T> {
        const cpy = this.clone().take();
        const tmp = cpy[i2];
        cpy[i2] = cpy[i1];
        cpy[i1] = tmp;

        return arr(cpy);
    }

    /**
     * Returns the last element of some array, and `null` if the array is empty
     */
    get last(): T | null {
        return this.inner.at(this.inner.length - 1) ?? null;
    }

    /**
     * Inserts the `toInsert` value at the first position of a given array at which the array value matches the supplied predicate.
     * If no values are found that match the predicate, then the original array is returned.
     *
     * @example
     * ```ts
     * arr([1, 2, null, 3, null, 4]).patch(
     *      9,                  // <-- value to insert
     *      (v) => v == null    // <-- predicate to match on
     * ).take();  // [1, 2, 9, 3, null, 4]
     * ```
     */
    patch(toInsert: T, predicate: (v: T) => boolean): TslyArray<T> {
        const { inner } = this;
        for (const [i, v] of inner.entries()) if (predicate(v)) return arr(inner.slice(0, i).concat([toInsert], inner.slice(i + 1)));
        return arr(inner.slice(0));
    }

    /**
     * Interleaves some value in-between each element of some array.
     *
     * @example
     * ```ts
     * arr(['apple', 'banana', 'orange'])
     *  .interleave('|').take(); // ['apple', '|', 'banana', '|', 'orange']
     * ```
     */
    interleave(toInsert: T) {
        return arr(this.inner.flatMap((e) => [toInsert, e]).slice(1));
    }

    /**
     * Checks if some given number, i, is a, index of some array, arr.
     * Equivilant to `i >= 0 && i < arr.length`
     */
    hasIdx(idx: number): boolean {
        return idx >= 0 && idx < this.inner.length;
    }

    /**
     * Chunks the input array into smaller arrays of the specified size.
     *
     * @example
     * ```ts
     * const nums= [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
     * arr(nums).chunk(3).take(); // [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10]]
     * ```
     */
    chunk(chunkSize: number): TslyArray<T[]> {
        const chunks: T[][] = [];
        const buf: T[] = [];

        this.inner.forEach((cur, i) => {
            buf.push(cur);

            if (buf.length == chunkSize || i + 1 == this.inner.length) {
                chunks.push([...buf]);
                buf.splice(0, buf.length); // clear buffer
            }
        });

        return arr(chunks);
    }

    /**
     * Returns a clone of the given array wih duplicate elements removed via the `filter/indexOf` method.
     *
     * @example
     * ```ts
     * arr([1, 1, 1, 2, 1, 3, 4, 4, 2, 1, 2])
     *  .dedup().take(); // [1, 2, 3, 4]
     * ```
     */
    dedup(eq: (a: T, b: T) => boolean = (a, b) => a == b): TslyArray<T> {
        return arr(this.inner.filter((cur, i) => this.inner.findIndex((each) => eq(each, cur)) == i));
    }

    /**
     * Recursively flattens a nested array, returning a new array with all elements flattened.
     *
     * ?> This method serves as a more typescript-friendly, ergonimic alternative to `Array.prototype.flat` called with `Number.POSITIVE_INFINITY`
     *
     * @example
     * ```ts
     * const arr1 = arr([[0], [[1]]]).deepFlat().take();
     * //    ^? number[]
     * //    returns [0, 1]
     *
     * const arr2 = arr([[["str"]], [false], [[5]]]).deepFlat().take();
     * //    ^? (string | boolean | number)[]
     * //    returns ["str", false, 5]
     * ```
     *
     * ---
     *
     * Contrast with with `Array.prototype.flat`
     * ```typescript
     * const arr = [[0], [[1]]].flat(Number.POSITIVE_INFINITY);
     * //    ^? FlatArray<number[] | number[][], 0 | 1 | 2 | -1 | 3 | 4 | 5 | ...
     * ```
     *
     */
    deepFlat(): TslyArray<DeepUnwind<T>> {
        return arr(this.inner.flatMap((el) => (Array.isArray(el) ? arr(el).deepFlat().take() : el)));
    }

    /**
     * Performs an update operation on the wrapped array.
     *
     * @example
     * ```ts
     * const people = [
     *  { name: "jonn", age: 21 },
     *  { name: "ben", age: 15 },
     *  { name: "autumn", age: 19 }
     * ]
     *
     * arr(people).update({
     *  where: ({ name }) => name == "ben", // select where name is "ben"
     *  to: { name: "jill", age: 20 } // use the 'to' property to specify a replacement element
     * }).take(); // [{ name: "jonn", age: 21 }, { name: "jill", age: 20 }, { name: "autumn", age 19 }]
     * ```
     *
     * #### Replacement elements can also be computed with the `by` property
     *
     * ```ts
     * arr(people).update({
     *  where: ({ age }) => age >= 18,
     *  by: (el) => ({ ...el, isAdult: true })
     * }).take();
     * // [
     * //   { name: "john", age: 21, isAdult: true },
     * //   { name: "bill": age: 15 },
     * //   { name: "autumn", age: 19, isAdult: true }
     * // ]
     * ```
     */
    update<E>(
        options: { where: (value: T, idx: number) => boolean } & ({ to: E } | { by: (value: T, idx: number) => E }),
    ): TslyArray<T | E> {
        return arr(
            this.inner.map((value, idx) => {
                if (!options.where(value, idx)) {
                    return value;
                }

                if ("by" in options) {
                    return options.by(value, idx);
                }

                return options.to;
            }),
        );
    }

    /**
     * Applies a traditional flatmap operation on the underlying array primitive. This is useful for calling prototype methods on the array structure.
     *
     * @example
     * ```
     * const nums = [1, 2, 3, 4, 4, 3, 2, 1]
     *
     * arr(nums)                                        // [1, 2, 3, 4, 4, 3, 2, 1]
     *  .update({ by: n => n + 1, where: n => n > 3 })  // [1, 2, 3, 5, 5, 3, 2, 1]
     *  .morph(it => it.toSpliced(0, 1))                // [2, 3, 5, 5, 3, 2, 1]
     *  .dedup()                                        // [2, 3, 5, 1]
     *  .take()
     * ```
     */
    morph<E>(fn: (it: T[]) => E[]): TslyArray<E> {
        return arr(fn(this.inner));
    }

    //

    /**
     * Construct a new array of a given size from the result of calling the given factory method with the respective index therein.
     *
     * @example
     * ```ts
     * arrFromFactory(5, (idx) => idx % 2 == 0 ? 'even' : 'odd');
     * // ['even', 'odd', 'even', 'odd', 'even'];
     * ```
     *
     * @category Array
     */
    static fromFactory<T>(size: number, by: (i: number) => T): TslyArray<T> {
        return arr(new Array(size).fill(null).map((_, i) => by(i)));
    }

    //

    [Symbol.iterator]() {
        return this.inner[Symbol.iterator]();
    }

    get isEmpty() {
        return this.inner.length == 0;
    }

    take(): T[];
    take<E>(mapping?: (it: T[]) => E): E;

    take(mapping?: (it: T[]) => unknown) {
        return typeof mapping == "function" ? mapping(this.inner) : this.inner;
    }

    into<E>(mapping: (it: T[]) => E): E {
        return this.take(mapping);
    }
}

/** @private */
function _builder<T>(inner: T[] | ReadonlyArray<T>): TslyArray<T> {
    return new TslyArray(inner as typeof inner extends ReadonlyArray<infer U> ? U[] : typeof inner);
}

/** @private */
type Arr = {
    <T>(inner: T[] | ReadonlyArray<T>): TslyArray<T>;
    make<T>(size: number, factory: (i: number) => T): TslyArray<T>;
};

export const arr: Arr = Object.assign(_builder, {
    make<T>(size: number, factory: (i: number) => T) {
        return TslyArray.fromFactory(size, factory);
    },
});
