import { Accordion, Paper } from "@mantine/core";
import { useForm } from "@mantine/form";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { GroupDetailsForm, MemberDetails, rootGroupQueryOptions, StagedMember, usersInfiniteQuery } from "shared/components/groups/Groups";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { usePermissionCheck } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { GroupDetailsInfoQuery, Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

// const SenderDetails = ({ senders }: { senders: GroupDetailsQuery["group"]["senders"] }) => {
//     // TODO
// };

// const TemplateDetails = ({ templates }: { templates: GroupDetailsQuery["group"]["template"] }) => {
//     // TODO
// };

const GroupDetailsContainer = () => {
    const { groupId } = Route.useParams();
    const {
        data: { group },
    } = useSuspenseQuery(groupInfoQueryOptions(groupId));

    const {
        data: { rootGroup },
    } = useSuspenseQuery(rootGroupQueryOptions);

    const { mutateAsync: updateGroupMutation } = useGraphqlMutation(updateGroup);

    const { mutateAsync: addParentMutation } = useGraphqlMutation(addParent);

    const { mutateAsync: removeParentMutation } = useGraphqlMutation(removeParent);

    const form = useForm<GroupDetailsInfoQuery["group"]>({
        initialValues: {
            ...group,
        },
        validate: {
            name: (v) => (v.length == 0 ? "Invalid name" : null),
            parents: (v) => (group.id != rootGroup.id && v.length == 0 ? "Group must have at least one parent" : null),
        },
    });

    const { getValues, reset } = form;

    function handleSubmit(data: GroupDetailsInfoQuery["group"]) {
        const promises: Promise<unknown>[] = [];
        if (form.isDirty("name") || form.isDirty("internalJoinable")) {
            promises.push(updateGroupMutation({ internalJoinable: data.internalJoinable, name: data.name, groupId: data.id }));
        }
        if (form.isDirty("parents")) {
            const newParents = getValues().parents.filter((p) => !group.parents.some((g) => g.id == p.id));
            const removedParents = group.parents.filter((p) => !getValues().parents.some((g) => g.id == p.id));
            if (newParents.length) newParents.forEach((p) => promises.push(addParentMutation({ groupId: group.id, parentId: p.id })));
            if (getValues().parents.length === 0) promises.push(Promise.reject("Group must have at least one parent"));
            else if (removedParents.length)
                removedParents.forEach((p) => promises.push(removeParentMutation({ groupId: group.id, parentId: p.id })));
        }

        Promise.all(promises)
            .then(async () => {
                await qk.invalidate("organization");
                notify.show.success({ message: "Updated group details" });
                form.setInitialValues({
                    ...getValues(),
                });
                reset();
            })
            .catch(logger.error);
    }

    const isMobile = useIsMobile();
    const [membersOverride, setMembersOverride] = useState<StagedMember[]>();

    const canViewMembership = usePermissionCheck(Permission.GroupUsersView, groupId);
    const canChangeMembership = usePermissionCheck(Permission.GroupUsersManage, groupId);

    return (
        <Paper p={!isMobile ? "1rem" : undefined} h="100%" mah="85dvh" style={{ flexGrow: 1, overflowY: "auto" }}>
            <Accordion chevronPosition="left" w="100%" multiple defaultValue={["group", "members"]}>
                <GroupDetailsForm group={group} handleSubmit={handleSubmit} form={form} />
                <MemberDetails
                    members={group.members}
                    groupId={group.id}
                    membersOverride={membersOverride}
                    setMembersOverride={setMembersOverride}
                    canViewMembership={canViewMembership}
                    canChangeMembership={canChangeMembership}
                    isCreateForm={false}
                />
            </Accordion>
        </Paper>
    );
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/_groups/groups/$groupId")({
    loader: ({ params: { groupId } }) => {
        queryClient.prefetchInfiniteQuery(usersInfiniteQuery).catch(logger.error);
        queryClient.ensureQueryData(groupInfoQueryOptions(groupId)).catch(logger.error);
        queryClient.ensureQueryData(rootGroupQueryOptions).catch(logger.error);
    },
    component: GroupDetailsContainer,
});

function groupInfoQueryOptions(groupId: string) {
    return queryOptions({ queryKey: qk("organization", "group", groupId), queryFn: () => httpPostGraphql(groupInfoQuery, { groupId }) });
}

const groupInfoQuery = graphql(`
    query GroupDetailsInfo($groupId: UUID!) {
        group(id: $groupId) {
            id
            name
            internalJoinable
            synchronized
            children {
                id
                name
                internalJoinable
                synchronized
                members {
                    id
                    firstName
                    lastName
                    euds {
                        type
                        value
                    }
                }
            }
            members {
                id
                firstName
                lastName
                euds {
                    type
                    value
                }
            }
            parents {
                name
                id
            }
        }
    }
`);

const updateGroup = graphql(`
    mutation GroupDetailsUpdate($groupId: UUID!, $internalJoinable: Boolean!, $name: String!) {
        updateGroup(id: $groupId, params: { internalJoinable: $internalJoinable, name: $name }) {
            id
        }
    }
`);

const addParent = graphql(`
    mutation GroupDetailsAddParent($groupId: UUID!, $parentId: UUID!) {
        addParentToGroup(groupId: $groupId, parentId: $parentId) {
            id
        }
    }
`);

const removeParent = graphql(`
    mutation GroupDetailsRemoveParent($groupId: UUID!, $parentId: UUID!) {
        removeParentFromGroup(groupId: $groupId, parentId: $parentId) {
            id
        }
    }
`);
