import { Accordion, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { GroupDetailsForm, MemberDetails, rootGroupQueryOptions, StagedMember, usersInfiniteQuery } from "shared/components/groups/Groups";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { useUserId } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { GroupDetailsInfoQuery } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const Page = () => {
    const { userId } = useUserId();
    const { mutate: createGroup } = useGraphqlMutation({
        document: mutations.createGroup,
        onSuccess: async () => {
            await qk.invalidate("user", "id", userId).catch(logger.error);
            await qk.invalidate("organization", "rootGroup").catch(logger.error);
            notify.show.success({ message: "Created new group" });
        },
    });

    const isMobile = useIsMobile();
    const [membersOverride, setMembersOverride] = useState<StagedMember[]>();
    const group = { id: "create", name: "", members: [], children: [], parents: [], synchronized: false, internalJoinable: false };

    const form = useForm<GroupDetailsInfoQuery["group"]>({
        initialValues: {
            ...group,
        },
        validate: {
            name: (v) => (v.length == 0 ? "Invalid name" : null),
            parents: (v) => (v.length == 0 ? "Group must have at least one parent" : null),
        },
    });

    function handleSubmit(data: GroupDetailsInfoQuery["group"]) {
        createGroup({
            name: data.name,
            parentGroupIds: data.parents.map((p) => p.id),
            internalJoinable: data.internalJoinable,
            members: membersOverride?.map((m) => m.id) ?? [],
        });
        form.reset();
    }

    return (
        <Stack p={!isMobile ? "1rem" : undefined} h="100%" mah="85dvh" style={{ flexGrow: 1, overflowY: "auto" }}>
            <Accordion chevronPosition="left" w="100%" multiple defaultValue={["group", "members"]}>
                <GroupDetailsForm group={group} handleSubmit={handleSubmit} form={form} />
                <MemberDetails
                    members={group.members}
                    groupId={group.id}
                    membersOverride={membersOverride}
                    setMembersOverride={setMembersOverride}
                    canViewMembership={true}
                    canChangeMembership={true}
                    isCreateForm={false}
                />
            </Accordion>
        </Stack>
    );
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/_groups/groups/create")({
    component: Page,
    loader: () => {
        queryClient.prefetchInfiniteQuery(usersInfiniteQuery).catch(logger.error);
        queryClient.ensureQueryData(rootGroupQueryOptions).catch(logger.error);
    },
});

const mutations = {
    createGroup: graphql(`
        mutation GroupDetailsCreateGroup($internalJoinable: Boolean!, $name: String!, $parentGroupIds: [UUID!]!, $members: [UUID!]!) {
            createGroup(params: { internalJoinable: $internalJoinable, name: $name, parentGroupIds: $parentGroupIds, members: $members }) {
                id
            }
        }
    `),
};
