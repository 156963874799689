import { createFormContext } from "@mantine/form";
import { to } from "shared/utils/fns";
import { geozod, nonemptyString, nonemptyUUID } from "shared/utils/zod";
import { Cm, MessageSeverity } from "src/gql/graphql";
import { z } from "zod";

export const composeFormStateSchema = z.object({
    audience: z.object({
        groups: z.object({ id: z.string(), name: z.string() }).array(),
        topics: z.object({ id: z.string(), name: z.string() }).array(),
        users: z.object({ id: z.string(), firstName: z.string().optional(), lastName: z.string().optional() }).array(),
        features: geozod.feature(geozod.polygon()).array(),
        geofilters: z.string().uuid().array(),
    }),
    sender: z.object({ id: nonemptyUUID() }),
    severity: z.nativeEnum(MessageSeverity),
    subject: nonemptyString(),
    body: z.string(),
    richBody: z.string(),
    files: z.object({ id: nonemptyUUID() }).array(),
    options: z.object({ method: z.nativeEnum(Cm) }).array(),
});

type ComposeFormState = z.infer<typeof composeFormStateSchema>;

export const [ComposeFormProvider, useComposeFormContext, useComposeForm] = createFormContext<ComposeFormState>();

export const composeFormStateToMessageInfoInput = composeFormStateSchema.transform((state) => ({
    ...state,
    sender: state.sender.id,
    questions: [],
    audience: {
        groups: state.audience.groups.map(to("id")),
        topics: state.audience.topics.map(to("id")),
        users: state.audience.users.map(to("id")),
        geometries: state.audience.features.map((f) => JSON.stringify(f.geometry)),
        geofilters: state.audience.geofilters,
    },
    files: state.files.map(to("id")),
}));
