import { LooseKeys, PathValue, UseFormReturnType } from "@mantine/form/lib/types";
import { useFormSubscription } from "shared/hooks/useFormSubscription";

export function useFormState<V, K extends LooseKeys<V>>(
    form: UseFormReturnType<V>,
    k: K,
): [value: PathValue<V, K>, setter: (value: PathValue<V, K>) => unknown] {
    const v = useFormSubscription(form, k);
    return [v as PathValue<V, K>, (v: PathValue<V, K>) => form.setFieldValue(k, v)];
}
