import { ActionIcon, Badge, Button, Group, Modal, Paper, rem, Select, Stack, Text, TextInput, Title } from "@mantine/core";
import { useField, useForm } from "@mantine/form";
import { IconDownload, IconPlus, IconX } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useState } from "react";
import { patternFormatter } from "react-number-format";
import { useAuth } from "react-oidc-context";
import { userQueryOptions } from "routes/_auth/route";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useUserId } from "shared/stores/oidc";
import { titlecase } from "shared/utils/fns";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { Cm, Language } from "src/gql/graphql";
import { queryClient } from "src/queryClient";
import { match } from "ts-pattern";

function isPhone(cm: Cm) {
    return [Cm.Phone, Cm.Sms].includes(cm);
}

const EUDDetails = ({
    eud,
}: {
    eud: {
        name?: string | null;
        type: Cm;
        value: string;
        id: string;
        isVerified?: boolean;
    };
}) => {
    const { userId } = useUserId();
    const { mutate: removeEUDMutation } = useGraphqlMutation({
        document: removeEUD,
        onSuccess: async () => {
            await qk.invalidate("user", "id", userId);
            notify.show.success({ message: "Removed device" });
        },
        onError: () => notify.show.error({ message: "Error removing device" }),
    });

    return (
        <Group justify="space-between">
            <Stack gap={0}>
                <Group>
                    {!!eud.name && <Text>{eud.name}</Text>}
                    {!eud.isVerified && <Badge color="red">Not Verified</Badge>}
                </Group>
                <Text>{isPhone(eud.type) ? patternFormatter(eud.value, { format: "(###)-###-####" }) : eud.value}</Text>
            </Stack>
            <ActionIcon
                variant="transparent"
                color="text"
                onClick={() => {
                    removeEUDMutation({ userId, eudId: eud.id });
                }}
            >
                <IconX size={16} />
            </ActionIcon>
        </Group>
    );
};

const EditNameForm = () => {
    const { userId } = useUserId();
    const {
        data: { firstName, lastName, language },
    } = useSuspenseQuery({
        ...userQueryOptions(userId),
        select: ({ user }) => ({ firstName: user.firstName, lastName: user.lastName, language: user.language }),
    });

    const form = useForm({ mode: "uncontrolled", initialValues: { firstName: firstName ?? "", lastName: lastName ?? "" } });
    const { mutate: updateUserName } = useGraphqlMutation({
        document: updateUser,
        onSuccess: () => notify.show.success({ message: "Updated name" }),
        onError: () => notify.show.error({ message: "Error updating name" }),
    });

    return (
        <form
            onSubmit={form.onSubmit((data) => {
                updateUserName({ userId, firstName: data.firstName, lastName: data.lastName, language });
            })}
        >
            <TextInput label="First Name" w="min-content" miw={rem(200)} key={form.key("firstName")} {...form.getInputProps("firstName")} />
            <TextInput label="Last Name" w="min-content" miw={rem(200)} key={form.key("lastName")} {...form.getInputProps("lastName")} />
            <Button leftSection={<IconDownload size={16} />} mt="xs" type="submit" disabled={!form.isDirty()}>
                Save
            </Button>
        </form>
    );
};

const CommunicationMethodsForm = () => {
    const { userId } = useUserId();
    const { data: euds } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.euds });

    const emailEUDs = euds.filter((eud) => eud.type == Cm.Email);
    const phoneEUDs = euds.filter((eud) => isPhone(eud.type));
    const verifyField = useField({ initialValue: "", validate: (v) => (v.trim().length < 1 ? "Enter a code" : null) });

    const [verifyValue, setVerifyValue] = useState<{ value: string; id: string }>();
    const [currentModal, setCurrentModal] = useState<"sms" | "voice" | "email" | null>();

    const cmLabel = match(currentModal)
        .with("email", () => "Email")
        .with("sms", () => "SMS Phone Number")
        .with("voice", () => "Voice Phone Number")
        .otherwise(() => "Error");

    const form = useForm({
        initialValues: { value: "", name: "" },
        validate: { value: (value) => (value.trim().length < 1 ? `Enter a Valid ${cmLabel}` : null) },
    });

    const { mutate: addEUDMutation } = useGraphqlMutation({
        document: addEUD,
        onSuccess: (data) => {
            setVerifyValue(data?.addEUD);
        },
    });

    const { mutate: resendEUDMutation } = useGraphqlMutation({
        document: resendEUD,
        onSuccess: () => notify.show.success({ message: "Resent verification code" }),
    });

    const { mutate: verifyEUDMutation } = useGraphqlMutation({
        document: verifyEUD,
        onSuccess: async () => {
            setCurrentModal(undefined);
            await qk.invalidate("user", "id", userId);
            notify.show.success({ message: "Device verified" });
        },
    });

    return (
        <>
            <Modal
                opened={!!currentModal}
                onClose={() => {
                    setCurrentModal(undefined);
                    form.reset();
                }}
                title={
                    <Text fz="h4" fw="600">
                        Add {cmLabel}
                    </Text>
                }
                centered
            >
                <Stack>
                    <form
                        onSubmit={form.onSubmit((data) => {
                            addEUDMutation({
                                userId,
                                params: {
                                    name: data.name == "" ? undefined : data.name,
                                    value: data.value,
                                    type: match(currentModal)
                                        .with("email", () => Cm.Email)
                                        .with("sms", () => Cm.Sms)
                                        .with("voice", () => Cm.Phone)
                                        .otherwise(() => Cm.Email),
                                },
                            });
                        })}
                    >
                        <Stack>
                            <TextInput label="Name" placeholder="Enter name..." key={form.key("name")} {...form.getInputProps("name")} />
                            <TextInput label={cmLabel} withAsterisk key={form.key("value")} {...form.getInputProps("value")} />
                            <Button w="100%" mt="md" type="submit">
                                Send verification code
                            </Button>
                        </Stack>
                    </form>
                    {!!verifyValue && (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                verifyField
                                    .validate()
                                    .then(() => {
                                        verifyEUDMutation({ userId, eudId: verifyValue.id, code: verifyField.getValue() });
                                    })
                                    .catch(logger.error);
                            }}
                        >
                            <Stack>
                                <Text>
                                    We sent a code to <em>{verifyValue.value}</em>. This code is only active for the next X minutes.
                                </Text>
                                <TextInput label="Enter Code" withAsterisk placeholder="Enter code..." {...verifyField.getInputProps()} />
                                <Button
                                    variant="default"
                                    w="100%"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        resendEUDMutation({ userId, eudID: verifyValue.id });
                                    }}
                                >
                                    Resend Code
                                </Button>
                                <Button w="100%" type="submit">
                                    Confirm
                                </Button>
                            </Stack>
                        </form>
                    )}
                </Stack>
            </Modal>
            <Title order={4}>Communication Methods</Title>
            <Title order={5}>Email</Title>
            {emailEUDs.map((eud) => (
                <EUDDetails key={`${eud.name}${eud.value}`} eud={eud} />
            ))}
            {emailEUDs.length < 3 && (
                <Button leftSection={<IconPlus size={16} />} variant="default" w="min-content" onClick={() => setCurrentModal("email")}>
                    Add Email
                </Button>
            )}
            <Title order={5}>Phone Numbers</Title>
            {phoneEUDs.map((eud) => (
                <EUDDetails eud={eud} key={`${eud.name}${eud.value}`} />
            ))}
            {phoneEUDs.length < 3 && (
                <>
                    <Button leftSection={<IconPlus size={16} />} variant="default" w="min-content" onClick={() => setCurrentModal("sms")}>
                        Add Text/SMS Phone Number
                    </Button>
                    <Button leftSection={<IconPlus size={16} />} variant="default" w="min-content" onClick={() => setCurrentModal("voice")}>
                        Add Voice Phone Number
                    </Button>
                    <Button disabled leftSection={<IconPlus size={16} />} variant="default" w="min-content">
                        Add TTY/TDD
                    </Button>
                </>
            )}
        </>
    );
};

const LanguageContainer = () => {
    const {
        data: {
            customer: { languages },
        },
    } = useSuspenseQuery(languageQueryOptions);

    const { userId } = useUserId();
    const {
        data: { firstName, lastName, language },
    } = useSuspenseQuery({
        ...userQueryOptions(userId),
        select: ({ user }) => ({ firstName: user.firstName, lastName: user.lastName, language: user.language }),
    });

    const { mutate: updateLanguage } = useGraphqlMutation({
        document: updateUser,
        onSuccess: () => notify.show.success({ message: "Updated language" }),
        onError: () => notify.show.error({ message: "Error updating language" }),
    });

    return (
        <>
            <Title order={4}>Preferences</Title>
            <Select
                allowDeselect={false}
                label="Language preference"
                defaultValue={language}
                data={languages.map((l) => ({ label: titlecase(l as string), value: l }))}
                w="min-content"
                miw={rem(300)}
                onChange={(_, opt) => {
                    updateLanguage({
                        userId,
                        firstName: firstName ?? "",
                        lastName: lastName ?? "",
                        language: opt.value as Language,
                    });
                }}
            />
        </>
    );
};

const ProfileContainer = () => {
    const auth = useAuth();

    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={3}>Profile Information</Title>
            </Content.Heading>
            <Title order={2} fw="normal" component="h3">
                Profile information
            </Title>
            <Title order={4}>Registered Email</Title>
            <Text>{auth.user?.profile.preferred_username}</Text>
            <a href={`${auth.settings.authority}/account`} target="_blank" style={{ width: "fit-content" }}>
                <Button variant="default">Edit Sign-in Information</Button>
            </a>
            <Link to="/logout">
                <Button color="red">Log out</Button>
            </Link>
            <EditNameForm />
            <CommunicationMethodsForm />
            <Paper w="100%" p="xl" style={{ display: "flex", justifyContent: "center" }} shadow="xl">
                <Title order={3}>Link App</Title>
            </Paper>
            <LanguageContainer />
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/settings/profile")({
    component: ProfileContainer,
    loader: () => {
        queryClient.ensureQueryData(languageQueryOptions).catch(logger.error);
    },
});

const addEUD = graphql(`
    mutation ProfileAddEud($userId: UUID!, $params: EndUserDeviceParameterInput!) {
        addEUD(userId: $userId, eudParams: $params) {
            id
            value
        }
    }
`);

const resendEUD = graphql(`
    mutation ProfileResendEUD($userId: UUID!, $eudID: UUID!) {
        resendEUDVerificationCode(userId: $userId, eudId: $eudID) {
            id
        }
    }
`);

const languageQueryOptions = queryOptions({ queryKey: qk("customer", "languages"), queryFn: () => httpPostGraphql(languageQuery, {}) });

const languageQuery = graphql(`
    query ProfileGetLanguages {
        customer {
            languages
        }
    }
`);

const removeEUD = graphql(`
    mutation ProfileRemoveEUD($userId: UUID!, $eudId: UUID!) {
        removeEUD(userId: $userId, eudId: $eudId)
    }
`);

const verifyEUD = graphql(`
    mutation ProfileVerifyEUD($userId: UUID!, $eudId: UUID!, $code: String!) {
        verifyEUD(userId: $userId, eudId: $eudId, verificationCode: $code) {
            id
        }
    }
`);

const updateUser = graphql(`
    mutation ProfileUpdateUser($userId: UUID!, $firstName: String!, $lastName: String!, $language: Language!) {
        updateUser(userId: $userId, params: { firstName: $firstName, lastName: $lastName, language: $language }) {
            id
        }
    }
`);
