import { Tooltip, UnstyledButton } from "@mantine/core";
import type { IconMessage } from "@tabler/icons-react";
import classes from "shared/components/global/Sidebar/SidebarItem.module.css";
import { useColorMode } from "shared/hooks/useColorMode";

export type SidebarItemProps = {
    icon: typeof IconMessage;
    label?: string;
    isActive?: boolean;
    onClick?(): void;
};

export const SidebarItem = ({ icon: Icon, label, isActive, onClick }: SidebarItemProps) => {
    const { isDark } = useColorMode();

    return (
        <Tooltip label={label} position="right" transitionProps={{ duration: 0 }} color={isDark ? "gray" : undefined}>
            <UnstyledButton onClick={onClick} className={classes.item} data-active={isActive || undefined} size="xs" mx={"auto"} mt={"xs"}>
                <Icon size={20} stroke={1.5} />
            </UnstyledButton>
        </Tooltip>
    );
};
