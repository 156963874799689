import { Button, Divider, Group, Input, NumberInput, Paper, Slider, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconBomb, IconCheck, IconClockShare, IconMail, IconRefresh } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useLoaderData } from "@tanstack/react-router";
// @ts-expect-error ／人◕ __ ◕人＼
import hipsum from "lorem-hipsum";
import { useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useUserId } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { getRelativeTimeString } from "shared/utils/temporal";
import { graphql } from "src/gql";
import { queryClient } from "src/queryClient";

function Component() {
    const [count, setCount] = useState(5);
    const [sendMsgId, setSendMsgId] = useState("");
    const [scheduleMsgId, setScheduleMsgId] = useState("");
    const [schedulePlusMin, setSchedulePlusMin] = useState(5);
    const { userId } = useUserId();
    const { group, sender } = useLoaderData({ from: "/_auth/_dev/devtools/message-o-matic" });
    const {
        data: { drafts },
    } = useSuspenseQuery(draftsQuery);
    const { mutateAsync: createMessageAsync } = useGraphqlMutation(
        graphql(`
            mutation SandboxCreateDraft($sub: String!, $body: String!, $group: UUID!, $sender: UUID!) {
                createMessage(
                    params: {
                        body: $body
                        richBody: $body
                        audience: { groups: [$group], topics: [], users: [], geometries: [], geofilters: [] }
                        options: [{ method: SMS }, { method: ANDROID }, { method: PHONE }, { method: EMAIL }]
                        questions: []
                        severity: INFORMATIONAL
                        subject: $sub
                        sender: $sender
                        files: []
                    }
                ) {
                    id
                }
            }
        `),
    );

    const { mutateAsync: sendMessageAsync } = useGraphqlMutation({
        document: graphql(`
            mutation SandboxSendMessage($id: UUID!) {
                sendMessage(id: $id) {
                    id
                    subject
                }
            }
        `),
        onSuccess: async (data) => {
            await qk.invalidate("sandbox", "drafts");
            notify.show.success({ message: data?.sendMessage.subject });
        },
    });

    const { mutateAsync: scheduleMessageAsync } = useGraphqlMutation(
        graphql(`
            mutation SandboxScheduleMessage($id: UUID!, $sendAt: Instant!) {
                scheduleMessage(id: $id, sendAt: $sendAt) {
                    id
                }
            }
        `),
    );

    const { mutateAsync: deleteDraftAsync } = useGraphqlMutation({
        document: graphql(`
            mutation SandboxMurkDraft($id: UUID!) {
                discardMessageDraft(id: $id) {
                    id
                }
            }
        `),
    });

    const { mutateAsync: addPhoneNumber } = useGraphqlMutation(
        graphql(`
            mutation SandboxAddEUD($userId: UUID!, $name: String!, $phone: String!) {
                addEUD(userId: $userId, eudParams: { name: $name, type: SMS, value: $phone }) {
                    id
                }
            }
        `),
    );

    async function deleteAllDrafts() {
        const len = drafts.length;
        const toastId = notify.show.info({ message: `Cooking... (0/${len})`, loading: true, autoClose: 10_000_000 });

        for (let i = 0; i < drafts.length; i++) {
            await deleteDraftAsync({ id: drafts[i].id });
            notify.update({ id: toastId, message: `Cooking... (${i}/${len})`, loading: true });
        }

        notify.update({ id: toastId, message: `Done!`, loading: false, icon: <IconCheck />, color: "green", autoClose: 5000 });

        await queryClient.invalidateQueries({ exact: true, queryKey: draftsQuery.queryKey });
    }

    async function makeMessages() {
        notify.show.info({ message: "Proccessing..." });

        for (let i = 0; i < count; i++) {
            const sub = hipsum({ count: 2, units: "words" });
            const body: string = hipsum({
                count: 1,
                units: "paragraphs",
                sentenceLowerBound: 5,
                sentenceUpperBound: 15,
                paragraphLowerBound: 3,
                paragraphUpperBound: 7,
                format: "plain",
            });

            await createMessageAsync({ body, group, sender, sub });
        }

        notify.show.success({
            message: `Created ${count} message${count < 2 ? "" : "s"}`,
        });

        await queryClient.invalidateQueries({ exact: true, queryKey: draftsQuery.queryKey });
    }

    async function handleSchedule() {
        const d = new Date();
        d.setMinutes(d.getMinutes() + schedulePlusMin);

        await scheduleMessageAsync({
            id: scheduleMsgId,
            sendAt: (+d).toString(),
        }).then(() => notify.show.success({ message: "Message scheduled for delivery " + getRelativeTimeString(d) }));
    }

    const eudForm = useForm({
        mode: "uncontrolled",
        initialValues: {
            name: "",
            phone: "",
        },
    });

    return (
        <Stack>
            <Title>Message-o-Matic 9000™</Title>

            <Button
                onClick={() =>
                    modals.open({
                        title: "heyy can i get ur number?",
                        children: (
                            <Stack>
                                <TextInput label="whats ur name son?" key={eudForm.key("name")} {...eudForm.getInputProps("name")} />
                                <TextInput
                                    label="make sure to include the +1. no spaces or formatting or anything :)"
                                    key={eudForm.key("phone")}
                                    {...eudForm.getInputProps("phone")}
                                />
                                <Button
                                    onClick={() =>
                                        void addPhoneNumber({
                                            ...eudForm.getValues(),
                                            userId,
                                        })
                                            .then(() => modals.closeAll())
                                            .then(() => notify.show.success({ message: "Done!" }))
                                            .catch(logger.error)
                                    }
                                >
                                    yuh
                                </Button>
                            </Stack>
                        ),
                    })
                }
            >
                Add Phone Number
            </Button>

            <Slider value={count} onChange={(e) => setCount(e)} />
            <Group>
                <Button onClick={() => void makeMessages()}>Make {count} Message</Button>
            </Group>

            <Divider />
            <Group align="flex-start">
                <Group flex={1}>
                    <Stack>
                        <Group>
                            <Button
                                w={"fit-content"}
                                leftSection={<IconRefresh />}
                                onClick={() => void queryClient.invalidateQueries({ exact: true, queryKey: draftsQuery.queryKey })}
                            >
                                Reload
                            </Button>
                            <Button
                                variant="default"
                                c={"red"}
                                w={"fit-content"}
                                leftSection={<IconBomb />}
                                onClick={() => void deleteAllDrafts()}
                            >
                                Murk All Drafts
                            </Button>
                        </Group>
                        <Title order={4}>Drafts</Title>
                        <Paper p="md">
                            <Stack gap={"0px"}>
                                {drafts.map((each) => (
                                    <Group key={each.id} wrap="nowrap">
                                        <pre>{each.id}</pre>
                                        <Button onClick={() => void sendMessageAsync({ id: each.id })}>Send</Button>
                                        <Button
                                            onClick={() => {
                                                void deleteDraftAsync({ id: each.id })
                                                    .then(() => qk.invalidate("sandbox", "drafts"))
                                                    .then(() => notify.show.success({ message: "Deleted" }));
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Group>
                                ))}
                            </Stack>
                        </Paper>
                    </Stack>
                </Group>
                <Group flex={1}>
                    <Stack>
                        <Group>
                            <Input placeholder="Draft UUID" value={sendMsgId} onChange={(e) => setSendMsgId(e.target.value)} />
                            <Button leftSection={<IconMail />} onClick={() => void sendMessageAsync({ id: sendMsgId })}>
                                Send
                            </Button>
                        </Group>
                    </Stack>

                    <Stack>
                        <Group>
                            <Input value={scheduleMsgId} placeholder="Draft UUID" onChange={(e) => setScheduleMsgId(e.target.value)} />
                            <NumberInput
                                value={schedulePlusMin}
                                onChange={(v) => setSchedulePlusMin(+v || 1)}
                                placeholder="+min"
                                min={1}
                                suffix={" Minute" + (schedulePlusMin > 1 ? "s" : "")}
                            />
                            <Button leftSection={<IconClockShare />} onClick={() => void handleSchedule()}>
                                Schedule
                            </Button>
                        </Group>
                    </Stack>
                </Group>
            </Group>
        </Stack>
    );
}

const draftsQuery = queryOptions({
    queryKey: qk("sandbox", "drafts"),
    queryFn: () =>
        httpPostGraphql(
            graphql(`
                query SandboxDrafts {
                    drafts(limit: 9999, offset: 0) {
                        id
                    }
                }
            `),
            {},
        ),
});

/** @public */
export const Route = createFileRoute("/_auth/_dev/devtools/message-o-matic")({
    component: Component,
    loader: async ({ abortController: { signal } }) => {
        const {
            senders: [{ id: sender }],
        } = await httpPostGraphql(
            graphql(`
                query SandboxSenders {
                    senders {
                        id
                    }
                }
            `),
            {},
            { signal },
        );

        const {
            rootGroup: { id: group },
        } = await httpPostGraphql(
            graphql(`
                query SandboxGroups {
                    rootGroup {
                        id
                    }
                }
            `),
            {},
            { signal },
        );

        await queryClient.ensureQueryData(draftsQuery);

        return {
            sender,
            group,
        };
    },
});
