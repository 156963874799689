import { ActionIcon, Badge, Button, Divider, Group, Modal, Stack, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconDownload, IconEdit, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { userQueryOptions } from "routes/_auth/route";
import { Content } from "shared/components/global/Content";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useUserId } from "shared/stores/oidc";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";

const AddressDetails = ({ setEditingId }: { setEditingId: (id: string) => void }) => {
    const { userId } = useUserId();
    const { data: addresses } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.addresses });

    return addresses.map((a) => {
        return (
            <Stack key={a.id}>
                <Group justify="space-between">
                    <div>
                        <Group gap={0}>
                            <Title order={5}>{a.name}</Title>

                            <ActionIcon variant="transparent" color="var(--mantine-color-text)" onClick={() => setEditingId(a.id)}>
                                <IconEdit size={16} />
                            </ActionIcon>
                        </Group>
                        <Text size="lg">{`${a.street}, ${a.city}, ${a.state} ${a.zip}`}</Text>
                    </div>
                </Group>
                <Divider />
            </Stack>
        );
    });
};

const EditLocationForm = ({ onSuccess, addressId }: { addressId: string; onSuccess: () => void }) => {
    const { userId } = useUserId();
    const { data: addresses } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.addresses });

    const location = addresses.find((a) => a.id == addressId)!;
    const form = useForm({
        mode: "uncontrolled",
        initialValues: {
            name: location.name,
            street: location.street,
            street2: location.street2,
            city: location.city,
            state: location.state,
            zip: location.zip,
        },
        validate: {
            name: (n) => (n.length < 1 ? "Invalid name" : null),
            street: (s) => (s.length < 1 ? "Invalid street" : null),
            city: (c) => (c.length < 1 ? "Invalid city" : null),
            state: (s) => (s.length < 1 ? "Invalid state" : null),
            zip: (z) => (z.length < 5 ? "Invalid zip code" : null),
        },
    });

    const { mutate: editUserAddress } = useGraphqlMutation({
        document: editAddress,
        onSuccess: async () => {
            await qk.invalidate("user", "id", userId);
            notify.show.success({ message: "Edited location" });
            onSuccess();
        },
        onError: () => notify.show.error({ message: "Error adding location" }),
    });

    const { mutate: removeUserAddress } = useGraphqlMutation({
        document: removeAddress,
        onSuccess: async () => {
            await qk.invalidate("user", "id", userId);
            notify.show.success({ message: "Removed location" });
            onSuccess();
        },
    });

    return (
        <form
            onSubmit={form.onSubmit((data, e) => {
                e?.stopPropagation();
                editUserAddress({
                    userId,
                    addressId,
                    address: {
                        name: data.name,
                        street: data.street,
                        street2: data.street2,
                        city: data.city,
                        state: data.state,
                        zip: data.zip,
                    },
                });
            })}
        >
            <Stack>
                <TextInput label="Name" placeholder="Enter name..." withAsterisk key={form.key("name")} {...form.getInputProps("name")} />
                <TextInput
                    label="Address"
                    placeholder="Enter address..."
                    withAsterisk
                    key={form.key("street")}
                    {...form.getInputProps("street")}
                />
                <TextInput
                    label="Address Line 2"
                    placeholder="Enter address..."
                    key={form.key("street2")}
                    {...form.getInputProps("street2")}
                />
                <Group wrap="nowrap">
                    <TextInput
                        label="City"
                        placeholder="Enter city..."
                        withAsterisk
                        key={form.key("city")}
                        {...form.getInputProps("city")}
                    />
                    <TextInput
                        label="State"
                        placeholder="Enter state..."
                        withAsterisk
                        key={form.key("state")}
                        {...form.getInputProps("state")}
                    />
                    <TextInput label="Zip" placeholder="Enter zip..." withAsterisk key={form.key("zip")} {...form.getInputProps("zip")} />
                </Group>
            </Stack>
            <Button
                fullWidth
                mt="md"
                leftSection={<IconTrash size={16} />}
                color="red"
                onClick={() => removeUserAddress({ userId: userId, addressId: addressId })}
            >
                Remove
            </Button>
            <Button fullWidth mt="md" leftSection={<IconPlus size={16} />} type="submit" color="green" disabled={!form.isDirty()}>
                Update
            </Button>
        </form>
    );
};

const AddLocationForm = ({ onSuccess }: { onSuccess: () => void }) => {
    const { userId } = useUserId();

    const form = useForm({
        mode: "uncontrolled",
        initialValues: { name: "", street: "", street2: "", city: "", state: "", zip: "" },
        validate: {
            name: (n) => (n.length < 1 ? "Invalid name" : null),
            street: (s) => (s.length < 1 ? "Invalid street" : null),
            city: (c) => (c.length < 1 ? "Invalid city" : null),
            state: (s) => (s.length < 1 ? "Invalid state" : null),
            zip: (z) => (z.length < 5 ? "Invalid zip code" : null),
        },
    });

    const { mutate: addUserAddress } = useGraphqlMutation({
        document: addAddress,
        onSuccess: async () => {
            await qk.invalidate("user", "id", userId);
            notify.show.success({ message: "Added new address" });
            onSuccess();
        },
        onError: () => notify.show.error({ message: "Error adding address" }),
    });

    return (
        <form
            onSubmit={form.onSubmit((data, e) => {
                e?.stopPropagation();
                addUserAddress({
                    userId,
                    address: {
                        name: data.name,
                        street: data.street,
                        street2: data.street2,
                        city: data.city,
                        state: data.state,
                        zip: data.zip,
                    },
                });
            })}
        >
            <Stack>
                <TextInput label="Name" placeholder="Enter name..." withAsterisk key={form.key("name")} {...form.getInputProps("name")} />
                <TextInput
                    label="Address"
                    placeholder="Enter address..."
                    withAsterisk
                    key={form.key("street")}
                    {...form.getInputProps("street")}
                />
                <TextInput
                    label="Address Line 2"
                    placeholder="Enter address..."
                    key={form.key("street2")}
                    {...form.getInputProps("street2")}
                />
                <Group wrap="nowrap">
                    <TextInput
                        label="City"
                        placeholder="Enter city..."
                        withAsterisk
                        key={form.key("city")}
                        {...form.getInputProps("city")}
                    />
                    <TextInput
                        label="State"
                        placeholder="Enter state..."
                        withAsterisk
                        key={form.key("state")}
                        {...form.getInputProps("state")}
                    />
                    <TextInput label="Zip" placeholder="Enter zip..." withAsterisk key={form.key("zip")} {...form.getInputProps("zip")} />
                </Group>
            </Stack>
            <Group justify="flex-end">
                <Button fullWidth mt="md" leftSection={<IconDownload size={16} />} type="submit">
                    Add
                </Button>
            </Group>
        </form>
    );
};

const LocationsContainer = () => {
    const [addModalOpened, { open: openAddModal, close: closeAddModal }] = useDisclosure();
    const [editModalOpened, { open: openEditModal, close: closeEditModal }] = useDisclosure();
    const [editingId, setEditingId] = useState<string>();
    const { userId } = useUserId();

    const { data: addresses } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.addresses });

    useEffect(() => {
        if (!!editingId) openEditModal();
        else if (editModalOpened) closeEditModal();
    }, [editingId]);

    const AddModal = () => (
        <Modal
            centered
            opened={addModalOpened}
            onClose={() => {
                closeAddModal();
            }}
            withCloseButton={false}
        >
            <Modal.Header p={0}>
                <Group justify="space-between" w="100%">
                    <Text fw={600} fz="h3">
                        Add Address
                    </Text>
                    <ActionIcon variant="transparent" color="var(--mantine-color-text)" onClick={closeAddModal} autoFocus={false}>
                        <IconX size={16} />
                    </ActionIcon>
                </Group>
            </Modal.Header>
            <AddLocationForm
                onSuccess={() => {
                    setEditingId(undefined);
                    closeAddModal();
                }}
            />
        </Modal>
    );

    const EditModal = ({ addressId }: { addressId?: string }) => (
        <Modal
            centered
            opened={editModalOpened}
            onClose={() => {
                closeEditModal();
                setEditingId(undefined);
            }}
            withCloseButton={false}
        >
            <Modal.Header p={0}>
                <Group justify="space-between" w="100%">
                    <Text fw={600} fz="h3">
                        Edit Address
                    </Text>
                    <ActionIcon variant="transparent" color="var(--mantine-color-text)" onClick={closeEditModal} autoFocus={false}>
                        <IconX size={16} />
                    </ActionIcon>
                </Group>
            </Modal.Header>
            {addressId && (
                <EditLocationForm
                    onSuccess={() => {
                        setEditingId(undefined);
                        closeAddModal();
                    }}
                    addressId={addressId}
                />
            )}
        </Modal>
    );

    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={3}>Locations</Title>
                <Badge variant="light" radius="sm">
                    Max 5
                </Badge>
            </Content.Heading>

            <AddressDetails setEditingId={setEditingId} />
            <Button w="min-content" disabled={addresses.length > 4} onClick={openAddModal}>
                Add Address
            </Button>
            <AddModal />
            <EditModal addressId={editingId} />
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/settings/locations")({
    component: LocationsContainer,
});

const addAddress = graphql(`
    mutation ProfileAddUserAddress($userId: UUID!, $address: UserAddressInput!) {
        addUserAddress(userId: $userId, address: $address) {
            id
        }
    }
`);

const editAddress = graphql(`
    mutation ProfileEditUserAddress($userId: UUID!, $addressId: UUID!, $address: UserAddressInput!) {
        updateUserAddress(userId: $userId, addressId: $addressId, address: $address) {
            id
        }
    }
`);

const removeAddress = graphql(`
    mutation ProfileRemoveUserAddress($userId: UUID!, $addressId: UUID!) {
        removeUserAddress(userId: $userId, addressId: $addressId)
    }
`);
