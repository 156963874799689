import { UnstyledButton } from "@mantine/core";
import { IconHome } from "@tabler/icons-react";
import { Link, ParseRoute } from "@tanstack/react-router";
import { routeTree } from "routeTree.gen";
import { sidebarLinkData } from "shared/components/global/Sidebar/sidebar.config";
import { SidebarItem, SidebarItemProps } from "shared/components/global/Sidebar/SidebarItem";
import classes from "shared/components/global/Sidebar/SidebarItem.module.css";
import { Permission } from "src/gql/graphql";

type SidebarLinkProps = {
    to: string;
} & SidebarItemProps;

export type SidebarLinkData = {
    icon: typeof IconHome;
    label: string;
    to: ParseRoute<typeof routeTree>["fullPath"];
    index: number;
    permissions?: {
        value: Permission;
        id?: "string";
    }[];
    subLinks?: { header: string; data: Omit<SidebarLinkData, "icon" | "subLinks" | "index">[] };
    position?: "top" | "bottom";
    hideFromMobile?: boolean;
};

export function hasSubNav(navIndex: number) {
    return (sidebarLinkData.find((link) => link.index == navIndex)?.subLinks?.data.length ?? 0) != 0;
}

export const SidebarLink = ({ icon, label, to, onClick }: SidebarLinkProps) => {
    return (
        <Link to={to} onClick={onClick}>
            {({ isActive }) => {
                return <SidebarItem isActive={isActive} icon={icon} label={label} />;
            }}
        </Link>
    );
};

type SidebarSubLinkProps = Omit<SidebarLinkProps, "icon">;

export const SidebarSubLink = ({ label, to, onClick }: SidebarSubLinkProps) => (
    <Link to={to} onClick={onClick}>
        {({ isActive }) => {
            return (
                <UnstyledButton
                    size="sm"
                    w="80%"
                    ml="sm"
                    pl="md"
                    style={{ justifyContent: "flex-start" }}
                    onClick={onClick}
                    className={classes.item}
                    data-active={isActive || undefined}
                >
                    {label}
                </UnstyledButton>
            );
        }}
    </Link>
);
