import { MantinePrimaryShade, useMantineTheme } from "@mantine/core";
import { alpha } from "@mantine/core";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Layer, LngLatBounds, MapLayerMouseEvent, MapRef, Source, useMap } from "react-map-gl/maplibre";
import { httpPostGraphql } from "shared/api/httpClient";
import { useColorMode } from "shared/hooks/useColorMode";
import { createDebounce } from "shared/utils/fns";
import { maybe } from "shared/utils/maybe";
import { qk } from "shared/utils/qk";
import { geozod } from "shared/utils/zod";
import { graphql } from "src/gql";

function toGeoJsonPolygon(llb: LngLatBounds): GeoJSON.Polygon {
    return {
        type: "Polygon",
        coordinates: [
            [
                llb.getNorthWest().toArray(),
                llb.getNorthEast().toArray(),
                llb.getSouthEast().toArray(),
                llb.getSouthWest().toArray(),
                llb.getNorthWest().toArray(),
            ],
        ],
    };
}

/**
 * The interactive layer id which should be registered to the associated MapBase
 */
const layerId = "geofilter-interactive-layer";

type MapGeoFilterOverlayProps = {
    geofilterIds: string[];
    onChange: (ids: string[]) => unknown;
};

export const MapGeoFilterOverlay = Object.assign(
    (props: MapGeoFilterOverlayProps) => {
        const map = useMap();
        const theme = useMantineTheme();
        const { isDark } = useColorMode();
        const { data } = useQuery(query(map.current));

        function isSelected(f: GeoJSON.Feature) {
            return (
                maybe(f.properties?.["id"] as string | null)
                    ?.map((id) => props.geofilterIds.includes(id))
                    .take() ?? false
            );
        }

        useEffect(() => {
            const { debouncing } = createDebounce();

            function handleMapClick(event: MapLayerMouseEvent) {
                const id: string | undefined = event.features?.at(0)?.properties?.["id"];
                if (!id) return;

                if (props.geofilterIds.includes(id)) props.onChange(props.geofilterIds.filter((it) => it != id));
                else props.onChange(props.geofilterIds.concat([id]));
            }

            function handleMapMove() {
                debouncing(() => {
                    void qk.invalidate("map", "customer", "geofiltersWithin");
                });
            }

            map.current?.on("move", handleMapMove);
            map.current?.on("click", layerId, handleMapClick);

            return () => {
                map.current?.off("move", handleMapMove);
                map.current?.off("click", layerId, handleMapClick);
            };
        }, [map.current, props.geofilterIds, props.onChange]);

        if (!data) return null;
        const selectedFeatures = data.filter(isSelected);

        return (
            <>
                <Source id="geofilter-interactive-source" type="geojson" data={{ type: "FeatureCollection", features: data }}>
                    <Layer id={layerId} type="fill" paint={{ "fill-color": "rgba(250, 229, 229, 0)" }} />
                    <Layer id={"geofilter-interactive-layer-outline"} type="line" paint={{ "line-color": "#878787", "line-width": 2 }} />
                </Source>
                <Source id="geofilter-selected-source" type="geojson" data={{ type: "FeatureCollection", features: selectedFeatures }}>
                    <Layer
                        id={"geofilter-selected-layer"}
                        type="fill"
                        paint={{
                            "fill-color": alpha(
                                theme.colors[theme.primaryColor][(theme.primaryShade as MantinePrimaryShade)[isDark ? "dark" : "light"]],
                                0.25,
                            ),
                        }}
                    />
                </Source>
            </>
        );
    },
    { layerId },
);

const query = (map?: MapRef) =>
    queryOptions({
        enabled: !!map,
        queryKey: qk("map", "customer", "geofiltersWithin", map?.getBounds().toString() ?? "unset"),
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query MapGeoFilterOverlay($bounds: GeoPolygon!) {
                        geoFiltersWithin(within: $bounds) {
                            id
                            boundary
                        }
                    }
                `),
                {
                    bounds: JSON.stringify(maybe(map?.getBounds())?.take(toGeoJsonPolygon)),
                },
            ),
        select: ({ geoFiltersWithin }) => {
            return geoFiltersWithin.map((g) =>
                geozod
                    .feature(geozod.polygon())
                    .parse({ type: "Feature", id: g.id, geometry: JSON.parse(g.boundary), properties: { id: g.id } }),
            );
        },
    });
