import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { httpPostGraphql } from "shared/api/httpClient";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";

export function useRootTopicQuery() {
    const {
        data: { rootTopic },
        ...rest
    } = useSuspenseQuery(query);
    return { rootTopic, ...rest };
}

export const query = queryOptions({
    queryKey: qk("organization", "rootTopic"),
    queryFn: () => httpPostGraphql(gqlQuery, {}),
});

/** @private */
const gqlQuery = graphql(`
    query RootTopic {
        rootTopic {
            id
            name
            isCategory
            isRequired
            members {
                id
            }
            children {
                id
                name
                isCategory
                isRequired
                members {
                    id
                }
                children {
                    id
                    name
                    isCategory
                    isRequired
                    members {
                        id
                    }
                    children {
                        id
                        name
                        isCategory
                        isRequired
                        members {
                            id
                        }
                        children {
                            id
                            name
                            isCategory
                            isRequired
                            members {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`);
