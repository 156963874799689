import {
    ActionIcon,
    Badge,
    Button,
    Divider,
    Group,
    Menu,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Text,
    TextInput,
    Title,
    useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowRight, IconChevronRight, IconInfoCircle, IconPlus } from "@tabler/icons-react";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { useMemo, useState } from "react";
import { Content } from "shared/components/global/Content";
import { query as topicQuery, useRootTopicQuery } from "shared/components/topic/useRootTopicQuery";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { usePermissionCheck } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { searchTree } from "shared/utils/tree";
import { graphql } from "src/gql";
import { Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

type TopicInfo = {
    id: string;
    name: string;
    description?: string;
    isCategory: boolean;
    isRequired: boolean;
    children?: TopicInfo[];
    parent?: { id: string; name: string };
    members: { id: string }[];
};

const CategoryCreateModal = ({ parentCategory }: { parentCategory: TopicInfo }) => {
    const { rootTopic } = useRootTopicQuery();

    const { mutate } = useGraphqlMutation({
        document: createCategory,
        onSuccess: async () => {
            await qk.invalidate("organization", "rootTopic");
            notify.show.success({ message: "Category created successfully" });
        },
    });

    const form = useForm({
        initialValues: {
            title: "",
            parent: parentCategory.id,
        },
        validate: {
            title: (v) => (v.length < 1 ? "Enter a title" : null),
        },
    });

    const categories = useMemo(() => searchTree(rootTopic, (it) => it.isCategory), [rootTopic]);

    return (
        <form onSubmit={form.onSubmit((data) => mutate({ ...data }))}>
            <Stack>
                <TextInput {...form.getInputProps("title")} label="Title" withAsterisk />
                <Select
                    {...form.getInputProps("parent")}
                    label="Parent Category"
                    withAsterisk
                    w="100%"
                    placeholder="Select a category..."
                    data={categories.map((c) => ({ value: c.id, label: c.name }))}
                />
                <Divider />
                <Button type="submit" w="100%">
                    Create
                </Button>
            </Stack>
        </form>
    );
};

const TopicCreationModal = ({ onSubmit }: { onSubmit: (required: boolean) => void }) => {
    const [radioValue, setRadioValue] = useState("standard");
    return (
        <Stack>
            <Group>
                <IconInfoCircle />
                <Text size="lg">Topic Type cannot be changed</Text>
            </Group>
            <Text>Once you create this topic, the selected topic type will be permanent and cannot be edited later.</Text>
            <Divider />
            <RadioGroup value={radioValue} onChange={setRadioValue}>
                <Stack>
                    <Radio value="standard" label="Standard" />
                    <Radio value="required" label="Required" />
                </Stack>
            </RadioGroup>
            <Button w="100%" rightSection={<IconArrowRight />} onClick={() => onSubmit(radioValue == "required")}>
                Continue
            </Button>
        </Stack>
    );
};

const TopicDetails = ({ category, handleTopicCreate }: { category: TopicInfo; handleTopicCreate: (categoryId: string) => void }) => {
    const theme = useMantineTheme();
    const [opened, { toggle }] = useDisclosure(true);

    const hasEditPermission = usePermissionCheck(Permission.TopicCategoryManage, category.id);

    return (
        <Stack w="100%">
            <Group key={category.id} justify="space-between" w="100%" px="sm">
                <Group>
                    {category.isCategory && category.children && (
                        <ActionIcon variant="transparent" onClick={toggle}>
                            <IconChevronRight
                                style={{ transition: "transform 0.5s", transform: opened ? "rotate(90deg)" : "rotate(0deg)" }}
                            />
                        </ActionIcon>
                    )}
                    {hasEditPermission ? (
                        <Link to="/organization/topics/$topicId" params={{ topicId: category.id }}>
                            {category.name}
                        </Link>
                    ) : (
                        <Text>{category.name}</Text>
                    )}
                    {category.isRequired && <Badge>Required</Badge>}
                </Group>
                {category.isCategory ? (
                    <Menu>
                        <Menu.Target>
                            <ActionIcon variant="transparent">
                                <IconPlus />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item
                                onClick={() =>
                                    modals.open({ title: "Create Category", children: <CategoryCreateModal parentCategory={category} /> })
                                }
                            >
                                Create Category
                            </Menu.Item>
                            <Menu.Item onClick={() => handleTopicCreate(category.id)}>Create Topic</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                ) : (
                    <div />
                )}
            </Group>
            {category.isCategory &&
                opened &&
                category.children?.map((c) => (
                    <div key={c.id} style={{ paddingLeft: theme.spacing.xl }}>
                        <TopicDetails category={c} handleTopicCreate={handleTopicCreate} />
                    </div>
                ))}
        </Stack>
    );
};

const TopicsContainer = () => {
    const nav = useNavigate();
    const { rootTopic } = useRootTopicQuery();

    function handleTopicCreate(categoryId: string) {
        modals.open({
            title: "Topic Type",
            children: (
                <TopicCreationModal
                    onSubmit={(required) => {
                        modals.closeAll();
                        nav({
                            to: "/organization/topics/$topicId",
                            params: { topicId: "create" },
                            search: { required, category: categoryId },
                        }).catch(logger.error);
                    }}
                />
            ),
        });
    }

    return (
        <Content paper>
            <Content.Heading>
                <Group justify="space-between">
                    <Title order={2} fw="normal">
                        Topics
                    </Title>
                    <Menu>
                        <Menu.Target>
                            <ActionIcon variant="transparent">
                                <IconPlus />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item
                                onClick={() =>
                                    modals.open({ title: "Create Category", children: <CategoryCreateModal parentCategory={rootTopic} /> })
                                }
                            >
                                Create Category
                            </Menu.Item>
                            <Menu.Item onClick={() => handleTopicCreate(rootTopic.id)}>Create Topic</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Content.Heading>
            <Stack h="100%" align="center" style={{ overflow: "auto" }} mt="xl" px="xl">
                {rootTopic.children.map((c) => (
                    <TopicDetails key={c.id} category={c} handleTopicCreate={handleTopicCreate} />
                ))}
            </Stack>
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/organization/topics/list")({
    component: TopicsContainer,
    loader: async () => {
        await queryClient.ensureQueryData(topicQuery);
    },
});

const createCategory = graphql(`
    mutation OrganizationCreateCategory($title: String!, $parent: UUID!) {
        createTopic(params: { name: $title, isCategory: true, isRequired: false, parentId: $parent }) {
            id
        }
    }
`);
