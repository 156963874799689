import "routes/_auth/organization/groups.css";

import { ActionIcon, Anchor, Button, Group, Loader, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowLeft, IconList, IconPlus } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Outlet, useNavigate, useRouterState } from "@tanstack/react-router";
import { Suspense, useState } from "react";
import { userQueryOptions } from "routes/_auth/route";
import { httpPostGraphql } from "shared/api/httpClient";
import { GroupInfo, GroupTreeNode } from "shared/components/groups/Groups";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { permissionCheck, useUserId } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const GroupsComponent = () => {
    const {
        data: { rootGroup },
    } = useSuspenseQuery(rootGroupQueryOptions);

    const nav = useNavigate();
    const routerState = useRouterState();

    const { userId } = useUserId();
    const { data: permissions } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.clientPermissions });

    const isMobile = useIsMobile();
    const [opened, { toggle }] = useDisclosure(true);
    const [groupsExpanded, setGroupsExpanded] = useState<string[]>([rootGroup.id]);

    function isDisabledPredicate(g: GroupInfo) {
        return !permissionCheck(permissions, Permission.GroupSubgroupManage, g.id);
    }

    function chevronOnClick(group: GroupInfo) {
        if (groupsExpanded.includes(group.id)) setGroupsExpanded(groupsExpanded.filter((g) => g != group.id));
        else setGroupsExpanded(groupsExpanded.concat(group.id));
    }

    function groupOnClick(group: GroupInfo) {
        nav({ to: `/organization/groups/${group.id}` }).catch(logger.error);
    }

    const Label = (group: GroupInfo) =>
        isDisabledPredicate(group) ? (
            <Text opacity={0.4}>{group.name}</Text>
        ) : (
            <Text onClick={() => groupOnClick?.(group)}>
                <Anchor style={{ color: "text" }}>{group.name}</Anchor>
            </Text>
        );

    const MobileView = () => {
        return (
            <Stack p="1rem" h="100%">
                {routerState.location.pathname.split("/").slice(-1).includes("groups") ? (
                    <>
                        <Title order={2}>Groups</Title>
                        <GroupTreeNode
                            group={rootGroup}
                            expandedNodes={groupsExpanded}
                            chevronOnClick={chevronOnClick}
                            isDisabledPredicate={isDisabledPredicate}
                            renderLabel={Label}
                            shouldRedirect
                        />
                    </>
                ) : (
                    <>
                        <Group>
                            <ActionIcon
                                variant="transparent"
                                color="text"
                                size="sm"
                                onClick={() => {
                                    nav({ to: "/organization/groups" }).catch(logger.error);
                                }}
                            >
                                <IconArrowLeft />
                            </ActionIcon>
                            <Title order={2}>Groups</Title>
                        </Group>
                        <Suspense fallback={<Loader />}>
                            <Outlet />
                        </Suspense>
                    </>
                )}
            </Stack>
        );
    };

    const DesktopView = () => {
        return (
            <Stack gap={0} h="100%">
                <Group justify="space-between" w="100%" h="7dvh" p="xs" style={{ borderBottom: "1px solid var(--app-shell-border-color)" }}>
                    <Title order={2} fw="normal">
                        Groups
                    </Title>
                    <Button
                        display={routerState.location.pathname == "/organization/groups/create" ? "none" : undefined}
                        variant="default"
                        leftSection={<IconPlus />}
                        color="text"
                        onClick={() => {
                            nav({ to: "/organization/groups/$groupId", params: { groupId: "create" } }).catch(logger.error);
                        }}
                    >
                        Create Group
                    </Button>
                </Group>
                <Group gap={0} wrap="nowrap" style={{ flexGrow: 1, overflowY: "auto" }}>
                    <Stack
                        h="100%"
                        mah="85dvh"
                        w={opened ? "16rem" : "min-content"}
                        style={{ borderRight: "1px solid var(--app-shell-border-color)" }}
                        p="xs"
                    >
                        <Group>
                            <ActionIcon variant="transparent" color="text" onClick={toggle}>
                                {opened ? <IconArrowLeft /> : <IconList />}
                            </ActionIcon>
                        </Group>
                        <Stack display={opened ? undefined : "none"} style={{ overflow: "auto", gap: 0, flexGrow: 1 }}>
                            <GroupTreeNode
                                group={rootGroup}
                                expandedNodes={groupsExpanded}
                                chevronOnClick={chevronOnClick}
                                isDisabledPredicate={isDisabledPredicate}
                                renderLabel={Label}
                                shouldRedirect
                            />
                        </Stack>
                    </Stack>
                    <Suspense fallback={<Loader />}>
                        <Outlet />
                    </Suspense>
                </Group>
            </Stack>
        );
    };

    return isMobile ? <MobileView /> : <DesktopView />;
};

const rootGroupQueryOptions = queryOptions({
    queryKey: qk("organization", "rootGroup"),
    queryFn: () => httpPostGraphql(rootGroupQuery, {}),
});

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/_groups/groups")({
    component: GroupsComponent,
    loader: () => {
        queryClient.ensureQueryData(rootGroupQueryOptions).catch(logger.error);
    },
});

export const rootGroupQuery = graphql(`
    query GroupTreeRootGroup {
        rootGroup {
            id
            name
            internalJoinable
            synchronized
            members {
                id
                firstName
                lastName
                euds {
                    type
                    value
                }
            }
            children {
                id
                name
                internalJoinable
                synchronized
                members {
                    id
                    firstName
                    lastName
                    euds {
                        type
                        value
                    }
                }
                children {
                    id
                    name
                    internalJoinable
                    synchronized
                    members {
                        id
                        firstName
                        lastName
                        euds {
                            type
                            value
                        }
                    }
                    children {
                        id
                        name
                        internalJoinable
                        synchronized
                        members {
                            id
                            firstName
                            lastName
                            euds {
                                type
                                value
                            }
                        }
                    }
                }
            }
            parents {
                id
                name
            }
        }
    }
`);
