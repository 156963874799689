import { Badge, Button, Checkbox, Group, Select, Stack, Textarea, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconDownload, IconX } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect, useMemo } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { useRootTopicQuery } from "shared/components/topic/useRootTopicQuery";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { logger } from "shared/utils/logger";
import { maybe } from "shared/utils/maybe";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { searchTree } from "shared/utils/tree";
import { graphql } from "src/gql";
import { queryClient } from "src/queryClient";
import { z } from "zod";

/** @private */
type FormType = {
    parent?: string;
    name: string;
    description: string;
    default: boolean;
    keyword: string;
    admins: never[];
    required: boolean;
};

/** @private */
type TopicLike = {
    id: string;
    name: string;
    description?: string;
    isCategory: boolean;
    isRequired: boolean;
    children?: TopicLike[];
    parent?: { id: string; name: string };
    members?: { id: string }[];
};

const TopicForm = ({ topic, onSubmit }: { topic: TopicLike; onSubmit: (data: FormType) => void }) => {
    const nav = useNavigate();
    const { required, category } = Route.useSearch();

    const { rootTopic } = useRootTopicQuery();
    const categories = useMemo(() => searchTree(rootTopic, (it) => it.isCategory), [rootTopic]);

    const form = useForm<FormType>({
        initialValues: {
            parent: category ?? topic.parent?.id,
            name: topic.name,
            description: topic.description ?? "",
            default: false, // TODO
            keyword: "", // TODO
            admins: [], // TODO
            required: !!required,
        },
    });

    useEffect(() => {
        form.resetDirty();
    }, [topic]);

    return (
        <form style={{ height: "100%" }} onSubmit={form.onSubmit(onSubmit)}>
            <Stack>
                <Select
                    label="Parent Category"
                    withAsterisk
                    w="100%"
                    placeholder="Select a category..."
                    data={categories.map((c) => ({ value: c.id, label: c.name }))}
                    {...form.getInputProps("parent", {})}
                />
                <TextInput label="Title" withAsterisk placeholder="Enter a title..." {...form.getInputProps("name")} />
                {!topic.isCategory && (
                    <>
                        <Textarea label="Description" placeholder="Enter a keyword..." {...form.getInputProps("description")} />
                        <Checkbox
                            label="Default Topic"
                            description="Users are automatically subscribed to topic and can unsubscribe from topic."
                            {...form.getInputProps("default", { type: "checkbox" })}
                        />
                        <TextInput
                            label="Keyword"
                            description="SMS opt in keyword."
                            placeholder="Enter a keyword..."
                            {...form.getInputProps("keyword")}
                        />
                        {maybe(topic.members)?.take((it) => <Title order={3}>{it.length} Total Subscribers</Title>)}
                    </>
                )}
                <Group justify="flex-end">
                    <Button leftSection={<IconDownload />} type="submit" disabled={!form.isDirty()}>
                        Save
                    </Button>
                    <Button
                        variant="default"
                        leftSection={<IconX />}
                        onClick={(e) => {
                            e.preventDefault();
                            void nav({ to: "/organization/topics/list" });
                        }}
                    >
                        Cancel
                    </Button>
                </Group>
            </Stack>
        </form>
    );
};

const TopicCreateContainer = () => {
    const { required } = Route.useSearch();
    const topic: TopicLike = { name: "", id: "create", members: [], isCategory: false, isRequired: !!required };
    const nav = useNavigate();

    const { mutate } = useGraphqlMutation({
        document: mutations.topicCreate,
        onSuccess: async () => {
            await qk.invalidate("organization", "rootTopic");
            await nav({ to: "/organization/topics/list" });
            notify.show.success({ message: "Topic created successfully" });
        },
    });

    function handleCreate(data: FormType) {
        if (!data.parent) {
            notify.show.error({ message: "Must specify category" });
            return;
        }
        mutate({
            params: { parentId: data.parent, isCategory: false, isRequired: !!required, name: data.name, description: data.description },
        });
    }

    return (
        <Content style={{ alignItems: "Center", height: "100%" }} paper>
            <Content.Heading backable>
                <Title order={2} fw="normal">
                    Create Topic
                </Title>
                {required && <Badge color="red">Required</Badge>}
            </Content.Heading>
            <TopicForm topic={topic} onSubmit={handleCreate} />
        </Content>
    );
};

const TopicEditContainer = () => {
    const { topicId } = Route.useParams();
    const {
        data: { topic },
    } = useSuspenseQuery(queries.topic(topicId));

    const { mutateAsync: updateTopic } = useGraphqlMutation(mutations.topicUpdate);
    const { mutateAsync: updateTopicParent } = useGraphqlMutation(mutations.topicParentUpdate);

    function handleEdit(data: FormType) {
        const promises = [];
        if (data.name != topic.name || data.description != topic.description) {
            const { name, description } = data;
            promises.push(updateTopic({ topicId: topic.id, params: { name, description } }));
        }
        if (data.parent != topic.parent?.id) {
            if (!data.parent) return;
            promises.push(updateTopicParent({ topicId: topic.id, parentId: data.parent }));
        }
        Promise.all(promises)
            .then(async () => {
                await qk.invalidate("organization", "topic", topic.id);
                notify.show.success({ message: "Updated Topic" });
            })
            .catch(logger.error);
    }

    return (
        <Content style={{ alignItems: "Center", height: "100%" }} paper>
            <Content.Heading backable>
                <Title order={2} fw="normal">
                    Edit Topic
                </Title>
                {topic.isRequired && <Badge color="red">Required</Badge>}
            </Content.Heading>
            <TopicForm topic={topic} onSubmit={handleEdit} />
        </Content>
    );
};

const TopicContainer = () => {
    const { topicId } = Route.useParams();

    return topicId == "create" ? <TopicCreateContainer /> : <TopicEditContainer />;
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/topics/$topicId")({
    validateSearch: z.object({ required: z.boolean().optional(), category: z.string().optional() }),
    loader: async ({ params: { topicId } }) => {
        if (topicId != "create") await queryClient.ensureQueryData(queries.topic(topicId));
    },
    component: TopicContainer,
});

const queries = {
    topic: (topicId: string) =>
        queryOptions({
            queryKey: qk("organization", "topic", topicId),
            queryFn: () =>
                httpPostGraphql(
                    graphql(`
                        query OrganizationTopic($topicId: UUID!) {
                            topic(topicId: $topicId) {
                                id
                                parent {
                                    id
                                    name
                                }
                                name
                                description
                                isCategory
                                isRequired
                                members {
                                    id
                                    firstName
                                    lastName
                                    euds {
                                        id
                                        type
                                        value
                                    }
                                }
                            }
                        }
                    `),
                    { topicId },
                ),
        }),
};

const mutations = {
    topicUpdate: graphql(`
        mutation OrganizationUpdateTopic($topicId: UUID!, $params: UpdateTopicParametersInput!) {
            updateTopic(id: $topicId, params: $params) {
                id
            }
        }
    `),
    topicParentUpdate: graphql(`
        mutation OrganizationUpdateTopicParent($topicId: UUID!, $parentId: UUID!) {
            changeTopicParent(id: $topicId, parentId: $parentId) {
                id
            }
        }
    `),
    topicCreate: graphql(`
        mutation OrganizationCreateTopic($params: CreateTopicParametersInput!) {
            createTopic(params: $params) {
                id
            }
        }
    `),
};
