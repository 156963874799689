import { createContext, ReactNode, useContext, useState } from "react";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { useOnNavAway } from "shared/hooks/useOnNavAway";
import { Drawer as Vaul } from "vaul";

export const AsideCtx = createContext<{
    present: (content: ReactNode | { label: string; component: ReactNode }) => void;
    toggle: (content: ReactNode | { label: string; component: ReactNode }) => void;
    dismiss: () => void;
    content: ReactNode;
    label: string;
} | null>(null);

export const AsideProvider = ({ children }: { children: ReactNode }) => {
    const [label, setLabel] = useState("");
    const [content, setContent] = useState<ReactNode>(null);

    function present(args: ReactNode | { label: string; component: ReactNode }) {
        if (!!args && typeof args == "object" && "component" in args) {
            setLabel(args.label);
            setContent(args.component);
        } else {
            setLabel("");
            setContent(args);
        }
    }

    function toggle(args: ReactNode | { label: string; component: ReactNode }) {
        if (!!content) dismiss();
        else present(args);
    }

    function dismiss() {
        setContent(null);
    }

    return (
        <AsideCtx.Provider value={{ present, dismiss, content, label, toggle }}>
            <div data-vaul-drawer-wrapper>
                <Vaul.Root open={!!content} onClose={dismiss} onOpenChange={(open) => !open && dismiss()} shouldScaleBackground>
                    <MobileAsideSheet />
                    {children}
                </Vaul.Root>
            </div>
        </AsideCtx.Provider>
    );
};

function MobileAsideSheet() {
    const isMobile = useIsMobile();
    const aside = useAside();

    if (!isMobile) return null;
    return (
        <Vaul.Portal>
            <Vaul.Overlay
                style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0, 0, 0, 0.4)", zIndex: 10e3 }}
            />
            <Vaul.Content
                style={{
                    backgroundColor: "var(--mantine-color-default)",
                    display: "flex",
                    flexDirection: "column",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    marginTop: "6px",
                    height: "97dvh",
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    outline: "none",
                    zIndex: 10e3 + 1,
                }}
            >
                <div
                    style={{
                        padding: "1rem", // p-4 (4 * 0.25rem = 1rem)
                        borderTopLeftRadius: "10px",
                        flex: "1",
                    }}
                >
                    <div
                        aria-hidden
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "3rem",
                            height: "0.375rem",
                            flexShrink: 0,
                            borderRadius: "9999px",
                            backgroundColor: "#d1d5db",
                            marginBottom: "2rem",
                        }}
                    />
                    <div
                        style={{
                            maxWidth: "28rem",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        <div
                            className="font-medium mb-4 text-gray-900"
                            style={{
                                fontWeight: 500,
                                marginBottom: "1rem",
                                color: "#1a202c",
                            }}
                        >
                            {aside.content}
                        </div>
                    </div>
                </div>
            </Vaul.Content>
        </Vaul.Portal>
    );
}

export function useAside() {
    const { content, dismiss, ...rest } = useContext(AsideCtx)!;
    useOnNavAway(dismiss);

    return { isPresenting: !!content, content, dismiss, ...rest };
}
