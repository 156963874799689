type Maybe<T> = Some<NonNullable<T>> | (T extends T ? (T extends null | undefined ? null : never) : never);

export function maybe<T>(inner: T): Maybe<T> {
    if (inner == null) return null as Maybe<T>;
    else return new Some(inner) as Maybe<T>;
}

class Some<T> {
    constructor(private inner: T) {}

    map<E>(fn: (it: T) => E): Maybe<E> {
        return maybe(fn(this.inner));
    }

    takeIf(predicate: (it: T) => boolean): T | null {
        if (predicate(this.inner)) return this.inner;
        else return null;
    }

    if(predicate: (it: T) => boolean): Some<T> | null {
        if (predicate(this.inner)) return this;
        else return null;
    }

    run(fn: (it: T) => unknown): Some<T> {
        fn(this.inner);
        return this;
    }

    take<F extends (it: T) => unknown>(fn: F): ReturnType<F>;
    take(): T;

    take<E = T>(mapping?: (it: T) => E): T | E {
        return typeof mapping == "function" ? mapping(this.inner) : this.inner;
    }
}
