import { generateColors } from "@mantine/colors-generator";
import {
    Badge,
    Button,
    createTheme,
    CSSVariablesResolver,
    DEFAULT_THEME,
    defaultVariantColorsResolver,
    Loader,
    mergeMantineTheme,
    rem,
    Title,
} from "@mantine/core";

const primaryColor: string = import.meta.env.VITE_ORG_PRIMARY_COLOR ?? "red";
const primaryHex: string = import.meta.env.VITE_ORG_PRIMARY_HEX ?? "#8F0808";

const themeOverride = createTheme({
    primaryColor,
    primaryShade: {
        light: 8,
        dark: 7,
    },
    fontFamily: "Open Sans",
    colors: {
        [primaryColor]: generateColors(primaryHex),
    },
    spacing: {
        xs: rem(8),
    },
    components: {
        Title: Title.extend({
            defaultProps: {
                c: "text",
            },
        }),
        Text: {
            defaultProps: {
                c: "text",
            },
        },
        Loader: Loader.extend({
            defaultProps: {
                type: "dots",
                mx: "auto",
            },
        }),
        Badge: Badge.extend({
            defaultProps: {
                variant: "light",
                radius: "sm",
            },
        }),
        Button: Button.extend({
            defaultProps: {
                w: "fit-content",
            },
        }),
    },
    variantColorResolver: (input) => {
        const base = defaultVariantColorsResolver(input);

        // transparent button text color should be "text"
        if (input.variant == "transparent") {
            return { ...base, color: "text" };
        } else if (input.variant == "subtle") return { ...base, color: "text" };

        return base;
    },
});

export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);

export const cssVariablesResolver: CSSVariablesResolver = () => ({
    variables: {},
    light: {},
    dark: {},
});
