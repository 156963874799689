import { ActionIcon, Box, Group, rem, Stack, Title } from "@mantine/core";
import { IconBackhoe, IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { ReactNode } from "react";
import { userQueryOptions } from "routes/_auth/route";
import { sidebarLinkData } from "shared/components/global/Sidebar/sidebar.config";
import { hasSubNav, SidebarLink, SidebarSubLink } from "shared/components/global/Sidebar/SidebarLink";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { permissionsCheck, useUserId } from "shared/stores/oidc";

export const Sidebar = ({
    navIndex,
    setNavIndex,
    opened,
    toggle,
}: {
    navIndex: number;
    setNavIndex: (idx: number) => unknown;
    opened: boolean;
    toggle: () => void;
}) => {
    const isMobile = useIsMobile();

    const { userId } = useUserId();
    const { data: permissions } = useSuspenseQuery({ ...userQueryOptions(userId), select: (data) => data.user.clientPermissions });

    permissionsCheck;
    const subLinks = sidebarLinkData
        .find((link) => link.index == navIndex)
        ?.subLinks?.data.map((link) => {
            if (
                !!link.permissions &&
                !permissionsCheck(
                    permissions,
                    link.permissions.map((p) => [p.value, p.id]),
                )
            ) {
                return;
            }
            return <SidebarSubLink {...link} key={link.label} to={link.to} onClick={isMobile ? toggle : undefined} />;
        });

    return isMobile ? (
        subLinks
    ) : (
        <SidebarDesktop navIndex={navIndex} setNavIndex={setNavIndex} opened={opened} toggle={toggle} subLinks={subLinks} />
    );
};

const SidebarDesktop = ({
    navIndex,
    setNavIndex,
    opened,
    toggle,
    subLinks,
}: {
    navIndex: number;
    setNavIndex: (idx: number) => unknown;
    opened: boolean;
    toggle: () => void;
    subLinks: ReactNode;
}) => {
    const topLinks = sidebarLinkData
        .filter((link) => link.position != "bottom")
        .map((link, idx) => <SidebarLink {...link} key={link.to} onClick={() => setNavIndex(idx)} />);
    const bottomLinks = sidebarLinkData
        .filter((link) => link.position == "bottom")
        .map((link, idx) => <SidebarLink {...link} key={link.to} onClick={() => setNavIndex(idx + topLinks.length)} />);

    return (
        <Stack component="nav" h="100%" dir="row" style={{ flexWrap: "wrap", gap: 0 }}>
            <Stack justify="space-between" h="100%" w={rem(50)} my={rem(3)}>
                <Stack justify="center" gap={0}>
                    {hasSubNav(navIndex) && (
                        <ActionIcon
                            variant="outline"
                            radius="xl"
                            color="text"
                            style={{ position: "absolute", right: rem(-12) }}
                            bg="var(--mantine-color-body)"
                            size="sm"
                            onClick={toggle}
                        >
                            {opened ? <IconChevronLeft /> : <IconChevronRight />}
                        </ActionIcon>
                    )}
                    {topLinks}
                </Stack>
                <Stack justify="center" gap={0} mb="xs">
                    {import.meta.env.VITE_ENVIRONMENT !== "prod" && (
                        <SidebarLink to="/devtools" icon={IconBackhoe} label="Dev Sandbox" onClick={() => setNavIndex(-1)} />
                    )}
                    {bottomLinks}
                </Stack>
            </Stack>
            <Stack
                justify="flex-start"
                h="100%"
                w={rem(175)}
                display={opened ? undefined : "none"}
                style={{ borderLeft: "1px solid var(--app-shell-border-color)" }}
            >
                <Box
                    py="xs"
                    h="7dvh"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderBottom: "1px solid var(--app-shell-border-color)",
                    }}
                    fw="bold"
                >
                    <Title order={5}>{sidebarLinkData.find((link) => link.index == navIndex)?.subLinks?.header}</Title>
                </Box>
                <Stack>{subLinks}</Stack>
            </Stack>
        </Stack>
    );
};

export const NavbarFooter = ({ setNavIndex }: { setNavIndex: (idx: number) => unknown }) => {
    const mobileLinks = sidebarLinkData.filter((link) => !link.hideFromMobile);
    return (
        <Group h={rem(50)} justify="space-around">
            {mobileLinks.map((link, idx) => (
                <SidebarLink {...link} key={link.to} onClick={() => setNavIndex(idx)} />
            ))}
        </Group>
    );
};
