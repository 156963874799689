import { Badge } from "@mantine/core";
import { titlecase } from "shared/utils/fns";
import { MessageStatus } from "src/gql/graphql";
import { match } from "ts-pattern";

export const MessageStatusBadge = (props: { status: MessageStatus }) => (
    <Badge
        radius={"sm"}
        variant="light"
        miw={"max-content"}
        color={match(props.status)
            .with(MessageStatus.Expired, () => "red")
            .with(MessageStatus.Published, () => "green")
            .with(MessageStatus.Scheduled, () => "blue")
            .otherwise(() => undefined)}
    >
        {match(props.status)
            .with(MessageStatus.Expired, () => "Expired")
            .with(MessageStatus.Published, () => "Active")
            .with(MessageStatus.Scheduled, () => "Scheduled")
            .otherwise((it) => titlecase(it))}
    </Badge>
);
