import { ActionIcon, Box, Checkbox, Group, Stack, Text, Title, Tooltip } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useUserId } from "shared/stores/oidc";
import { to } from "shared/utils/fns";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { RootTopicQuery, UserNode } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

type TopicInfo = Pick<RootTopicQuery["rootTopic"]["children"][0], "id" | "name" | "isCategory" | "isRequired"> & {
    members?: Pick<UserNode, "id">[];
    children?: TopicInfo[];
};

export const TopicNode = ({
    topic,
    expandedNodes,
    setExpandedNodes,
    checkboxOnChange,
    defaultCheckedFn,
    disabledPredicate,
    disabledTooltipLabel,
}: {
    topic: TopicInfo;
    expandedNodes: string[];
    setExpandedNodes: (nodes: string[]) => void;
    checkboxOnChange: (id: string, checked: boolean) => void;
    defaultCheckedFn?: (topic: TopicInfo) => boolean;
    disabledPredicate?: (topic: TopicInfo) => boolean;
    disabledTooltipLabel?: string;
}) => {
    const isExpanded = expandedNodes.includes(topic.id);

    return (
        <Stack gap={0} style={{ display: "inline-flex" }} w="min-content">
            <Group gap="0" w="min-content" p="sm" wrap="nowrap" style={{ whiteSpace: "nowrap" }}>
                <ActionIcon
                    display={topic.isCategory && (topic.children?.length ?? 0) > 0 ? undefined : "none"}
                    variant="transparent"
                    color="text"
                    onClick={() => {
                        if (isExpanded) setExpandedNodes(expandedNodes.filter((id) => id != topic.id));
                        else setExpandedNodes(expandedNodes.concat(topic.id));
                    }}
                >
                    <IconChevronRight style={{ transition: "transform 0.5s", transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)" }} />
                </ActionIcon>
                {!topic.isCategory && (
                    <Tooltip disabled={!disabledPredicate?.(topic)} label={disabledTooltipLabel}>
                        <Checkbox
                            disabled={disabledPredicate?.(topic)}
                            defaultChecked={defaultCheckedFn?.(topic)}
                            onChange={(e) => checkboxOnChange(topic.id, e.target.checked)}
                            pr="sm"
                        />
                    </Tooltip>
                )}
                <Text>{topic.name}</Text>
            </Group>
            <Stack gap={0} style={{ display: isExpanded ? undefined : "none" }}>
                <Box
                    w="fit-content"
                    style={{
                        borderLeft: "3rem solid transparent",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {topic.children?.map((c) => (
                        <TopicNode
                            expandedNodes={expandedNodes}
                            setExpandedNodes={setExpandedNodes}
                            key={c.id}
                            topic={c}
                            checkboxOnChange={checkboxOnChange}
                            defaultCheckedFn={defaultCheckedFn}
                            disabledPredicate={disabledPredicate}
                            disabledTooltipLabel={disabledTooltipLabel}
                        />
                    ))}
                </Box>
            </Stack>
        </Stack>
    );
};

const TopicsContainer = () => {
    const { userId } = useUserId();
    const {
        data: { rootTopic },
    } = useSuspenseQuery(rootTopicQueryOptions);
    const [expandedNodes, setExpandedNodes] = useState(rootTopic.children.filter((c) => c.isCategory).map(to("id")));

    const { mutate: addUserMutation } = useGraphqlMutation({
        document: mutations.addUser,
        onSuccess: async () => {
            notify.show.success({ message: "Subscribed to topic" });
            await qk.invalidate("organization", "rootTopic");
        },
    });

    const { mutate: removeUserMutation } = useGraphqlMutation({
        document: mutations.removeUser,
        onSuccess: async () => {
            notify.show.success({ message: "Unsubscribed from topic" });
            await qk.invalidate("organization", "rootTopic");
        },
    });

    return (
        <Content paper stack={{ gap: 0 }}>
            <Content.Heading backable>
                <Title order={3}>Topic Subscriptions</Title>
            </Content.Heading>
            <Title order={2} fw="normal" component="h3">
                Topic Subscriptions
            </Title>
            <Stack gap={0}>
                {rootTopic.children.map((c) => (
                    <TopicNode
                        key={c.id}
                        topic={c}
                        expandedNodes={expandedNodes}
                        setExpandedNodes={setExpandedNodes}
                        checkboxOnChange={(id, checked) => {
                            if (checked) {
                                addUserMutation({ userId, topicId: id });
                            } else {
                                removeUserMutation({ userId, topicId: id });
                            }
                        }}
                        defaultCheckedFn={(t) => !!t.members?.some((m) => m.id == userId)}
                        disabledPredicate={(t) => t.isRequired}
                        disabledTooltipLabel="This topic is required and cannot be unsubscribed from"
                    />
                ))}
            </Stack>
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/settings/topics")({
    component: TopicsContainer,
    loader: () => {
        queryClient.ensureQueryData(rootTopicQueryOptions).catch(logger.error);
    },
});

const mutations = {
    addUser: graphql(`
        mutation SettingsAddUserToTopic($topicId: UUID!, $userId: UUID!) {
            addUserToTopic(topicId: $topicId, userId: $userId) {
                id
            }
        }
    `),
    removeUser: graphql(`
        mutation SettingsRemoveUserFromTopic($topicId: UUID!, $userId: UUID!) {
            removeUserFromTopic(topicId: $topicId, userId: $userId) {
                id
            }
        }
    `),
};

const rootTopicQueryOptions = queryOptions({
    queryKey: qk("settings", "rootTopic"),
    queryFn: () => httpPostGraphql(rootTopicQuery, {}),
});

const rootTopicQuery = graphql(`
    query SettingsRootTopic {
        rootTopic {
            id
            name
            isCategory
            isRequired
            children {
                id
                name
                isCategory
                isRequired
                children {
                    id
                    name
                    isCategory
                    isRequired
                    children {
                        id
                        name
                        isCategory
                        isRequired
                        children {
                            id
                            name
                            isCategory
                            isRequired
                        }
                    }
                }
            }
        }
    }
`);
