import { Button, Group, MultiSelect, TextInput, Title, Tooltip } from "@mantine/core";
import { useField } from "@mantine/form";
import { IconInfoCircle } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { notify } from "shared/utils/notify";
import { graphql } from "src/gql";
import { PermissionType } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const {
        data: { roles },
    } = useSuspenseQuery(query.roles);
    const email = useField({ initialValue: "" });
    const selectedRoles = useField({ initialValue: [] });

    const { mutateAsync: createUser } = useGraphqlMutation(
        graphql(`
            mutation UsertronCreateUser($email: String!) {
                createUser(email: $email) {
                    id
                }
            }
        `),
    );

    const { mutateAsync: assignRole } = useGraphqlMutation(
        graphql(`
            mutation UsertronAssignUserRoles($userId: UUID!, $roleId: UUID!) {
                assignCustomerRole(params: { userId: $userId, roleId: $roleId }) {
                    id
                }
            }
        `),
    );

    function handleCreate() {
        createUser({ email: email.getValue() })
            .then(({ createUser: { id: userId } }) => selectedRoles.getValue().map((roleId) => assignRole({ userId, roleId })))
            .then(() => notify.show.success({ message: "User Created!" }))
            .catch(notify.catch);
    }

    return (
        <Content>
            <Content.Heading>
                <Title order={3}>Cook New Keycloak User</Title>
                <Tooltip label="don't forget to assign the user to a group and also set their password in keycloak!!">
                    <IconInfoCircle />
                </Tooltip>
            </Content.Heading>
            <TextInput label="Email" {...email.getInputProps()} />
            <MultiSelect
                label="Rights"
                data={roles.filter((it) => it.type == PermissionType.Customer).map(({ id: value, name: label }) => ({ value, label }))}
                {...selectedRoles.getInputProps()}
            />
            <Group justify="flex-end">
                <Button onClick={handleCreate}>BUurr</Button>
            </Group>
        </Content>
    );
};

const query = {
    roles: queryOptions({
        queryKey: ["usertron", "roles"],
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query UsertronRoles {
                        roles(limit: 10000, offset: 0) {
                            id
                            name
                            type
                        }
                    }
                `),
                {},
            ),
    }),
};

/** @public */
export const Route = createFileRoute("/_auth/_dev/devtools/user-tron")({
    loader: () => queryClient.ensureQueryData(query.roles),
    component: Component,
});
