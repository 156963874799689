import { Button, Divider, Group, Radio, Select, Skeleton, Stack, TextInput, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconPlus } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { Suspense } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { Section, SectionProvider } from "shared/components/global/Section";
import { AttachmentEditor } from "shared/components/message/compose/AttachmentEditor";
import { AudienceGroupEditor } from "shared/components/message/compose/AudienceGroupsEditor";
import { AudienceTopicEditor } from "shared/components/message/compose/AudienceTopicEditor";
import { ComposeFormProvider, useComposeFormContext } from "shared/components/message/compose/context";
import { GeoLocationEditor } from "shared/components/message/compose/GeoLocationEditor";
import { MessageBodyEditor } from "shared/components/message/MessageBodyEditor";
import { useFormSubscription } from "shared/hooks/useFormSubscription";
import { useAside } from "shared/utils/aside";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { MessageSeverity } from "src/gql/graphql";

export const ComposeForm = () => {
    const form = useComposeFormContext();
    const options = useFormSubscription(form, "options");
    const aside = useAside();

    const {
        data: { senders },
    } = useSuspenseQuery(queries.senders);

    return (
        <SectionProvider>
            <Stack>
                <Stack mb="md">
                    <Select
                        searchable
                        withAsterisk
                        label="From"
                        data={senders.map(({ id: value, name: label }) => ({ value, label }))}
                        {...form.getInputProps("sender.id", { withError: true })}
                    />
                    <Radio.Group label="Criticality" withAsterisk {...form.getInputProps("severity")}>
                        <Group mt="xs">
                            <Radio label="Informational" value={MessageSeverity.Informational} />
                            <Radio label="Urgent" value={MessageSeverity.Urgent} />
                        </Group>
                    </Radio.Group>
                </Stack>
            </Stack>

            <Section label="Audience">
                <AudienceGroupEditor />
                <AudienceTopicEditor />
                <Title order={5}>Geo-Location</Title>
                <Button
                    variant="default"
                    leftSection={<IconPlus />}
                    onClick={() =>
                        modals.open({
                            size: "auto",
                            title: "Select Geo-Location",
                            children: (
                                <ComposeFormProvider form={form}>
                                    <GeoLocationEditor />
                                </ComposeFormProvider>
                            ),
                        })
                    }
                >
                    Add Geo-Location
                </Button>
            </Section>
            <Section label="Message">
                <TextInput withAsterisk label="Subject" {...form.getInputProps("subject")} />
                <MessageBodyEditor />

                <Suspense
                    fallback={
                        <Group>
                            {[200, 50, 150, 170, 120, 250].map((w, i) => (
                                <Skeleton key={i} h="lg" w={w} />
                            ))}
                        </Group>
                    }
                >
                    <AttachmentEditor />
                </Suspense>

                <Divider />

                <Button
                    ml={"auto"}
                    variant="default"
                    onClick={() =>
                        aside.toggle({
                            label: "Channels",
                            component: <Skeleton h={"500px"} w={"100%"}></Skeleton>,
                        })
                    }
                >{`Channels (${options.length})`}</Button>
            </Section>
        </SectionProvider>
    );
};

const queries = {
    senders: queryOptions({
        queryKey: qk("compose", "senders"),
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query ComposeFormSenders {
                        senders {
                            id
                            name
                        }
                    }
                `),
                {},
            ),
    }),
};
