import {
    Accordion,
    AccordionControlProps,
    ActionIcon,
    Box,
    Button,
    Center,
    Checkbox,
    Divider,
    Group,
    InputLabel,
    Modal,
    Paper,
    Stack,
    Switch,
    Text,
    TextInput,
    Title,
    Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconDownload, IconEdit, IconPlus, IconX } from "@tabler/icons-react";
import { queryOptions, useQuery, useSuspenseInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useBlocker } from "@tanstack/react-router";
import { ReactNode, useEffect, useRef, useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { GroupTreeModal, rootGroupQueryOptions } from "shared/components/groups/Groups";
import { CREATE_KEYWORD } from "shared/const";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { usePermissionCheck, useUserId } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { graphql } from "src/gql";
import { Cm, GroupDetailsInfoQuery, Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const AccordionControl = ({ props, rightSection }: { props: AccordionControlProps; rightSection: ReactNode }) => {
    return (
        <Center>
            <Accordion.Control {...props} />
            {rightSection}
        </Center>
    );
};

const AddMembersModal = ({
    initialMembers,
    groupId,
    opened,
    onClose,
    setMembersOverride,
}: {
    opened: boolean;
    onClose: () => void;
    initialMembers: string[];
    groupId: string;
    setMembersOverride: (members: MemberOverrideType[]) => void;
}) => {
    const [newMembers, setNewMembers] = useState<string[]>([]);

    const { mutate: addMembersMutation } = useGraphqlMutation({
        document: addMembersToGroup,
        onSuccess: async () => {
            await qk.invalidate("organization", "group", groupId);
            notify.show.success({ message: "Added users to group" });
        },
        onError: () => notify.show.error({ message: "Error adding users to group" }),
    });

    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(usersQuery);
    const members = pages.flat();

    return (
        <Modal
            opened={opened}
            onClose={() => {
                onClose();
                if (groupId != CREATE_KEYWORD) setNewMembers([]);
            }}
            title={<Title order={3}>Add members</Title>}
            centered
        >
            {/* TODO: Search */}
            <Stack>
                <Stack mah="50dvh" style={{ overflowY: "auto" }}>
                    {members.map((m) => {
                        const maybeEud = m.euds.find((eud) => eud.type == Cm.Email);
                        const checked = newMembers.includes(m.id) || initialMembers.includes(m.id);
                        return (
                            <Checkbox
                                key={m.id}
                                label={m.firstName || m.lastName ? `${m.firstName ?? ""} ${m.lastName ?? ""}` : "Unknown user"}
                                description={!!maybeEud ? maybeEud.value : ""}
                                size="md"
                                checked={checked}
                                disabled={initialMembers.includes(m.id)}
                                onChange={() => setNewMembers(checked ? newMembers.filter((id) => id != m.id) : newMembers.concat(m.id))}
                            />
                        );
                    })}
                    <LoadMoreTrigger query={usersQuery} />
                </Stack>
                <Divider />
                <Button
                    w="100%"
                    onClick={() => {
                        if (groupId == CREATE_KEYWORD) {
                            setMembersOverride(
                                members
                                    .filter((m) => newMembers.includes(m.id))
                                    .map((m) => ({
                                        firstName: m.firstName ?? undefined,
                                        lastName: m.lastName ?? undefined,
                                        id: m.id,
                                        euds: m.euds,
                                    })),
                            );
                        } else addMembersMutation({ groupId, userIds: newMembers });

                        onClose();
                    }}
                >
                    Add
                </Button>
            </Stack>
        </Modal>
    );
};

type MemberOverrideType = {
    firstName?: string;
    lastName?: string;
    id: string;
    euds: {
        type: Cm;
        value: string;
    }[];
};

const Member = ({
    member,
    canChangeMembership,
    onRemove,
}: {
    member: MemberOverrideType;
    canChangeMembership: boolean;
    onRemove: () => void;
}) => {
    const maybeEud = member.euds.find((eud) => eud.type == Cm.Email);
    return (
        <Group key={member.id} justify="space-between">
            <Stack gap={0}>
                <Text>{member.firstName || member.lastName ? `${member.firstName ?? ""} ${member.lastName ?? ""}` : "Unknown user"}</Text>
                <Text fz="sm">{!!maybeEud ? maybeEud.value : ""}</Text>
            </Stack>
            <ActionIcon
                variant="transparent"
                color="var(--mantine-color-text)"
                size="sm"
                disabled={!canChangeMembership}
                onClick={onRemove}
            >
                <IconX />
            </ActionIcon>
        </Group>
    );
};

const MemberDetails = ({
    members,
    groupId,
    membersOverride,
    setMembersOverride,
}: {
    members: GroupDetailsInfoQuery["group"]["members"];
    groupId: string;
    membersOverride?: MemberOverrideType[];
    setMembersOverride: (members: MemberOverrideType[]) => void;
}) => {
    const [opened, { open, close }] = useDisclosure(false);

    const { mutate: removeMemberMutation } = useGraphqlMutation({
        document: removeMembersFromGroup,
        onSuccess: async () => {
            await qk.invalidate("organization", "group", groupId);
            notify.show.success({ message: "Removed user from group" });
        },
        onError: () => notify.show.error({ message: "Failed to removed user from group" }),
    });

    const hasViewPerm = usePermissionCheck(Permission.GroupUsersView, groupId);
    const canViewMembership = groupId == CREATE_KEYWORD || hasViewPerm;
    const hasChangePerm = usePermissionCheck(Permission.GroupUsersManage, groupId);
    const canChangeMembership = groupId == CREATE_KEYWORD || hasChangePerm;

    return (
        <>
            <AddMembersModal
                opened={opened}
                onClose={close}
                initialMembers={members.map((m) => m.id)}
                groupId={groupId}
                setMembersOverride={setMembersOverride}
            />
            <Accordion.Item value="members" style={{ borderBottom: "none" }}>
                <AccordionControl
                    rightSection={
                        <Tooltip label="You do not have permission to add members to this group" disabled={canChangeMembership}>
                            <Button
                                variant="default"
                                leftSection={<IconPlus />}
                                flex="1 0 auto"
                                onClick={open}
                                disabled={!canChangeMembership}
                            >
                                Add
                            </Button>
                        </Tooltip>
                    }
                    props={{ children: <Title order={3}>Members</Title> }}
                />
                <Accordion.Panel>
                    <Box style={{ flexGrow: 1 }}>
                        <Stack style={{ overflowY: "auto" }}>
                            {(membersOverride?.length ?? 0) > 0 ? (
                                membersOverride?.map((member) => (
                                    <Member
                                        key={member.id}
                                        member={member}
                                        canChangeMembership
                                        onRemove={() => {
                                            setMembersOverride(membersOverride.filter((m) => m.id != member.id));
                                        }}
                                    />
                                ))
                            ) : canViewMembership && members.length > 0 ? (
                                members.map((member) => (
                                    <Member
                                        key={member.id}
                                        member={{
                                            firstName: member.firstName ?? undefined,
                                            lastName: member.lastName ?? undefined,
                                            id: member.id,
                                            euds: member.euds,
                                        }}
                                        canChangeMembership={canChangeMembership}
                                        onRemove={() => removeMemberMutation({ userId: member.id, groupId: groupId })}
                                    />
                                ))
                            ) : (
                                <Stack align="center">
                                    <Text c="dimmed">No members found</Text>
                                </Stack>
                            )}
                        </Stack>
                    </Box>
                </Accordion.Panel>
            </Accordion.Item>
        </>
    );
};

// const SenderDetails = ({ senders }: { senders: GroupDetailsQuery["group"]["senders"] }) => {
//     // TODO
// };

// const TemplateDetails = ({ templates }: { templates: GroupDetailsQuery["group"]["template"] }) => {
//     // TODO
// };

const GroupDetails = ({ group, membersOverride }: { group: GroupDetailsInfoQuery["group"]; membersOverride?: MemberOverrideType[] }) => {
    const hasRequestError = useRef(false);
    const [isEditing, setIsEditing] = useState(group.id == CREATE_KEYWORD);
    const { userId } = useUserId();
    const {
        data: { rootGroup },
    } = useSuspenseQuery(rootGroupQueryOptions);
    const form = useForm<GroupDetailsInfoQuery["group"]>({
        initialValues: {
            ...group,
        },
        validate: {
            name: (v) => (v.length == 0 ? "Invalid name" : null),
            parents: (v) => (group.id != rootGroup.id && v.length == 0 ? "Group must have at least one parent" : null),
        },
    });
    const { reset, getValues } = form;

    function onRequestError(error: string): void {
        hasRequestError.current = true;
        notify.show.error({ message: error });
    }

    const { mutate: updateGroupMutation } = useGraphqlMutation({
        document: updateGroup,
        onError: () => onRequestError("Error updating group"),
        onSuccess: async () => await qk.invalidate("organization", "rootGroup").catch(logger.error),
    });

    const { mutate: addParentMutation } = useGraphqlMutation({
        document: addParent,
        onError: () => onRequestError("Error adding parent group"),
        onSuccess: async () => await qk.invalidate("organization", "rootGroup").catch(logger.error),
    });

    const { mutate: removeParentMutation } = useGraphqlMutation({
        document: removeParent,
        onError: () => onRequestError("Error removing parent group"),
        onSuccess: async () => await qk.invalidate("organization", "rootGroup").catch(logger.error),
    });

    const { mutate: createGroupMutation } = useGraphqlMutation({
        document: createGroup,
        onError: () => onRequestError("Error creating group"),
        onSuccess: async () => {
            await qk.invalidate("user", "id", userId).catch(logger.error);
            await qk.invalidate("organization", "rootGroup").catch(logger.error);
            notify.show.success({ message: "Created new group" });
        },
    });

    function handleSubmit(data: typeof form.values) {
        data.id == CREATE_KEYWORD ? handleGroupCreateSubmit(data) : handleGroupEditSubmit(data);
    }

    function handleGroupCreateSubmit(data: typeof form.values) {
        createGroupMutation({
            name: data.name,
            parentGroupIds: data.parents.map((p) => p.id),
            internalJoinable: data.internalJoinable,
            members: membersOverride?.map((m) => m.id) ?? [],
        });
    }

    function handleGroupEditSubmit(data: typeof form.values) {
        hasRequestError.current = false;
        if (form.isDirty("name") || form.isDirty("internalJoinable")) {
            updateGroupMutation({ internalJoinable: data.internalJoinable, name: data.name, groupId: data.id });
        }
        if (form.isDirty("parents")) {
            const newParents = getValues().parents.filter((p) => !group.parents.some((g) => g.id == p.id));
            const removedParents = group.parents.filter((p) => !getValues().parents.some((g) => g.id == p.id));
            if (newParents.length) newParents.forEach((p) => addParentMutation({ groupId: group.id, parentId: p.id }));
            if (getValues().parents.length === 0) onRequestError("Group must have at least one parent");
            else if (removedParents.length) removedParents.forEach((p) => removeParentMutation({ groupId: group.id, parentId: p.id }));
        }

        if (!hasRequestError.current) {
            notify.show.success({ message: "Updated group details" });
            form.setInitialValues({
                ...getValues(),
            });
            reset();
        }
    }

    useEffect(() => {
        setIsEditing(group.id == CREATE_KEYWORD);
        form.setInitialValues({ ...group });
        reset();
    }, [group]);

    useBlocker({
        blockerFn: () => {
            if (window.confirm("This action will lose currently unsaved changes. Continue anyways?")) {
                reset();
                setIsEditing(false);
                return true;
            }
            return false;
        },
        condition: form.isDirty(),
    });

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Accordion.Item value="group" style={{ borderBottom: "none" }}>
                <AccordionControl
                    rightSection={
                        isEditing ? (
                            <Group wrap="nowrap" pl="md">
                                {group.id == CREATE_KEYWORD ? (
                                    <Button
                                        color="var(--mantine-color-text)"
                                        type="submit"
                                        disabled={!form.isDirty()}
                                        leftSection={<IconDownload />}
                                    >
                                        Create
                                    </Button>
                                ) : (
                                    <Button
                                        color="var(--mantine-color-text)"
                                        type="submit"
                                        disabled={!form.isDirty()}
                                        leftSection={<IconDownload />}
                                    >
                                        Save
                                    </Button>
                                )}
                                <Button
                                    variant="default"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsEditing(false);
                                        reset();
                                    }}
                                    type="button"
                                    display={group.id == CREATE_KEYWORD ? "none" : undefined}
                                >
                                    Cancel
                                </Button>
                            </Group>
                        ) : (
                            <ActionIcon size="lg" c="var(--mantine-color-text" variant="transparent" onClick={() => setIsEditing(true)}>
                                <IconEdit />
                            </ActionIcon>
                        )
                    }
                    props={{ children: <Title order={3}>Details</Title> }}
                />
                <Accordion.Panel>
                    <TextInput
                        key={form.key("name")}
                        label="Group Name"
                        placeholder="Enter name"
                        disabled={!isEditing}
                        {...form.getInputProps("name")}
                    />
                    <Stack gap={0} pt="sm">
                        <InputLabel>Parent Group(s)</InputLabel>
                        <GroupTreeModal
                            rootGroup={rootGroup}
                            values={form.getValues().parents}
                            onChange={(parents) => form.setValues({ parents })}
                            disabled={!isEditing || group.synchronized}
                            currentGroup={group}
                            errorText={(form.errors["parents"] as string) ?? undefined}
                        />
                    </Stack>
                    <Switch
                        key={form.key("internalJoinable")}
                        label="Internally Joinable?"
                        py="sm"
                        size="lg"
                        disabled={group.synchronized || !isEditing}
                        {...form.getInputProps("internalJoinable", { type: "checkbox" })}
                    />
                    <Title order={3}>Sender Profile</Title>
                    <Group p="sm">Placeholder</Group>
                    <Title order={3}>Templates</Title>
                    <Group p="sm">Placeholder</Group>
                </Accordion.Panel>
            </Accordion.Item>
        </form>
    );
};

const GroupDetailsContainer = () => {
    const { groupId } = Route.useParams();
    const { data } = useQuery({ ...groupInfoQueryOptions(groupId), enabled: groupId != CREATE_KEYWORD });
    const group = !!data ? data.group : emptyGroup;

    const isMobile = useIsMobile();
    const [membersOverride, setMembersOverride] = useState<MemberOverrideType[]>();

    return (
        <Paper p={!isMobile ? "1rem" : undefined} h="100%" mah="85dvh" style={{ flexGrow: 1, overflowY: "auto" }}>
            <Accordion chevronPosition="left" w="100%" multiple defaultValue={["group", "members"]}>
                <GroupDetails group={group} membersOverride={membersOverride} />
                <MemberDetails
                    members={group.members}
                    groupId={group.id}
                    membersOverride={membersOverride}
                    setMembersOverride={setMembersOverride}
                />
            </Accordion>
        </Paper>
    );
};

const emptyGroup: GroupDetailsInfoQuery["group"] = {
    id: CREATE_KEYWORD,
    name: "",
    members: [],
    children: [],
    parents: [],
    synchronized: false,
    internalJoinable: false,
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/groups/$groupId")({
    loader: ({ params: { groupId } }) => {
        queryClient.prefetchInfiniteQuery(usersQuery).catch(logger.error);
        groupId != CREATE_KEYWORD && queryClient.ensureQueryData(groupInfoQueryOptions(groupId)).catch(logger.error);
        queryClient.ensureQueryData(rootGroupQueryOptions).catch(logger.error);
    },
    component: GroupDetailsContainer,
});

function groupInfoQueryOptions(groupId: string) {
    return queryOptions({ queryKey: qk("organization", "group", groupId), queryFn: () => httpPostGraphql(groupInfoQuery, { groupId }) });
}

const groupInfoQuery = graphql(`
    query GroupDetailsInfo($groupId: UUID!) {
        group(id: $groupId) {
            id
            name
            internalJoinable
            synchronized
            children {
                id
                name
                internalJoinable
                synchronized
                members {
                    id
                    firstName
                    lastName
                }
            }
            members {
                firstName
                lastName
                id
                euds {
                    type
                    value
                }
            }
            parents {
                name
                id
            }
        }
    }
`);

const updateGroup = graphql(`
    mutation GroupDetailsUpdate($groupId: UUID!, $internalJoinable: Boolean!, $name: String!) {
        updateGroup(id: $groupId, params: { internalJoinable: $internalJoinable, name: $name }) {
            id
        }
    }
`);

const addParent = graphql(`
    mutation GroupDetailsAddParent($groupId: UUID!, $parentId: UUID!) {
        addParentToGroup(groupId: $groupId, parentId: $parentId) {
            id
        }
    }
`);

const removeParent = graphql(`
    mutation GroupDetailsRemoveParent($groupId: UUID!, $parentId: UUID!) {
        removeParentFromGroup(groupId: $groupId, parentId: $parentId) {
            id
        }
    }
`);

const usersQuery = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query GroupDetailsUsers($offset: Int!, $limit: Int!) {
            users(limit: $limit, offset: $offset) {
                id
                firstName
                lastName
                euds {
                    type
                    value
                }
            }
        }
    `),
    select: ({ users }) => users,
    queryKey: ["organization", "users"],
});

const addMembersToGroup = graphql(`
    mutation GroupDetailsAddUsersToGroup($groupId: UUID!, $userIds: [UUID!]!) {
        addUsersToGroup(groupId: $groupId, userIds: $userIds) {
            id
        }
    }
`);

const removeMembersFromGroup = graphql(`
    mutation GroupDetailsRemoveMember($userId: UUID!, $groupId: UUID!) {
        removeUserFromGroup(userId: $userId, groupId: $groupId) {
            id
        }
    }
`);

const createGroup = graphql(`
    mutation GroupDetailsCreateGroup($internalJoinable: Boolean!, $name: String!, $parentGroupIds: [UUID!]!, $members: [UUID!]!) {
        createGroup(params: { internalJoinable: $internalJoinable, name: $name, parentGroupIds: $parentGroupIds, members: $members }) {
            id
        }
    }
`);
