import { ActionIcon, AppShell, Box, Burger, Group, Menu, rem, Stack, Title, useMantineTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUserCircle, IconX } from "@tabler/icons-react";
import { queryOptions } from "@tanstack/react-query";
import { createFileRoute, Outlet, redirect, useNavigate, useRouterState } from "@tanstack/react-router";
import { useContext, useEffect, useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { NavbarFooter, Sidebar } from "shared/components/global/Sidebar/Sidebar";
import { sidebarLinkData } from "shared/components/global/Sidebar/sidebar.config";
import { hasSubNav } from "shared/components/global/Sidebar/SidebarLink";
import { useColorMode } from "shared/hooks/useColorMode";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { isSupport } from "shared/hooks/useIsSupport";
import { getUserId } from "shared/stores/oidc";
import { AsideCtx } from "shared/utils/aside";
import { qk } from "shared/utils/qk";
import { BryxLogo } from "src/assets/BryxLogo";
import { graphql } from "src/gql";
import { queryClient } from "src/queryClient";

const AuthLayout = () => {
    const logoWidth = "30px";
    const logo = import.meta.env.VITE_ORG_LOGO_SOURCE;
    const title = import.meta.env.VITE_ORG_TITLE;

    const nav = useNavigate();
    const isMobile = useIsMobile();
    const router = useRouterState();
    const theme = useMantineTheme();
    const { isDark } = useColorMode();
    const [opened, { toggle, open }] = useDisclosure(true);

    const [navIndex, setNavIndex] = useState(
        sidebarLinkData.find((link) => link.subLinks?.data.some((v) => router.location.pathname.includes(v.to)))?.index ?? -1,
    );

    useEffect(() => {
        if (opened && !hasSubNav(navIndex)) {
            toggle();
        }
        if (hasSubNav(navIndex)) open();
    }, [navIndex]);

    const aside = useContext(AsideCtx);

    return (
        <AppShell
            header={{ height: "8dvh" }}
            footer={{ height: isMobile ? "8dvh" : 0 }}
            navbar={{ width: opened ? rem(225) : rem(50), breakpoint: theme.breakpoints.sm, collapsed: { mobile: !opened } }}
            aside={{ width: rem(358), breakpoint: theme.breakpoints.sm, collapsed: { desktop: !aside?.content, mobile: true } }}
        >
            <AppShell.Header>
                <Group h="100%" px="md" justify="space-between">
                    {hasSubNav(navIndex) && <Burger opened={opened} onClick={toggle} display={!isMobile ? "none" : undefined} size="sm" />}
                    <Group pl="xs">
                        <Group>
                            {Boolean(logo) ? <img src={logo} width={logoWidth} /> : <BryxLogo width={logoWidth} />}
                            <Title
                                order={2}
                                c="var(--mantine-primary-color-filled)"
                                fw={"light"}
                                onDoubleClick={() =>
                                    import.meta.env["VITE_ENV"] != "prod" &&
                                    (() => (window.location.href = "https://bryx.slack.com/archives/C059BQRU7GE/p1736542695444959"))()
                                }
                            >
                                {Boolean(title) ? title : "BMNS"}
                            </Title>
                        </Group>
                    </Group>
                    <Menu>
                        <Menu.Target>
                            <ActionIcon variant="filled" aria-label="Profile" radius="lg" size="lg">
                                <IconUserCircle size={20} stroke={1.5} />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item onClick={() => void nav({ to: "/settings/profile" })}>Profile Information</Menu.Item>
                            <Menu.Item onClick={() => void nav({ to: "/logout" })}>Log out</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar>
                <Sidebar opened={opened} toggle={toggle} navIndex={navIndex} setNavIndex={setNavIndex} />
            </AppShell.Navbar>
            <AppShell.Aside>
                {!!aside?.content && !isMobile && (
                    <Stack p="md">
                        <Group w="100%" justify="space-between">
                            <Title order={3}>{aside?.label}</Title>
                            <ActionIcon variant="transparent" onClick={() => aside?.dismiss()}>
                                <IconX size={16} />
                            </ActionIcon>
                        </Group>
                        {aside?.content}
                    </Stack>
                )}
            </AppShell.Aside>
            <AppShell.Main
                style={{
                    height: isMobile ? "84dvh" : "92dvh",
                    maxHeight: isMobile ? "84dvh" : "92dvh",
                    background: isDark ? theme.colors.dark[6] : theme.colors.gray[2],
                }}
            >
                <Box w="100%" h="100%" style={{ overflowY: "auto" }}>
                    <Outlet />
                </Box>
            </AppShell.Main>
            <AppShell.Footer h="fit-content" display={!isMobile ? "none" : undefined}>
                <NavbarFooter setNavIndex={setNavIndex} />
            </AppShell.Footer>
        </AppShell>
    );
};

/** @public */
export const Route = createFileRoute("/_auth")({
    beforeLoad: async ({ context, location }) => {
        const auth = await context.auth;
        if (!auth.isAuthenticated) throw redirect({ to: "/login", search: { redirect: location.href } });

        // Support people will not be associated with a user
        if (!isSupport(auth)) {
            const data = await queryClient.ensureQueryData({
                ...userQueryOptions(getUserId(auth)),
            });
            if (data.user.firstName == null) throw redirect({ to: "/account/register" });
        }
    },
    component: AuthLayout,
});

export function userQueryOptions(id: string) {
    return queryOptions({
        queryKey: qk("user", "id", id),
        staleTime: 5 * 60 * 60, // 5 minutes
        queryFn: async () =>
            httpPostGraphql(
                graphql(`
                    query User($id: UUID!) {
                        user(userId: $id) {
                            id
                            firstName
                            lastName
                            language
                            topics {
                                id
                                name
                            }
                            groups {
                                id
                                name
                                internalJoinable
                            }
                            topics {
                                id
                            }
                            clientPermissions {
                                customer
                                group {
                                    id
                                    permissions
                                }
                                topic {
                                    id
                                    permissions
                                }
                            }
                            euds {
                                id
                                name
                                type
                                value
                                isVerified
                                isDefault
                            }
                            addresses {
                                name
                                city
                                id
                                state
                                street
                                street2
                                zip
                            }
                        }
                    }
                `),
                { id },
            ),
    });
}
